import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import dayjs from "dayjs";
import { DATE_STRING_FORMAT } from "@/app/Import/Utils/pasteTemplate";

export function parseBritzAU(
  grid: AgGridReact,
  row: any,
): RelocationItemInput | null {
  const fields = [
    "line",
    "departure",
    "destination",
    "pickup_date",
    "dropoff_date",
    "vehicle_code",
    "unit_no",
    "grade",
    "hire_unit_cost",
    "fuel_allowance",
  ] as const;

  const rowObject: any = {
    quantity: 1,
  };

  if (!row.at(0)) {
    return null;
  }

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "line":
        rowObject.line = item;
        break;
      case "departure":
        rowObject.departure_office_code = item;
        break;
      case "destination":
        rowObject.delivery_office_code = item;
        break;
      case "pickup_date":
        rowObject.available_from = dayjs(item, "D/M/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;

      case "dropoff_date":
        rowObject.available_to = dayjs(item, "D/M/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "vehicle_code":
        rowObject.vehicle_code = item;
        break;

      case "hire_unit_cost":
        rowObject.hire_unit_rate = parseInt(item.replace("$", ""));
        break;

      case "fuel_allowance":
        if (
          rowObject.delivery_office_code === "HBT" ||
          rowObject.departure_office_code === "HBT"
        ) {
          rowObject.fuel_amount = 0;
        } else {
          rowObject.fuel_amount = parseInt(item.replace("$", ""));
        }
        break;

      default:
        break;
    }
  });

  return rowObject;
}
