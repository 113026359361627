import { RecordScreenFormCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenFormCard";
import { sortOpenHours } from "@/app/Offices/Utils/sortOpenHours";
import { ClosedDatesForm } from "@/app/Suppliers/Components/ClosedDatesForm";
import {
  fromOpenDaysSchema,
  toOpenDaysSchema,
} from "@/app/Suppliers/Utils/mapClosedDays";
import { OpenHoursInput } from "@/app/Offices/Components/OpenHoursInput";
import { OpenHoursViewState } from "@/app/Offices/Components/OpenHoursViewState";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationCalendar } from "@/assets/Illustrations";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";

export function SupplierDefaultOpenHoursScreen() {
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const record = useSupplierRecord();

  return (
    <>
      <RecordScreenFormCard
        onSubmit={async (values) => {
          const foo = toOpenDaysSchema(values.closed_dates);
          await mutateAsync({
            id: record.id,
            input: {
              closed_dates: foo,
            },
          });
        }}
        title="Closed days"
        initialValues={{
          closed_dates: fromOpenDaysSchema(record.closed_dates),
        }}
      >
        <ClosedDatesForm
          emptyState={
            <EmptyState
              Icon={IllustrationCalendar}
              header="No closed dates"
              subheader="Add date(s) you want to close all offices on"
            />
          }
        />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        enableReinitialize={true}
        onSubmit={(values) => {
          return mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        initialValues={{
          departure_hours: sortOpenHours(record.departure_hours),
        }}
        title="Departure hours"
        view={<OpenHoursViewState openHours={record.departure_hours} />}
      >
        <OpenHoursInput prefix="departure_hours" />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        enableReinitialize={true}
        onSubmit={(values) => {
          return mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        initialValues={{
          delivery_hours: sortOpenHours(record.delivery_hours),
        }}
        title="Delivery hours"
        view={<OpenHoursViewState openHours={record.delivery_hours} />}
      >
        <OpenHoursInput prefix="delivery_hours" />
      </RecordScreenFormCard>
    </>
  );
}
