import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { QueryRelocationsWhereColumn, SqlOperator } from "@/gql/graphql";
import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationLinkLineDialog } from "@/app/Relocations/Components/RelocationLinkLineDialog";
import { RelocationTable } from "@/app/Relocations/Components/RelocationTable";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";

export function RelocationLinesScreen() {
  const record = useRelocationRecord();
  const { open } = useDialog(RelocationLinkLineDialog);

  return (
    <>
      <RecordScreenCard
        title="Related relocations"
        buttons={
          <Button
            onClick={() => {
              open({
                relocation: record,
              });
            }}
          >
            Link Relocation
          </Button>
        }
      >
        <RelocationTable
          id="relocation-linked-lines"
          queryVariables={{
            where: {
              AND: [
                {
                  column: QueryRelocationsWhereColumn.RelocationLineId,
                  value: record.line.id,
                },
                {
                  column: QueryRelocationsWhereColumn.Id,
                  operator: SqlOperator.Neq,
                  value: record.id,
                },
              ],
            },
          }}
        />
      </RecordScreenCard>
    </>
  );
}
