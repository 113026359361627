import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { RegionInput } from "@/app/Common/Components/RegionInput";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { onboardingCreateOrganisationMutation } from "@/app/Onboarding/GraphQL/onboardingCreateOrganisationMutation";
import { useOrganizationList } from "@clerk/clerk-react";
import { SubmitButtonInput } from "@/lib/Components/Form/Inputs/SubmitButtonInput";
import { HireUnitRadioButton } from "@/app/Common/Components/HireUnitRadioButton";
import { HireUnitType } from "@/gql/graphql";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { createOrganisationValidation } from "@/app/Onboarding/Validators/createOrganisationValidation";
import { useNavigate } from "@tanstack/react-router";
import { settingsGeneralRoute } from "@/app/Settings/Settings/Routes/settingsRoute";
import { useLocalStorage } from "react-use";
import { localStorageKeys } from "@/lib/utils";
import { GlobalSupplierStorageObject } from "@/app/Suppliers/Components/GlobalSupplierProvider";

export function OnboardingCreateOrgScreen() {
  const { mutateAsync } = useGqlMutation(onboardingCreateOrganisationMutation);
  const { setActive } = useOrganizationList();
  const navigate = useNavigate();
  const [, setGlobalSupplier] = useLocalStorage<GlobalSupplierStorageObject>(
    localStorageKeys.GLOBAL_SUPPLIER,
  );

  return (
    <div className="max-w-sm pb-24">
      <GenericForm
        validationSchema={toFormikValidationSchema(
          createOrganisationValidation,
        )}
        initialValues={{
          name: "",
          region: null!,
          currency: null!,
          hire_unit_type: HireUnitType.Day,
        }}
        className="grid-cols-1 gap-x-0"
        onSubmit={async (values) => {
          if (!setActive) {
            throw new Error("Not loaded");
          }

          const res = await mutateAsync({
            input: values,
          });

          await setActive({
            organization:
              res.onboardingCreateOrganisation.clerk_organisation_id,
          });

          setGlobalSupplier({
            type: "single",
            id: res.onboardingCreateOrganisation.supplier_id,
          });

          await navigate({
            to: settingsGeneralRoute.to,
          });
        }}
      >
        <TextInput name="name" label="Business name" />
        <RegionInput label="region" name="region" />
        <CurrencyTypeInput name="currency" label="Currency" />
        <HireUnitRadioButton name="hire_unit_type" label="Units" />

        <SubmitButtonInput>Create</SubmitButtonInput>
      </GenericForm>
    </div>
  );
}
