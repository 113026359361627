import { FC, ReactNode, useState } from "react";
import { Intent } from "@/lib/Components/Common/Intent";
import classNames from "classnames";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

type TextButtonProps = {
  children: ReactNode;
  intent?: Intent;
  onClick?: () => void;
  bgClass?: string;
  className?: string;
  Icon?: FC<any>;
};

const intentMap: {
  [key in Intent]: string;
} = {
  neutral: "text-gray-700",
  primary: "text-blue-600",
  success: "text-green-600",
  warning: "text-yellow-400",
  danger: "text-red-600",
  info: "text-indigo-600",
  highlight: "text-pink-600",
};

export function TextButton({
  children,
  intent = "neutral",
  onClick,
  bgClass = "bg-inherit focus:ring-offset-white",
  className,
  Icon,
}: TextButtonProps) {
  const color = intentMap[intent];
  const [isLoading, setIsLoading] = useState(false);

  return (
    <button
      onClick={async () => {
        setIsLoading(true);
        try {
          await onClick?.();
        } finally {
          setIsLoading(false);
        }
      }}
      type="button"
      className={classNames(
        "inline-flex items-center rounded-md text-xs underline ring-offset-inherit focus:outline-none focus:ring focus:ring-offset-4",
        color,
        bgClass,
        className
      )}
    >
      {Icon && (
        <span className="mr-1 inline-flex">
          {isLoading ? (
            <Spinner size={4} />
          ) : (
            <Icon className={classNames("h-3 w-3")} />
          )}
        </span>
      )}
      <span>{children}</span>
    </button>
  );
}
