import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import {
  SupplierOfficeListItem,
  supplierOfficeListQuery,
} from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { ModelLinkCell } from "@/lib/Components/Tables/Components/ModelLinkCell";
import { TableId } from "@/app/Common/Utils/tableIds";
import { useSupplierOfficeActions } from "@/app/Offices/Hooks/useSupplierOfficeActions";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import {
  OrderByRelationWithColumnAggregateFunction,
  QuerySupplierOfficesOrderByCityColumn,
  QuerySupplierOfficesOrderByColumn,
  SortOrder,
  SupplierOfficeListQueryVariables,
} from "@/gql/graphql";
import { ReactNode } from "react";

export type OfficeTableId = "name" | "actions" | "city" | "code" | "address";

export function OfficeTable({
  id,
  columns,
  queryVariables,
  rightButtons,
}: {
  id: TableId;
  columns?: DataTableColDef<SupplierOfficeListItem, any, OfficeTableId>[];
  queryVariables?: Partial<SupplierOfficeListQueryVariables>;
  rightButtons?: ReactNode;
}) {
  const { supplierId } = useGlobalSupplier();
  const getActions = useSupplierOfficeActions();
  const defaultColumns: DataTableColDef<
    SupplierOfficeListItem,
    any,
    OfficeTableId
  >[] = [
    {
      id: "name",
      accessorFn: (data) => data.name,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <ModelLinkCell
            to={"/offices/$id"}
            params={{
              id: row.original.id,
            }}
          >
            {row.original.name}
          </ModelLinkCell>
        );
      },
    },
    {
      id: "code",
      accessorFn: (data) => data.code ?? "--",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Code" />
      ),
    },
    {
      id: "city",
      accessorFn: (data) => data.city.name,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="City" />
      ),
    },
    {
      id: "address",
      cell: ({ row: { original } }) => (
        <div className="text-xs text-gray-500">
          <p>{original.address.formatted_address_line_1 || "--"}</p>
          <p>{original.address.formatted_address_line_2}</p>
        </div>
      ),
    },
  ];

  return (
    <DataTable
      getActions={getActions}
      columns={columns ?? defaultColumns}
      document={supplierOfficeListQuery}
      getQueryVariables={({ pagination, sorting, search }) => {
        const baseParams: SupplierOfficeListQueryVariables = {
          search,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          supplier_id: supplierId,
          ...queryVariables,
        };

        if (baseParams.search) {
          return baseParams;
        }

        return {
          ...baseParams,
          orderBy: sorting.map((sort) => {
            const order = sort.desc ? SortOrder.Desc : SortOrder.Asc;
            if (sort.id === "name") {
              return {
                column: QuerySupplierOfficesOrderByColumn.Name,
                order,
              };
            }

            if (sort.id === "code") {
              return {
                column: QuerySupplierOfficesOrderByColumn.Code,
                order,
              };
            }

            if (sort.id === "city") {
              return {
                city: {
                  column: QuerySupplierOfficesOrderByCityColumn.Name,
                  aggregate: OrderByRelationWithColumnAggregateFunction.Min,
                },
                order,
              };
            }

            throw new Error(`Unknown sort column: ${sort.id}`);
          }),
        };
      }}
      accessor={(data) => data.supplierOffices}
      id={id}
      rightButtons={rightButtons}
    />
  );
}
