import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import {
  SupplierOfficeListItem,
  supplierOfficeListQuery,
} from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { Link } from "@tanstack/react-router";
import {
  SupplierOfficeRecord,
  supplierOfficeRecordQuery,
} from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { OfficeCreateDialog } from "@/app/Offices/Components/OfficeCreateDialog";
import { ComponentPropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";

export function SupplierOfficeInput({
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof supplierOfficeListQuery>,
  VariablesOf<typeof supplierOfficeListQuery>,
  SupplierOfficeListItem
>) {
  const { openAsPromise } = useDialog<
    ComponentPropsWithoutRef<typeof OfficeCreateDialog>,
    SupplierOfficeRecord
  >(OfficeCreateDialog);

  return (
    <ComboboxInput
      document={supplierOfficeListQuery}
      accessor={(data) => data.supplierOffices.data}
      getQueryVariables={(search) => ({
        first: 20,
        page: 1,
        search,
        archived: false,
      })}
      recordDocument={supplierOfficeRecordQuery}
      recordAccessor={(data) => data.supplierOffice}
      getViewNode={(item) =>
        item ? (
          <Link
            className="text-blue-500"
            to={"/offices/$id"}
            params={{
              id: item.id,
            }}
          >
            {item.name}
          </Link>
        ) : null
      }
      getKey={(i) => i.id}
      getListItemNode={(i) => <Node model={i} />}
      getInputNode={(i) => i.name}
      fixture={(search) => {
        return {
          label: `Add ${search}`,
          onClick: () => {
            return openAsPromise({
              initialValues: {
                name: search,
              },
            });
          },
        };
      }}
      {...props}
    />
  );
}

function Node({ model }: { model: SupplierOfficeListItem }) {
  const { t } = useTranslation("city");
  return (
    <div>
      <p>{model?.name}</p>
      <p className="text-xs text-gray-500">
        {model.city?.state ? t(`state.${model.city.state}`) : null}
      </p>
    </div>
  );
}
