import * as React from "react";
const Svg057HikerManColour = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "hiker-man-color", viewBox: "0 0 944.39 775.54", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".hiker-man-2{fill:#74d5de}.hiker-man-3{fill:none;stroke-linecap:round;stroke:#56cad8;stroke-miterlimit:10;stroke-width:4px}.hiker-man-4{fill:#56cad8}.hiker-man-7{fill:#fd8369}.hiker-man-9{fill:#de8e68}")), /* @__PURE__ */ React.createElement("path", { d: "M264 725.66C350.18 666 514.51 578.47 671.74 518q4.18-3.74 8.5-7.54c64.42-56.95 150.43-128 160.79-205.68 10.2-77.85-55.24-162.5-135.3-208.22C625.67 51 531 44.19 436.15 44S246.28 50.13 199 101.64c-47.06 51.36-46.38 148.05-53.52 236.27s-22.26 168.11.68 242.9c18.75 61.55 63.4 119.87 117.84 144.85", style: {
  fill: "#f8f8f8"
} }), /* @__PURE__ */ React.createElement("path", { d: "M730.92 496.42c-8.35-2.86-35.67-39-35.67-81.89 0-49.83 52.71-144.15 57.72-177.75 3.19-21.37 17.58-209.64 126.86-209.64 59.59 0 64.56 116.51 64.56 168.62 0 121.73-177 225.42-177 288.65a271 271 0 0 1-36.47 12.01", className: "hiker-man-2" }), /* @__PURE__ */ React.createElement("path", { d: "M744.36 491.9c0-97.62 72.85-275.48 136.93-338.07", className: "hiker-man-3" }), /* @__PURE__ */ React.createElement("path", { d: "M323.68 687.41c-5.58-1.66-20.75-21-20.75-45.92 0-24 6.74-59 6.74-91.47 0-18.06-6.74-24.46-6.74-45.95 0-34.25 32.46-44.68 43.84-44.68 16.75 0 25.71 14.33 25.71 21.92 0 10.59-3 18.63-3 30.35 0 22.91 8 25.78 7.16 45.52-2.5 57.71-26.46 114.13-47.52 126.93Z", className: "hiker-man-4" }), /* @__PURE__ */ React.createElement("path", { d: "M323.68 687.41c6.84-5.9-31.46-192.5-64.68-225.52-36.14-35.89-65.85-54.89-75.66-66.67-17.4-20.89-28.48-99.67-105.07-99.67-20.26 0-68 16.75-76.59 87.64-2.81 23.27-7.92 58.87 40.51 110.46 32.62 34.76 73.42 22.79 113.3 60.45 28.71 27.11 24.69 68 51.5 102.55 21.45 27.6 77.14 64.83 116.69 30.76", className: "hiker-man-2" }), /* @__PURE__ */ React.createElement("path", { d: "M314.28 691.86c0-46.83-147.73-258.19-206.84-288.42", className: "hiker-man-3" }), /* @__PURE__ */ React.createElement("path", { d: "M323.68 687.41c-1.47-11.1-7.67-105.4 9.9-139.76", style: {
  fill: "none",
  strokeLinecap: "round",
  strokeWidth: 4,
  stroke: "#74d5de",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "M207 774c44.21-63.13 439.14-278.51 676.39-318.3", style: {
  fill: "none",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  stroke: "#6d6d6d",
  strokeWidth: 3
} }), /* @__PURE__ */ React.createElement("rect", { width: 119.93, height: 222.98, x: 440.69, y: 112.93, className: "hiker-man-7", rx: 12, ry: 12 }), /* @__PURE__ */ React.createElement("path", { d: "M451 700.49v26h-70.6v-11l43.74-20.09Zm153.15-24.93c3.57 8 12 28.73 12 28.73l-35.23 22.15H549l-3.74-12.24 40.1-30.69Z", style: {
  fill: "#333"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 482.12, cy: 70.41, className: "hiker-man-9", rx: 29.77, ry: 37.54 }), /* @__PURE__ */ React.createElement("path", { d: "M460.44 3.36c-3.9-5.36-14.91-4.15-24.59 2.82a34 34 0 0 0-9.16 9.67 3 3 0 0 0-1-.23c-4.7 0-82.83 41.79-82.09 61.07s26.95 50.2 36.1 56.13c0 0 41.29-19.28 39.07-25.47s-26-26-30.17-29.92c4.9-2.56 44.12-41.42 48.55-45.82A33 33 0 0 0 450 25.8c9.66-6.98 14.35-17.02 10.44-22.44", className: "hiker-man-9" }), /* @__PURE__ */ React.createElement("path", { d: "M438 166.65c9-7.24-5.9-67.95-21.87-69.44s-43.82 28.6-42.7 37.14 41.83 50.5 64.57 32.3", style: {
  fill: "#fcc486"
} }), /* @__PURE__ */ React.createElement("path", { d: "M402.27 312c0 10 5 356 14 386 8 4 29 5 36 2s51.67-382.52 51.67-399.18S402.27 312 402.27 312", className: "hiker-man-4" }), /* @__PURE__ */ React.createElement("path", { d: "M581.61 687.19c-9.55-15-133.9-306.28-143.65-378.1 10.21-7.91 86.19-20.52 92.34-6.16s80 369.39 77.47 374-18.98 11.8-26.16 10.26", className: "hiker-man-2" }), /* @__PURE__ */ React.createElement("path", { d: "M482.54 119c64.5 0 60.5 178.5 46.5 189.5s-123.5 11.5-130.5 3.5-23.54-193 84-193", style: {
  fill: "#fed385"
} }), /* @__PURE__ */ React.createElement("path", { d: "M529.47 57.66c0-7.61-7.27-13.1-7.27-17.32s2.49-11.78-3.08-17.19c-6.77-6.57-17.56-3.83-27.22-3.83-9.08 0-14.09-10.55-26.48-10.55-8.26 0-7.21 10.72-10.3 13.38-2.29 2-20.14 5.84-20.14 14 0 9.12.24 21.36 19.18 21.36 1.26-4.38 6-14 11.4-18.28 2.78 1.2 18 7.48 21.77 8.59s9.28 17.25 15.12 19.2 6.21 12.7 10.48 12.24 16.54-2.26 16.54-21.6", className: "hiker-man-7" }), /* @__PURE__ */ React.createElement("circle", { cx: 507.75, cy: 73.37, r: 7.87, className: "hiker-man-9" }), /* @__PURE__ */ React.createElement("path", { d: "M497 133c-1.26 4.35-27.71-.93-27.2-5.85l4.52-43.7 31.87 6.86S498.28 128.67 497 133", className: "hiker-man-9" }), /* @__PURE__ */ React.createElement("path", { d: "M677.16 156.44 565.31 710.91", style: {
  fill: "none",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  stroke: "#333",
  strokeWidth: 7
} }), /* @__PURE__ */ React.createElement("path", { d: "M659.22 309.44a20.4 20.4 0 0 0-11-5.75c-13.34-21.53-58.67-87.89-78.66-112-14.41 10.35-32 28.45-32 28.45 7.35 8.24 62.4 73.37 91.69 98.91a20 20 0 0 0 6 14.37c7.41 7.42 18.79 8.06 25.41 1.45s5.97-18.01-1.44-25.43", className: "hiker-man-9" }), /* @__PURE__ */ React.createElement("path", { d: "M507.87 148.25c25.87-19.23 68.52 27.27 67.82 39.5S542.21 227.1 531 227.13c-13.69.02-45.15-62.51-23.13-78.88", style: {
  fill: "#fed892"
} }));
export default Svg057HikerManColour;
