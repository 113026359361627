import {
  RecordScreen,
  RecordScreenLink,
} from "@/lib/Components/RecordScreen/RecordScreen";
import { ActivityIcon, Link2Icon } from "lucide-react";
import {
  CalendarDaysIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { RelocationWarningBanner } from "@/app/Relocations/Components/RelocationWarningBanner";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { useRelocationActions } from "@/app/Relocations/Hooks/useRelocationActions";
import { StopwatchIcon } from "@radix-ui/react-icons";
import { RelocationDraftWarning } from "@/app/Relocations/Components/RelocationDraftWarning";
import { Badge } from "@/components/catalyst/badge";
import { useTranslation } from "react-i18next";
import { relocationStatusColorMap } from "@/app/Relocations/Utils/relocationStatusColorMap";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

export function RelocationRecordScreen() {
  const { isAdmin } = useTenant();
  const { hasSupplier } = useGlobalSupplier();

  const relocation = useRelocationRecord();
  const getActions = useRelocationActions();
  const { t } = useTranslation("relocation");

  return (
    <RecordScreen
      title={`${relocation.departureOffice.name} to ${relocation.deliveryOffice.name}`}
      breadcrumbs={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink to="/relocations">Relocations</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{relocation.reference}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
      badges={
        <>
          {!hasSupplier ? (
            <Badge color="zinc">{relocation.supplier.name}</Badge>
          ) : null}
          <Badge
            color={relocationStatusColorMap[relocation.status]}
            className="capitalize"
          >
            {t(`status.${relocation.status}`)}
          </Badge>
          {relocation.line.reference ? (
            <Badge color="zinc">Rego/Line: {relocation.line.reference}</Badge>
          ) : null}
        </>
      }
      banner={
        <>
          <RelocationWarningBanner relocation={relocation} />
          <RelocationDraftWarning />
        </>
      }
      actionsGroups={getActions(relocation)}
      tabs={[
        {
          name: "Details",
          Icon: DocumentTextIcon,
          to: "/relocations/$relocationId",
        },
        {
          name: "Bookings",
          Icon: CalendarDaysIcon,
          to: "/relocations/$relocationId/bookings",
        },
        {
          name: "Links",
          Icon: Link2Icon,
          to: "/relocations/$relocationId/lines",
        },
        ...(isAdmin
          ? [
              {
                name: "Waitlists",
                Icon: StopwatchIcon,
                to: "/relocations/$relocationId/waitlists",
              } satisfies RecordScreenLink,
            ]
          : []),
        {
          name: "Activity",
          Icon: ActivityIcon,
          to: "/relocations/$relocationId/activity",
        },
      ]}
    />
  );
}
