import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { Link } from "@tanstack/react-router";
import { LinkIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useInvoiceRecord } from "@/app/Invoices/Hooks/useInvoiceRecord";
import { InvoiceLineItemFieldsFragment, ModelType } from "@/gql/graphql";

export function PayableLineItemTable() {
  const invoice = useInvoiceRecord();
  const { t } = useTranslation("invoice");

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
          >
            Description
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Qty
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Rate
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Tax
          </th>
          <th
            scope="col"
            className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
          >
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        {invoice.lineItems.map((lineItem) => {
          return (
            <tr key={lineItem.id} className="border-b border-gray-200">
              <td className="py-4 pr-3 text-sm sm:pl-6 md:pl-0">
                <div className="font-medium text-gray-900">
                  {lineItem.description}
                </div>
                <div>
                  {lineItem.fulfills ? (
                    <PaymentAttributionLink lineItem={lineItem} />
                  ) : null}
                </div>
                <div className="mt-0.5 text-gray-500 sm:hidden">
                  {lineItem.quantity} {lineItem.unit_amount} at{" "}
                  {formatCurrency(lineItem.unit_amount, invoice.currency)}
                </div>
              </td>
              <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                {lineItem.quantity}
              </td>
              <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                {formatCurrency(lineItem.unit_amount, invoice.currency)}
              </td>
              <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                {t(`tax_type.${lineItem.tax_type}`)}
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCurrency(
                  lineItem.unit_amount * lineItem.quantity,
                  invoice.currency,
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th
            scope="row"
            colSpan={4}
            className="pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 md:pl-0"
          >
            Subtotal
          </th>
          <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
            {formatCurrency(invoice.amount_exc_tax, invoice.currency)}
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            colSpan={4}
            className="pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 md:pl-0"
          >
            GST
          </th>
          <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
            {formatCurrency(invoice.amount_tax, invoice.currency, {
              showZero: true,
            })}
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            colSpan={4}
            className="pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 md:pl-0"
          >
            Total
          </th>
          <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
            {formatCurrency(invoice.amount_inc_tax, invoice.currency)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

function PaymentAttributionLink({
  lineItem,
}: {
  lineItem: InvoiceLineItemFieldsFragment;
}) {
  if (
    !lineItem.fulfills?.attributed_to_type ||
    !lineItem.fulfills?.attributed_to_id
  ) {
    return null;
  }

  if (lineItem.fulfills.attributed_to_type === ModelType.Booking) {
    return (
      <Link
        to={"/bookings/$bookingId"}
        params={{
          bookingId: lineItem.fulfills.attributed_to_id,
        }}
        className="underline text-blue-500 text-xs"
      >
        <LinkIcon className="w-4 h-4 inline mr-2" />
        {`ORD${lineItem.fulfills.attributed_to_id.padStart(6, "0")}`}
      </Link>
    );
  }

  throw new Error(
    `Unsupported attributed_to_type ${lineItem.fulfills.attributed_to_type}`,
  );
}
