import { SettingFormCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import {
  MediaCollection,
  ModelType,
  SupplierTermsAndConditionsType,
  UpdateSupplierInput,
} from "@/gql/graphql";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createMediaMutation } from "@/app/Media/GraphQL/mediaMutations";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PdfUploader } from "@/lib/FileUpload/PdfUploader";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { toast } from "sonner";
import { PDFIcon } from "@/assets/Icons";
import {
  ArrowTopRightOnSquareIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

export function TermsAndConditionsSettingsCard() {
  const { supplier } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);

  return (
    <SettingFormCard<UpdateSupplierInput>
      title="Rental Terms & Conditions"
      description="Add your rental terms and conditions here. This will be displayed to the customer when they book a vehicle."
      onSubmit={(values) => {
        return mutateAsync({
          id: supplier.id,
          input: values,
        });
      }}
      initialValues={{
        terms_and_conditions: supplier.terms_and_conditions,
        terms_and_conditions_type: supplier.terms_and_conditions_type,
      }}
    >
      <TermsAndConditionsTabForm supplier={supplier} />
    </SettingFormCard>
  );
}

export function TermsAndConditionsTabForm({
  supplier,
}: {
  supplier: SupplierRecord;
}) {
  const { setFieldValue, values } = useForm<UpdateSupplierInput>();

  return (
    <Tabs
      className="w-full col-span-full"
      value={values.terms_and_conditions_type as string}
      onValueChange={(newVal) => {
        setFieldValue("terms_and_conditions_type", newVal);
      }}
    >
      <TabsList className="w-full mb-6">
        <TabsTrigger
          className="w-full"
          value={SupplierTermsAndConditionsType.Pdf}
        >
          PDF
        </TabsTrigger>
        <TabsTrigger
          className="w-full"
          value={SupplierTermsAndConditionsType.Text}
        >
          Text
        </TabsTrigger>
      </TabsList>

      <div className="col-span-full mb-6">
        <TermsAndConditionsHint />
      </div>

      <TabsContent value={SupplierTermsAndConditionsType.Pdf}>
        <TermsAndConditionsPdf supplier={supplier} />
      </TabsContent>
      <TabsContent value={SupplierTermsAndConditionsType.Text}>
        <RichTextInput
          name="terms_and_conditions"
          label="Rental terms & Conditions"
          className="col-span-full"
        />
      </TabsContent>
    </Tabs>
  );
}

function TermsAndConditionsHint() {
  return (
    <div className="rounded-md bg-blue-50 p-4 col-span-full">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">
            Only the selected option will be displayed to the customer.
          </p>
        </div>
      </div>
    </div>
  );
}

function TermsAndConditionsPdf({ supplier }: { supplier: SupplierRecord }) {
  const { mutateAsync: createMedia } = useGqlMutation(createMediaMutation);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <div className="col-span-full mb-8">
      <div className="mt-2 flex items-center gap-x-3">
        {supplier.termsAndConditionsPdf ? (
          <span className="flex items-center text-blue-500">
            <PDFIcon className="w-12 h-12 mr-2" />
            <a
              className="text-blue-500 underline"
              href={supplier.termsAndConditionsPdf.url}
            >
              <span>{supplier.termsAndConditionsPdf.name}</span>
              <ArrowTopRightOnSquareIcon className="w-4 h-4 inline" />
            </a>
          </span>
        ) : (
          <span className="flex items-center text-gray-300">
            <PDFIcon className="w-12 h-12" />
          </span>
        )}
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm min-w-20 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {supplier.termsAndConditionsPdf ? "Change" : "Add"}
            </button>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle>Upload Pdf</DialogTitle>

            <div className="w-full relative py-1">
              <PdfUploader
                onDrop={() => {
                  setIsUploading(true);
                }}
                onUpload={async (uploads) => {
                  if (uploads.length === 0 || uploads.length > 1) {
                    throw new Error("Uploads must be exactly 1");
                  }

                  const upload = uploads[0];

                  try {
                    await createMedia({
                      input: {
                        file_name: upload.file.name,
                        key: upload.temporary_url,
                        collectionName: MediaCollection.TermsAndConditionsPdf,
                        objectType: ModelType.Supplier,
                        objectId: supplier.id,
                      },
                    });
                  } catch (err) {
                    toast.warning("PDF upload failed");
                  } finally {
                    setIsUploading(false);
                  }

                  setOpen(false);
                }}
              />

              {isUploading ? (
                <div className="absolute inset-0 flex items-center justify-center bg-white/30 backdrop-blur-sm">
                  <Spinner />
                </div>
              ) : null}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
