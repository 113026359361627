import {
  GenericForm,
  GenericFormProps,
} from "@/lib/Components/Form/Forms/GenericForm";
import { ReactNode } from "react";
import { FormikValues } from "formik";
import { Button } from "@/lib/Components/Button/Button";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { toast } from "sonner";

export function SettingFormCard<T extends FormikValues>({
  title,
  description,
  children,
  onSubmit,
  ...props
}: {
  title: ReactNode;
  description: ReactNode;
} & GenericFormProps<T>) {
  return (
    <GenericForm
      className="block bg-white rounded-md p-6 space-y-6 shadow"
      shouldToggleEditOnSubmit={false}
      enableReinitialize
      onSubmit={async (...props) => {
        const res = await onSubmit(...props);

        toast.success("Settings saved successfully");

        return res;
      }}
      {...props}
    >
      <div>
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      </div>
      <div className="grid grid-cols-12 gap-x-6">{children}</div>
      <SaveButton />
    </GenericForm>
  );
}

export function SettingCard({
  title,
  description,
  children,
}: {
  title: ReactNode;
  description: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="bg-white rounded-md p-6 space-y-6 shadow">
      <div>
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      </div>
      <div className="">{children}</div>
    </div>
  );
}

function SaveButton() {
  const { values, initialValues, isSubmitting, submitCount, isValid } =
    useForm();
  const showWarning = submitCount > 1 && !isValid;

  const isDirty = JSON.stringify(values) !== JSON.stringify(initialValues);

  if (!isDirty) {
    return null;
  }

  return (
    <div className="flex justify-end border-t border-gray-100 pt-6 space-x-3">
      <Button intent="neutral" type="text" htmlButtonType="reset">
        Reset
      </Button>
      <Button
        intent={showWarning ? "danger" : "primary"}
        LeadingIcon={showWarning ? ExclamationTriangleIcon : undefined}
        showCommandShortcut
        isLoading={isSubmitting}
        htmlButtonType="submit"
      >
        Submit
      </Button>
    </div>
  );
}
