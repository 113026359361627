import { useState } from "react";
import { FormContext } from "./FormContext";
import { createStore } from "zustand";
import { Formik, FormikConfig, FormikValues } from "formik";
import { set as lodashSet } from "lodash";

export interface FormationContextType {
  toggleEdit: (isEditing?: boolean) => void;
  isEditing: boolean;
  fieldContext: Record<any, any>;
  setFieldContext: (path: string, val: any) => void;
}

export type FormationProps<T> = {
  initialEditingState?: boolean;
  shouldToggleEditOnSubmit?: boolean;
} & FormikConfig<T>;

export function Formation<T extends FormikValues>({
  initialEditingState = true,
  shouldToggleEditOnSubmit = true,
  onSubmit,
  ...props
}: FormationProps<T>) {
  const [store] = useState(() =>
    createStore<FormationContextType>((set) => ({
      isEditing: initialEditingState,
      toggleEdit: (isEditing?: boolean) =>
        set((state) => ({
          isEditing: isEditing ?? !state.isEditing,
        })),
      fieldContext: {},
      setFieldContext: (path, val) =>
        set((state) => ({
          fieldContext: lodashSet(state.fieldContext, path, val),
        })),
    })),
  );

  const state = store.getState();

  return (
    <FormContext.Provider value={store}>
      <Formik<T>
        {...props}
        onSubmit={async (values, formikHelpers) => {
          try {
            await onSubmit?.(values, formikHelpers);
            if (shouldToggleEditOnSubmit) {
              state.toggleEdit(false);
            }
          } catch (e) {
            console.error({
              message: "Error submitting form",
              error: e,
            });
          }
        }}
        {...props}
      />
    </FormContext.Provider>
  );
}
