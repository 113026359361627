import { Measurement } from "@/gql/graphql";
import Resources from "../resources";

const resource: Resources["generic"] = {
  boolean: {
    true: "Yes",
    false: "No",
    null: "--",
    undefined: "--",
  },
  measurement: {
    [Measurement.Imperial]: "Imperial",
    [Measurement.Metric]: "Metric",
  },
  currencySymbol: "$",
};

export default resource;
