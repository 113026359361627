import { graphql } from "@/gql";

export const createBookingMutation = graphql(`
  mutation CreateBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      id
    }
  }
`);

export const updateBookingMutation = graphql(`
  mutation UpdateBooking($id: ID!, $input: UpdateBookingInput!) {
    updateBooking(id: $id, input: $input) {
      id
    }
  }
`);

export const bookingPdfMutation = graphql(`
  mutation BookingPdf($bookingId: ID!) {
    bookingPdf(id: $bookingId)
  }
`);

export const mailBookingToCustomerMutation = graphql(`
  mutation MailBookingToCustomer($id: ID!) {
    mailBookingToCustomer(id: $id) {
      id
    }
  }
`);

export const mailBookingToSupplierMutation = graphql(`
  mutation MailBookingToSupplier($id: ID!) {
    mailBookingToSupplier(id: $id) {
      id
    }
  }
`);

export const mailBookingToAdminMutation = graphql(`
  mutation MailBookingToAdmin($id: ID!) {
    mailBookingToAdmin(id: $id) {
      id
    }
  }
`);

export const bookingReleaseLineMutation = graphql(`
  mutation BookingReleaseLine($id: ID!) {
    bookingReleaseLineReference(id: $id) {
      id
    }
  }
`);

export const bookingChangeRelocationMutation = graphql(`
  mutation BookingChangeRelocation(
    $id: ID!
    $input: BookingChangeRelocationInput!
  ) {
    bookingChangeRelocation(id: $id, input: $input) {
      id
    }
  }
`);
