import { OrganisationListQueryVariables } from "@/gql/graphql";
import { OrganisationListSearchParams } from "@/app/Organisations/Routes/organisationRoutes";

export function getOrganisationListQueryVariables(
  searchParams: OrganisationListSearchParams,
): OrganisationListQueryVariables {
  const baseParams: OrganisationListQueryVariables = {
    first: 20,
    page: 1,
    search: searchParams.organisationSearch,
  };

  if (searchParams.organisationSearch) {
    return baseParams;
  }

  return {
    ...baseParams,
  };
}
