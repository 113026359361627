import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { userListQuery } from "@/app/Users/GraphQL/userListQuery";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@/components/catalyst/dropdown";
import { cn } from "@/lib/utils";
import { Avatar } from "@/components/catalyst/avatar";
import { getInitials } from "@/lib/Utils/getInitials";

export function UserSelect({
  userId,
  onChange,
}: {
  userId: string;
  onChange: (userId: string) => void;
}) {
  const { data } = useGqlQuery(userListQuery, {
    first: 20,
    page: 1,
  });

  const selected = data?.users.data.find((user) => user.id === userId);

  return (
    <Dropdown>
      <DropdownButton as="div" className={cn("cursor-pointer")}>
        <div
          className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 hover:bg-indigo-500 hover:text-white"
          aria-current="page"
        >
          <div className="flex items-center flex-grow">
            {selected ? (
              <>
                <Avatar
                  media={selected?.contact?.profilePicture}
                  initials={getInitials(selected?.name ?? "--")}
                  className="mr-4 h-6 w-6 flex-shrink-0 bg-purple-500 text-white"
                />
                <div className="flex-grow">
                  <p className="truncate">{selected?.name}</p>
                </div>
              </>
            ) : (
              "Select a user"
            )}
          </div>
        </div>
      </DropdownButton>
      <DropdownMenu className="min-w-64" anchor="bottom end">
        {data?.users.data.map((user) => (
          <DropdownItem
            key={user.id}
            onClick={() => {
              onChange(user.id);
            }}
          >
            <Avatar
              media={user.contact?.profilePicture}
              initials={getInitials(user.name ?? "--")}
              className="mr-4 h-6 w-6 flex-shrink-0 bg-purple-500 text-white"
            />
            <DropdownLabel>{user.name}</DropdownLabel>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
