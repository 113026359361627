import {
  ContactRecordFieldsFragment,
  CreateInvoiceInput,
  Currency,
  PaymentStatus,
  PaymentType,
  TaxType,
  UpsertPayableLineItemInput,
} from "@/gql/graphql";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { PaymentInput } from "@/app/Payments/Components/PaymentInput";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { TaxTypeInput } from "@/app/Payments/Components/TaxTypeInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { InvoiceRecord } from "@/app/Invoices/GraphQL/invoiceRecordQuery";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

type InvoiceLineItemProps = {
  payee: ContactRecordFieldsFragment;
  currency: Currency;
  invoice?: InvoiceRecord;
};
export function InvoiceLineItemsForm({
  payee,
  currency,
  invoice,
}: InvoiceLineItemProps) {
  const { setFieldValue, values } = useForm<CreateInvoiceInput>();

  const defaultTaxType =
    currency === Currency.Aud ? TaxType.Gst : TaxType.TaxExempt;

  const newFulfillmentIds = values.lineItems?.upsert
    .map((input) => {
      return input.fulfills?.update?.id;
    })
    .filter(Boolean);

  const paymentIds = [
    ...newFulfillmentIds,
    ...(invoice?.lineItems.map((item) => item.fulfills?.id).filter(Boolean) ??
      []),
  ];

  return (
    <GenericFieldArray<UpsertPayableLineItemInput>
      label="Line items"
      fieldArrayKey="lineItems.upsert"
      newLineItemValues={{
        quantity: 1,
        unit_amount: null!,
        tax_type: defaultTaxType,
        description: "",
      }}
      addLineItemButton={({ fieldHelpers }) => {
        return (
          <SplitButton
            onClick={() => {
              fieldHelpers.push({
                quantity: 1,
                unit_amount: null!,
                tax_type: defaultTaxType,
                description: "",
              });
            }}
            onMenuItemClick={() => {
              fieldHelpers.push({
                quantity: 1,
                unit_amount: null!,
                tax_type: defaultTaxType,
                description: "",
                fulfills: {
                  update: {
                    id: null!,
                  },
                },
              });
            }}
          />
        );
      }}
      footer={<TaxFooter currency={currency} />}
    >
      {(object, index) => {
        return (
          <>
            {object.fulfills ? (
              <PaymentInput
                name={`lineItems.upsert.${index}.fulfills.update.id`}
                isEditable={!object.id}
                label="Fulfills"
                className="col-span-3"
                onChange={async (payment) => {
                  if (!payment) return;

                  await setFieldValue(
                    `lineItems.upsert.${index}.unit_amount`,
                    payment.amount,
                  );

                  await setFieldValue(`lineItems.upsert.${index}.quantity`, 1);

                  await setFieldValue(
                    `lineItems.upsert.${index}.description`,
                    payment.description,
                  );

                  await setFieldValue(
                    `lineItems.upsert.${index}.tax_type`,
                    payment.tax_type,
                  );
                }}
                getQueryVariables={(search) => {
                  const filterIds = paymentIds.filter(
                    (id) => id !== object.fulfills?.update?.id,
                  );

                  return {
                    search,
                    first: 20,
                    page: 1,
                    currency: [currency],
                    type: [PaymentType.SupplierPayment],
                    status: PaymentStatus.Unpaid,
                    payeeContactId: payee.id,
                    excludeIds: filterIds?.length ? filterIds : undefined,
                  };
                }}
              />
            ) : null}
            <NumberInput
              isEditable={!object.fulfills}
              name={`lineItems.upsert.${index}.quantity`}
              label="Quantity"
              inputProps={{
                pattern: "^d+(?:.d{1,2})?$",
              }}
            />
            <CurrencyInput
              isEditable={!object.fulfills}
              name={`lineItems.upsert.${index}.unit_amount`}
              label="Unit price"
              currency={currency}
            />
            <TaxTypeInput
              isEditable={!object.fulfills}
              label="Tax"
              name={`lineItems.upsert.${index}.tax_type`}
            />
            <TextAreaInput
              isEditable={!object.fulfills}
              className="col-span-full"
              name={`lineItems.upsert.${index}.description`}
              label="Description"
            />
          </>
        );
      }}
    </GenericFieldArray>
  );
}

const items = [{ name: "Add payment" }];

function SplitButton({
  onMenuItemClick,
  onClick,
}: {
  onClick?: () => void;
  onMenuItemClick?: () => void;
}) {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={() => {
          onClick?.();
        }}
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Add line item
      </button>
      <Menu as="div" className="relative -ml-px block">
        <MenuButton className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom end"
          className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {items.map((item) => (
              <MenuItem key={item.name}>
                <button
                  type="button"
                  onClick={() => {
                    onMenuItemClick?.();
                  }}
                  className="block text-left w-full px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                  {item.name}
                </button>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
}

type TaxFooterProps = {
  currency: Currency;
};
function TaxFooter({ currency }: TaxFooterProps) {
  const { values } = useForm<CreateInvoiceInput>();

  const lineItemAmounts = values.lineItems?.upsert?.map((item) => {
    return getTaxAmounts(
      item.unit_amount * item.quantity,
      item.tax_type,
      false,
    );
  });

  const subtotal = lineItemAmounts?.reduce((acc, curr) => {
    return acc + curr.amountExcTax;
  }, 0);

  const taxAmount = lineItemAmounts?.reduce((acc, curr) => {
    return acc + curr.taxAmount;
  }, 0);

  const total = lineItemAmounts?.reduce((acc, curr) => {
    return acc + curr.amountIncTax;
  }, 0);

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <tfoot>
        <tr>
          <th
            scope="row"
            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
          >
            Subtotal
          </th>

          <td className="w-36 pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
            {formatCurrency(subtotal, currency)}
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
          >
            GST
          </th>
          <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
            {formatCurrency(taxAmount, currency)}
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
          >
            Total
          </th>

          <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
            {formatCurrency(total, currency)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

function getTaxAmounts(
  amount: number,
  taxType: TaxType,
  isTaxIncluded: boolean,
) {
  const taxAmount = calculateTax(amount, taxType, isTaxIncluded);

  const amountIncTax = isTaxIncluded ? amount : amount + taxAmount;
  const amountExcTax = isTaxIncluded ? amount - taxAmount : amount;

  return {
    amountIncTax,
    amountExcTax,
    taxAmount,
  };
}

function calculateTax(
  amount: number,
  taxType: TaxType,
  isTaxIncluded: boolean,
) {
  const rate = TaxType.Gst === taxType ? 0.1 : 0;
  if (isTaxIncluded) {
    return amount - amount / (1 + rate);
  }

  return amount * rate;
}
