import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import dayjs from "dayjs";
import { DATE_STRING_FORMAT } from "@/app/Import/Utils/pasteTemplate";

export function parseIndieCampers(
  grid: AgGridReact,
  row: any,
): RelocationItemInput | null {
  //Vehicle
  //
  // Reference
  //
  // Pickup
  //
  // Dropoff
  //
  // Check-in Date
  //
  // Check-out Date
  //
  // Days allowed
  //
  // Mileage
  //
  // Fuel Allowance
  //
  // Already requested

  const fields = [
    "vehicle",
    "line",
    "pickup",
    "dropoff",
    "checkin",
    "days",
    "checkout",
    "days_allowed",
    "mileage",
    "fuel_allowance",
    "already_requested",
  ] as const;

  const rowObject: Partial<RelocationItemInput> = {
    quantity: 1,
  };

  if (!row.at(0)) {
    return null;
  }

  if (row.at(0) === "TRUE") {
    return null;
  }

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "vehicle":
        rowObject.vehicle_code = item;
        break;
      case "checkin":
        rowObject.available_from = dayjs(item, "D-MMM-YY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "checkout":
        rowObject.available_to = dayjs(item, "D-MMM-YY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "pickup":
        rowObject.departure_office_code = item;
        break;
      case "dropoff":
        rowObject.delivery_office_code = item;
        break;

      case "fuel_allowance":
        rowObject.fuel_amount = parseInt(
          item.replace("$", "").replace(",", ""),
        );
        rowObject.fuel_note = "Against receipts";
        break;

      case "days_allowed":
        rowObject.hire_units_allowed = parseInt(item);
        break;

      case "line":
        rowObject.line = item;
        break;

      case "mileage":
        rowObject.distance_allowed = parseInt(item.replace(",", ""));
        break;

      default:
        break;
    }
  });

  return rowObject as RelocationItemInput;
}
