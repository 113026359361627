import { ReactNode } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "@/components/catalyst/description-list";
import { BookingListItem } from "@/app/Bookings/GraphQL/bookingListQuery";
import { formatDate } from "@/lib/Formatters/formatDate";
import { inclusionIconMap } from "@/app/Relocations/Utils/inclusionIconMap";
import {
  formatCurrency,
  formatDistance,
} from "@/lib/Formatters/formatCurrency";
import { useTranslation } from "react-i18next";

export function RelocationDetailsDialog({
  children,
  relocation,
}: {
  children: ReactNode;
  relocation: BookingListItem["relocation"];
}) {
  const { t } = useTranslation("relocation");

  return (
    <Dialog>
      <DialogTrigger className="text-left transition-colors hover:bg-gray-100">
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{`${relocation.departureOffice.name} to ${relocation.deliveryOffice.name} (${relocation.id})`}</DialogTitle>
          <DialogDescription>
            {formatDate(relocation.available_from_date)} -{" "}
            {formatDate(relocation.available_to_date)}
          </DialogDescription>
        </DialogHeader>
        <DescriptionList>
          <DescriptionTerm>Vehicle</DescriptionTerm>
          <DescriptionDetails>{relocation.vehicle.name}</DescriptionDetails>

          <DescriptionTerm>Duration</DescriptionTerm>
          <DescriptionDetails>
            {relocation.hire_units_allowed} +{" "}
            {relocation.extra_hire_units_allowed}
            {" x "}
            {t(`hire_unit_type.${relocation.hire_unit_type}`)}s
          </DescriptionDetails>

          <DescriptionTerm>Distance</DescriptionTerm>
          <DescriptionDetails>
            {formatDistance(
              relocation.distance_allowed,
              relocation.measurement,
            )}
          </DescriptionDetails>

          <DescriptionTerm>Inclusions</DescriptionTerm>
          <DescriptionDetails>
            <ul>
              {relocation.inclusions.map(({ id, type, value, description }) => {
                const Icon = inclusionIconMap[type];

                return (
                  <li
                    key={id}
                    className="space-x-2 flex items-center text-xs text-gray-500"
                  >
                    <Icon className="w-4 h-4 text-yellow-400 flex-shrink-0" />
                    {value ? (
                      <span>{formatCurrency(value, relocation.currency)}</span>
                    ) : null}
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: description ?? "--",
                      }}
                    />
                  </li>
                );
              })}

              {relocation.inclusions.length === 0 ? "--" : null}
            </ul>
          </DescriptionDetails>

          <DescriptionTerm>Insurance</DescriptionTerm>
          <DescriptionDetails>
            {relocation.supplierInsuranceOption?.name ?? "--"}
          </DescriptionDetails>

          <DescriptionTerm>Bond</DescriptionTerm>
          <DescriptionDetails>
            {formatCurrency(
              relocation.supplierInsuranceOption?.bond_amount,
              relocation.currency,
            )}
          </DescriptionDetails>

          <DescriptionTerm>Standard liability</DescriptionTerm>
          <DescriptionDetails>
            {formatCurrency(
              relocation.supplierInsuranceOption?.standard_liability_amount,
              relocation.currency,
            )}
          </DescriptionDetails>

          {relocation.supplierInsuranceOption?.liability_reduction_options ? (
            <>
              <DescriptionTerm>Reduction options</DescriptionTerm>
              <DescriptionDetails>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      relocation.supplierInsuranceOption
                        .liability_reduction_options,
                  }}
                />
              </DescriptionDetails>
            </>
          ) : null}
        </DescriptionList>
      </DialogContent>
    </Dialog>
  );
}
