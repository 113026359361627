import { useForm } from "../../Components/Form/Hooks/useForm";
import { Badge } from "@/components/catalyst/badge";

export function FormationUnsavedChangesBadge() {
  const { hasUnsavedChanges } = useForm();

  if (!hasUnsavedChanges) return null;

  return <Badge color="yellow">Unsaved changes</Badge>;
}
