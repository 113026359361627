import { SignUp } from "@clerk/clerk-react";
import {
  changeOrganisationRoute,
  signInRoute,
} from "@/app/Auth/Routes/loginRoutes";

export function SignUpScreen() {
  return (
    <div>
      <SignUp
        signInUrl={signInRoute.to}
        forceRedirectUrl={changeOrganisationRoute.to}
        appearance={{
          elements: {
            cardBox: "shadow-none",
            logoBox: "hidden",
            formFieldInput:
              "w-full rounded-md border-gray-300 shadow-sm sm:text-sm focus:border-indigo-500 focus:ring-indigo-500",
            formButtonPrimary:
              "relative flex h-[38px] items-center justify-center overflow-hidden px-3 text-sm font-medium bg-indigo-500 hover:bg-indigo-600 capitalize rounded-md",
          },
        }}
      />
    </div>
  );
}
