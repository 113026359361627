import { Field, FieldProps, useField } from "formik";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import classNames from "classnames";
import { useForm } from "../Hooks/useForm";
import { NumericFormat } from "react-number-format";
import {
  formatCurrency,
  getCurrencySymbol,
} from "@/lib/Formatters/formatCurrency";
import { SelectOption } from "@/lib/Components/Form/Components/Select";
import { Currency } from "@/gql/graphql";
import { GenericFieldProps } from "@/lib/Components/Form/Fields/GenericField";

type TextWithDropDownInputProps = Omit<
  GenericFieldProps,
  "name" | "viewNode" | "children"
> & {
  selectName: string;
  inputName: string;
  options: SelectOption<string>[];
  type?: "text" | "number" | "currency";
  value?: ReactNode;
  currency?: Currency;
  inputProps: ComponentPropsWithoutRef<"input">;
};

export function TextWithSelectInput({
  inputName,
  selectName,
  options,
  label,
  type = "text",
  value,
  optionalLabel,
  currency,
  className,
  inputProps,
}: TextWithDropDownInputProps) {
  const { isEditing } = useForm();
  const [, { error, touched }] = useField(inputName);
  const showError = touched && error;

  return (
    <div className={className}>
      {!isEditing ? (
        <div>{value}</div>
      ) : (
        <>
          <label className="block text-sm font-medium text-gray-700">
            {label}
            {optionalLabel && (
              <span className="ml-2 inline-flex items-center text-gray-500">
                (optional)
              </span>
            )}
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <Field name={inputName}>
              {({ field, form }: FieldProps) => {
                if (type === "currency") {
                  return (
                    <NumericFormat
                      className={classNames(
                        showError
                          ? "border-red-300 text-red-900 focus:border-red-500 focus:outline-none focus:ring-red-500"
                          : "focus:border-indigo-500 focus:ring-indigo-500",
                        "mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm",
                      )}
                      thousandSeparator=","
                      decimalSeparator="."
                      placeholder={formatCurrency(0, currency!, {
                        showZero: true,
                      })}
                      decimalScale={2}
                      defaultValue={field.value ? field.value / 100 : undefined}
                      prefix={getCurrencySymbol(currency!)}
                      value={field.value ? field.value / 100 : ""}
                      onValueChange={(values) => {
                        form.setFieldValue(
                          inputName,
                          (values.floatValue ?? 0) * 100,
                        );
                      }}
                      name={inputName}
                      onBlur={field.onBlur}
                    />
                  );
                }

                return (
                  <input
                    {...field}
                    {...inputProps}
                    type={type}
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pl-16"
                  />
                );
              }}
            </Field>

            <div className="absolute inset-y-0 left-0 flex items-center">
              <Field name={selectName}>
                {({ field }: FieldProps) => {
                  return (
                    <select
                      {...field}
                      value={field.value ?? options[0].value}
                      className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      {options.map(({ value: optionValue, label }) => (
                        <option key={optionValue} value={optionValue}>
                          {label}
                        </option>
                      ))}
                    </select>
                  );
                }}
              </Field>
            </div>
          </div>
          {touched && error && (
            <p className="mt-0.5 text-xs text-red-600">{error}</p>
          )}
        </>
      )}
    </div>
  );
}
