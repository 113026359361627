import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { Region, State } from "@/gql/graphql";
import { useTranslation } from "react-i18next";

const stateAndRegion: {
  [key in State]: Region;
} = {
  [State.Fj]: Region.Pi,
  [State.Id]: Region.Pi,
  [State.Nc]: Region.Pi,
  [State.Pg]: Region.Pi,
  [State.Sb]: Region.Pi,
  [State.Vu]: Region.Pi,
  [State.Ax]: Region.Eu,
  [State.Ad]: Region.Eu,
  [State.Az]: Region.Eu,
  [State.Fa]: Region.Eu,
  [State.Fo]: Region.Eu,
  [State.Ge]: Region.Eu,
  [State.Gg]: Region.Eu,
  [State.Gi]: Region.Eu,
  [State.Im]: Region.Eu,
  [State.Is]: Region.Eu,
  [State.Je]: Region.Eu,
  [State.Li]: Region.Eu,
  [State.Lu]: Region.Eu,
  [State.Mc]: Region.Eu,
  [State.Md]: Region.Eu,
  [State.Mt]: Region.Eu,
  [State.Ru]: Region.Eu,
  [State.Sj]: Region.Eu,
  [State.Sm]: Region.Eu,
  [State.Tr]: Region.Eu,
  [State.Ua]: Region.Eu,
  [State.Va]: Region.Eu,

  //EU
  [State.Al]: Region.Eu,
  [State.Am]: Region.Eu,
  [State.At]: Region.Eu,
  [State.Bg]: Region.Eu,
  [State.Ba]: Region.Eu,
  [State.Be]: Region.Eu,
  [State.By]: Region.Eu,
  [State.Ch]: Region.Eu,
  [State.Cy]: Region.Eu,
  [State.Cz]: Region.Eu,
  [State.De]: Region.Eu,
  [State.Dk]: Region.Eu,
  [State.Ee]: Region.Eu,
  [State.Es]: Region.Eu,
  [State.Fi]: Region.Eu,
  [State.Fr]: Region.Eu,
  [State.Gb]: Region.Eu,
  [State.Gr]: Region.Eu,
  [State.Hr]: Region.Eu,
  [State.Hu]: Region.Eu,
  [State.Ie]: Region.Eu,
  [State.It]: Region.Eu,
  [State.Lt]: Region.Eu,
  [State.Lv]: Region.Eu,
  [State.Me]: Region.Eu,
  [State.Mk]: Region.Eu,
  [State.Nl]: Region.Eu,
  [State.No]: Region.Eu,
  [State.Pl]: Region.Eu,
  [State.Pt]: Region.Eu,
  [State.Ro]: Region.Eu,
  [State.Rs]: Region.Eu,
  [State.Se]: Region.Eu,
  [State.Si]: Region.Eu,
  [State.Sk]: Region.Eu,
  [State.Xk]: Region.Eu,

  //AU
  [State.AuAct]: Region.Au,
  [State.AuNsw]: Region.Au,
  [State.AuNt]: Region.Au,
  [State.AuQld]: Region.Au,
  [State.AuSa]: Region.Au,
  [State.AuTas]: Region.Au,
  [State.AuVic]: Region.Au,
  [State.AuWa]: Region.Au,

  //CA
  [State.CaAb]: Region.Ca,
  [State.CaBc]: Region.Ca,
  [State.CaMb]: Region.Ca,
  [State.CaNb]: Region.Ca,
  [State.CaNl]: Region.Ca,
  [State.CaNs]: Region.Ca,
  [State.CaNt]: Region.Ca,
  [State.CaNu]: Region.Ca,
  [State.CaOn]: Region.Ca,
  [State.CaPe]: Region.Ca,
  [State.CaQc]: Region.Ca,
  [State.CaSk]: Region.Ca,
  [State.CaYt]: Region.Ca,

  //NZ
  [State.NzAuk]: Region.Nz,
  [State.NzBop]: Region.Nz,
  [State.NzCan]: Region.Nz,
  [State.NzGis]: Region.Nz,
  [State.NzHkb]: Region.Nz,
  [State.NzMbh]: Region.Nz,
  [State.NzMwt]: Region.Nz,
  [State.NzNtl]: Region.Nz,
  [State.NzStl]: Region.Nz,
  [State.NzTas]: Region.Nz,
  [State.NzTki]: Region.Nz,
  [State.NzWgn]: Region.Nz,
  [State.NzWko]: Region.Nz,
  [State.NzWtc]: Region.Nz,
  [State.NzNsn]: Region.Nz,
  [State.NzOta]: Region.Nz,

  //US
  [State.UsAk]: Region.Us,
  [State.UsAl]: Region.Us,
  [State.UsAr]: Region.Us,
  [State.UsAz]: Region.Us,
  [State.UsCa]: Region.Us,
  [State.UsCo]: Region.Us,
  [State.UsCt]: Region.Us,
  [State.UsDe]: Region.Us,
  [State.UsFl]: Region.Us,
  [State.UsGa]: Region.Us,
  [State.UsHi]: Region.Us,
  [State.UsIa]: Region.Us,
  [State.UsId]: Region.Us,
  [State.UsIl]: Region.Us,
  [State.UsIn]: Region.Us,
  [State.UsKs]: Region.Us,
  [State.UsKy]: Region.Us,
  [State.UsLa]: Region.Us,
  [State.UsMa]: Region.Us,
  [State.UsMd]: Region.Us,
  [State.UsMe]: Region.Us,
  [State.UsMi]: Region.Us,
  [State.UsMn]: Region.Us,
  [State.UsMo]: Region.Us,
  [State.UsMt]: Region.Us,
  [State.UsNc]: Region.Us,
  [State.UsNd]: Region.Us,
  [State.UsNe]: Region.Us,
  [State.UsNh]: Region.Us,
  [State.UsNj]: Region.Us,
  [State.UsNm]: Region.Us,
  [State.UsNv]: Region.Us,
  [State.UsNy]: Region.Us,
  [State.UsOh]: Region.Us,
  [State.UsOk]: Region.Us,
  [State.UsOr]: Region.Us,
  [State.UsPa]: Region.Us,
  [State.UsRi]: Region.Us,
  [State.UsSc]: Region.Us,
  [State.UsSd]: Region.Us,
  [State.UsTn]: Region.Us,
  [State.UsTx]: Region.Us,
  [State.UsUt]: Region.Us,
  [State.UsVa]: Region.Us,
  [State.UsVt]: Region.Us,
  [State.UsWa]: Region.Us,
  [State.UsWi]: Region.Us,
  [State.UsWv]: Region.Us,
  [State.UsWy]: Region.Us,
  [State.UsMs]: Region.Us,
  [State.UsDc]: Region.Us,
  [State.Jp_01]: Region.Jp,
  [State.Jp_02]: Region.Jp,
  [State.Jp_03]: Region.Jp,
  [State.Jp_04]: Region.Jp,
  [State.Jp_05]: Region.Jp,
  [State.Jp_06]: Region.Jp,
  [State.Jp_07]: Region.Jp,
  [State.Jp_08]: Region.Jp,
  [State.Jp_09]: Region.Jp,
  [State.Jp_10]: Region.Jp,
  [State.Jp_11]: Region.Jp,
  [State.Jp_12]: Region.Jp,
  [State.Jp_13]: Region.Jp,
  [State.Jp_14]: Region.Jp,
  [State.Jp_15]: Region.Jp,
  [State.Jp_16]: Region.Jp,
  [State.Jp_17]: Region.Jp,
  [State.Jp_18]: Region.Jp,
  [State.Jp_19]: Region.Jp,
  [State.Jp_20]: Region.Jp,
  [State.Jp_21]: Region.Jp,
  [State.Jp_22]: Region.Jp,
  [State.Jp_23]: Region.Jp,
  [State.Jp_24]: Region.Jp,
  [State.Jp_25]: Region.Jp,
  [State.Jp_26]: Region.Jp,
  [State.Jp_27]: Region.Jp,
  [State.Jp_28]: Region.Jp,
  [State.Jp_29]: Region.Jp,
  [State.Jp_30]: Region.Jp,
  [State.Jp_31]: Region.Jp,
  [State.Jp_32]: Region.Jp,
  [State.Jp_33]: Region.Jp,
  [State.Jp_34]: Region.Jp,
  [State.Jp_35]: Region.Jp,
  [State.Jp_36]: Region.Jp,
  [State.Jp_37]: Region.Jp,
  [State.Jp_38]: Region.Jp,
  [State.Jp_39]: Region.Jp,
  [State.Jp_40]: Region.Jp,
  [State.Jp_41]: Region.Jp,
  [State.Jp_42]: Region.Jp,
  [State.Jp_43]: Region.Jp,
  [State.Jp_44]: Region.Jp,
  [State.Jp_45]: Region.Jp,
  [State.Jp_46]: Region.Jp,
  [State.Jp_47]: Region.Jp,

  [State.BwCe]: Region.Sacu,
  [State.BwCh]: Region.Sacu,
  [State.BwGh]: Region.Sacu,
  [State.BwKg]: Region.Sacu,
  [State.BwKl]: Region.Sacu,
  [State.BwKw]: Region.Sacu,
  [State.BwNe]: Region.Sacu,
  [State.BwNw]: Region.Sacu,
  [State.BwSe]: Region.Sacu,
  [State.BwSo]: Region.Sacu,
  [State.Ls]: Region.Sacu,
  [State.NaCa]: Region.Sacu,
  [State.NaEr]: Region.Sacu,
  [State.NaHa]: Region.Sacu,
  [State.NaKa]: Region.Sacu,
  [State.NaKe]: Region.Sacu,
  [State.NaKh]: Region.Sacu,
  [State.NaKu]: Region.Sacu,
  [State.NaKw]: Region.Sacu,
  [State.NaOd]: Region.Sacu,
  [State.NaOh]: Region.Sacu,
  [State.NaOn]: Region.Sacu,
  [State.NaOs]: Region.Sacu,
  [State.NaOt]: Region.Sacu,
  [State.NaOw]: Region.Sacu,
  [State.SzHh]: Region.Sacu,
  [State.SzLu]: Region.Sacu,
  [State.SzMa]: Region.Sacu,
  [State.SzSh]: Region.Sacu,
  [State.ZaEc]: Region.Sacu,
  [State.ZaFs]: Region.Sacu,
  [State.ZaGp]: Region.Sacu,
  [State.ZaKzn]: Region.Sacu,
  [State.ZaLp]: Region.Sacu,
  [State.ZaMp]: Region.Sacu,
  [State.ZaNc]: Region.Sacu,
  [State.ZaNw]: Region.Sacu,
  [State.ZaWc]: Region.Sacu,
};

export function StateInput({
  region,
  ...props
}: Omit<SelectInputProps<any>, "options"> & {
  region?: Region | null;
}) {
  const { t } = useTranslation("city");

  const sections = [
    {
      label: "Australia",
      region: Region.Au,
      states: [
        State.AuAct,
        State.AuNsw,
        State.AuNt,
        State.AuQld,
        State.AuSa,
        State.AuTas,
        State.AuVic,
        State.AuWa,
      ],
    },
    {
      label: "United States",
      region: Region.Us,
      states: [
        State.UsAk,
        State.UsAl,
        State.UsAr,
        State.UsAz,
        State.UsCa,
        State.UsCo,
        State.UsCt,
        State.UsDe,
        State.UsFl,
        State.UsGa,
        State.UsHi,
        State.UsIa,
        State.UsId,
        State.UsIl,
        State.UsIn,
        State.UsKs,
        State.UsKy,
        State.UsLa,
        State.UsMa,
        State.UsMd,
        State.UsMe,
        State.UsMi,
        State.UsMn,
        State.UsMo,
        State.UsMt,
        State.UsNc,
        State.UsNd,
        State.UsNe,
        State.UsNh,
        State.UsNj,
        State.UsNm,
        State.UsNv,
        State.UsNy,
        State.UsOh,
        State.UsOk,
        State.UsOr,
        State.UsPa,
        State.UsRi,
        State.UsSc,
        State.UsSd,
        State.UsTn,
        State.UsTx,
        State.UsUt,
        State.UsVa,
        State.UsVt,
        State.UsWa,
        State.UsWi,
        State.UsWv,
        State.UsWy,
        State.UsMs,
        State.UsDc,
      ],
    },
    {
      label: "Canada",
      region: Region.Ca,
      states: [
        State.CaAb,
        State.CaBc,
        State.CaMb,
        State.CaNb,
        State.CaNl,
        State.CaNs,
        State.CaNt,
        State.CaNu,
        State.CaOn,
        State.CaPe,
        State.CaQc,
        State.CaSk,
        State.CaYt,
      ],
    },
    {
      label: "New Zealand",
      region: Region.Nz,
      states: [
        State.NzAuk,
        State.NzBop,
        State.NzCan,
        State.NzGis,
        State.NzHkb,
        State.NzMbh,
        State.NzMwt,
        State.NzNtl,
        State.NzStl,
        State.NzTas,
        State.NzTki,
        State.NzWgn,
        State.NzWko,
        State.NzWtc,
        State.NzNsn,
        State.NzOta,
      ],
    },
    {
      label: "Japan",
      region: Region.Jp,
      states: [State.Jp_01, State.Jp_02, State.Jp_03],
    },
    {
      label: "Europe",
      region: Region.Eu,
      states: [
        State.Al,
        State.Am,
        State.At,
        State.Bg,
        State.Ba,
        State.Be,
        State.By,
        State.Ch,
        State.Cy,
        State.Cz,
        State.De,
        State.Dk,
        State.Ee,
        State.Es,
        State.Fi,
        State.Fr,
        State.Gb,
        State.Gr,
        State.Hr,
        State.Hu,
        State.Ie,
        State.It,
        State.Lt,
        State.Lv,
        State.Me,
        State.Mk,
        State.Nl,
        State.No,
        State.Pl,
        State.Pt,
        State.Ro,
        State.Rs,
        State.Se,
        State.Si,
        State.Sk,
        State.Xk,
      ],
    },
    {
      label: "South Africa",
      region: Region.Sacu,
      states: [
        State.Ls,
        State.ZaEc,
        State.ZaFs,
        State.ZaGp,
        State.ZaKzn,
        State.ZaLp,
        State.ZaMp,
        State.ZaNc,
        State.ZaNw,
        State.ZaWc,
      ],
    },
    {
      label: "Botswana",
      region: Region.Sacu,
      states: [
        State.BwCe,
        State.BwCh,
        State.BwGh,
        State.BwKg,
        State.BwKl,
        State.BwKw,
        State.BwNe,
        State.BwNw,
        State.BwSe,
        State.BwSo,
      ],
    },
    {
      label: "Lesotho",
      region: Region.Sacu,
      states: [State.Ls],
    },
    {
      label: "Swaziland",
      region: Region.Sacu,
      states: [State.SzHh, State.SzLu, State.SzMa, State.SzSh],
    },
    {
      label: "Namibia",
      region: Region.Sacu,
      states: [
        State.NaCa,
        State.NaEr,
        State.NaHa,
        State.NaKa,
        State.NaKe,
        State.NaKh,
        State.NaKu,
        State.NaKw,
        State.NaOd,
        State.NaOh,
        State.NaOn,
        State.NaOs,
        State.NaOt,
        State.NaOw,
      ],
    },
  ];

  const options = sections
    .filter((section) => {
      if (region) {
        return section.region === region;
      }
      return true;
    })
    .map((section) => ({
      label: section.label,
      options: section.states
        .map((state) => ({
          value: state,
          label: t(`state.${state}`) as string,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    }));

  return (
    <SelectInput
      options={options}
      filter={(value, search) => {
        const label: string = t(`state.${value as State}`);
        return label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
      }}
      {...props}
    />
  );
}
