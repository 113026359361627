import { RecordScreenFormCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenFormCard";
import { PaidDriverDetailsForm } from "@/app/PaidDrivers/PaidDrivers/Forms/PaidDriverDetailsForm";
import { DescriptionList } from "@/lib/Components/DescriptionList/DescriptionListCard";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { usePaidDriverRecord } from "@/app/PaidDrivers/PaidDrivers/Hooks/usePaidDriverRecord";

export function PaidDriverDetailScreen() {
  const record = usePaidDriverRecord();

  return (
    <>
      <RecordScreenCard title={"User"}>
        <DescriptionList
          items={[
            {
              key: "name",
              value: record.user.name,
            },
            {
              key: "email",
              value: record.user.primary_email,
            },
            {
              key: "phone",
              value: record.user.primary_phone,
            },
          ]}
        />
      </RecordScreenCard>

      <RecordScreenFormCard
        title={"Details"}
        initialValues={{
          bio: record?.bio ?? "",
          basedIn: {
            connect: record.basedIn.id,
          },
        }}
        onSubmit={() => {
          alert("NYI");
        }}
      >
        <PaidDriverDetailsForm />
      </RecordScreenFormCard>
    </>
  );
}
