import { ModelType } from "@/gql/graphql";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { BookingDuplicateWarning } from "../Components/Banners/BookingDuplicateWarning";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import { BookingPaymentCard } from "@/app/Bookings/Components/Cards/BookingPaymentCard";
import { BookingTripCard } from "@/app/Bookings/Components/Cards/BookingTripCard";
import { BookingCustomerCard } from "@/app/Bookings/Components/Cards/BookingCustomerCard";
import { BookingVehicleCard } from "@/app/Bookings/Components/Cards/BookingVehicleCard";
import { BookingInsuranceCard } from "@/app/Bookings/Components/Cards/BookingInsuranceCard";
import { BookingNoPaymentWarning } from "@/app/Bookings/Components/Banners/BookingNoPaymentWarning";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { Link } from "@tanstack/react-router";

export function BookingDetailsScreen() {
  const record = useBookingRecord();

  return (
    <div className="py-6 space-y-6">
      {record.original_relocation_id !== record.relocation.id ? (
        <WarningBanner>
          <p className="text-sm">
            The relocation has been changed since the booking was created{" "}
            <Link
              to="/relocations/$relocationId"
              params={{
                relocationId: record.original_relocation_id,
              }}
            >
              View original
            </Link>
          </p>
        </WarningBanner>
      ) : null}

      <BookingDuplicateWarning booking={record} />

      <div className="grid  xl:grid-cols-7 gap-6">
        <div className="col-span-4 space-y-6">
          <BookingCustomerCard />
          <BookingTripCard booking={record} />
          <BookingVehicleCard />
          <BookingInsuranceCard />
        </div>
        <div className="col-span-3 space-y-6">
          <BookingPaymentCard />
          <BookingNoPaymentWarning />
          <ActivityLogFeed modelId={record.id} modelType={ModelType.Booking} />
        </div>
      </div>
    </div>
  );
}
