import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import dayjs from "dayjs";
import { DATE_STRING_FORMAT } from "@/app/Import/Utils/pasteTemplate";

export function parseRCM(
  grid: AgGridReact,
  row: any,
): RelocationItemInput | null {
  const fields = [
    "departure_location",
    "delivery_location",
    "departure_date",
    "delivery_date",
    "price",
    "days_allowed",
    "extra_days",
    "extra_day_cost",
    "vehicle",
    "pax",
    "fuel_allowance",
    "mileage",
    "bond",
    "line",
    "admin_fee",
    "linen_pack",
  ] as const;

  const rowObject: Partial<RelocationItemInput> = {
    quantity: 1,
  };

  if (!row.at(0)) {
    return null;
  }

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "vehicle":
        rowObject.vehicle_code = item;
        break;
      case "departure_date":
        rowObject.available_from = dayjs(item, "DD/MM/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "departure_location":
        rowObject.departure_office_code = item;
        break;
      case "delivery_date":
        rowObject.available_to = dayjs(item, "DD/MM/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "delivery_location":
        rowObject.delivery_office_code = item;
        break;

      case "extra_day_cost":
        rowObject.extra_hire_unit_rate = parseInt(item) || null;
        break;

      case "price":
        rowObject.hire_unit_rate = parseInt(item) || 1;
        break;

      case "extra_days":
        rowObject.extra_hire_units_allowed = parseInt(item);
        break;

      case "fuel_allowance":
        rowObject.fuel_amount = parseInt(item.replace("$", ""));
        rowObject.fuel_note = "Against receipts";
        break;

      case "days_allowed":
        rowObject.hire_units_allowed = parseInt(item);
        break;

      case "line":
        rowObject.line = item;
        break;

      case "admin_fee":
        rowObject.toll_charge_note =
          "Compulsory toll charge (payable on pickup)";
        rowObject.toll_charge_amount = parseInt(item.replace("$", "")) || null;
        break;
      default:
        break;
    }
  });

  return rowObject as RelocationItemInput;
}
