import {
  SettingCard,
  SettingFormCard,
} from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { Measurement, UpdateSupplierInput } from "@/gql/graphql";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { useTranslation } from "react-i18next";
import { DistanceAllowedInput } from "@/lib/Components/Form/Inputs/DistanceAllowedInput";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { SupplierPurchasableExtrasTable } from "@/app/Suppliers/Components/SupplierPurchasableExtrasTable";
import { redirect } from "@tanstack/react-router";
import { settingsRoute } from "@/app/Settings/Settings/Routes/settingsRoute";

export function SettingsExtrasScreen() {
  const { supplier } = useGlobalSupplier();
  if (!supplier) {
    throw redirect({
      to: settingsRoute.to,
    });
  }
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const { t } = useTranslation("relocation");
  const hireUnitLabel = t(`hire_unit_type.${supplier.hire_unit_type}`);

  return (
    <div className="space-y-12">
      <SettingFormCard
        title="Extra days"
        description="Allow customers to rent for extra days. Extra days are charged at your normal rate(s) and paid to you at pickup"
        initialValues={{
          default_extra_hire_units_allowed:
            supplier.default_extra_hire_units_allowed,
          extra_distance_allowed_per_extra_hire_unit:
            supplier.extra_distance_allowed_per_extra_hire_unit,
        }}
        onSubmit={async (values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
      >
        <NumberInput
          name="default_extra_hire_units_allowed"
          label={`Maximum extra ${hireUnitLabel}s`}
          inputProps={{
            min: 0,
          }}
        />
        <DistanceAllowedInput
          name="extra_distance_allowed_per_extra_hire_unit"
          label={`${
            supplier.measurement === Measurement.Metric ? "Kms" : "Mls"
          } per extra ${hireUnitLabel}`}
          measurement={supplier.measurement}
        />
      </SettingFormCard>

      <SettingFormCard
        title="Excess mileage"
        description="If the vehicle is driven more than the allowed distance, specify the cost per extra km or ml."
        initialValues={{
          excess_distance_rate: supplier.excess_distance_rate,
        }}
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
      >
        <CurrencyInput
          currency={supplier.currency}
          name={"excess_distance_rate"}
          label={`Cost per excess ${
            supplier.measurement === Measurement.Metric ? "km" : "ml"
          }`}
        />
      </SettingFormCard>

      <SettingFormCard<UpdateSupplierInput>
        title="Standard inclusions"
        description="Specify what is included in the hire. This will be displayed to the customer on every listing."
        initialValues={{
          standard_inclusions: supplier.standard_inclusions,
        }}
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
      >
        <RichTextInput
          name={"standard_inclusions"}
          label={"Standard inclusions"}
          className="col-span-full"
        />
      </SettingFormCard>

      <SettingCard
        title="Purchaseable extras"
        description="On hire extra's your customers can purchase, payable to you at pickup."
      >
        <SupplierPurchasableExtrasTable supplier={supplier} title={null} />
      </SettingCard>
    </div>
  );
}
