import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Avatar } from "@/components/catalyst/avatar";
import { getInitials } from "@/lib/Utils/getInitials";
import { CopyToClipboard } from "@/app/Dashboard/Components/BookingTable";
import { StarIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/utils";
import { UserFieldsFragment } from "@/gql/graphql";

export function WaitlistUserCard({ user }: { user: UserFieldsFragment }) {
  return (
    <Card className="">
      <CardHeader className="relative h-10 translate-y-2 flex justify-center">
        <Avatar
          className="size-24 mx-auto border-gray-300 border shadow-md bg-white"
          media={user.contact?.profilePicture}
          initials={getInitials(user.name ?? "")}
        />
      </CardHeader>

      <CardContent className="p-6 text-sm">
        <div className="grid gap-3 ">
          <div className="font-semibold">User Information</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">User</dt>
              <dd>
                <CopyToClipboard text={user.name ?? "--"} />
              </dd>
            </div>
            {user.primary_email ? (
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Email</dt>
                <dd>
                  <CopyToClipboard text={user.primary_email} />
                </dd>
              </div>
            ) : null}
            {user.primary_phone ? (
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Phone</dt>
                <dd>
                  <CopyToClipboard text={user.primary_phone} />
                </dd>
              </div>
            ) : null}
          </dl>
          {user.waitlistPreference ? (
            <>
              <div className="font-semibold">Waitlist Preferences</div>
              <dl className="grid gap-3">
                <div className="flex items-center justify-between">
                  <dt className="text-muted-foreground">Vehicle category</dt>
                  <dd>{user.waitlistPreference?.vehicle_category ?? "--"}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-muted-foreground">
                    Number of travellers
                  </dt>
                  <dd>
                    {user.waitlistPreference.number_of_travellers ?? "--"}
                  </dd>
                </div>
              </dl>
            </>
          ) : null}
        </div>
      </CardContent>
      <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
        <div className="text-muted-foreground flex items-center relative w-full">
          <span className="text-sm text-gray-700">Rating: 3.9</span>
          <div className="ml-1 flex items-center">
            {[0, 1, 2, 3, 4].map((rating) => (
              <StarIcon
                key={rating}
                aria-hidden="true"
                className={cn(
                  4 > rating ? "text-yellow-400" : "text-gray-200",
                  "h-5 w-5 flex-shrink-0",
                )}
              />
            ))}
          </div>

          <div className="absolute inset-0 backdrop-blur-[1px] flex justify-center items-center">
            <span>🔒 coming soon</span>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
