import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { WaitlistEditDialog } from "@/app/Waitlists/Components/WaitlistEditDialog";
import { UserActivityDialog } from "@/app/Users/Components/UserActivityDialog";
import { ActivityIcon } from "lucide-react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { deleteWaitlistMutation } from "@/app/Waitlists/GraphQL/waitlistMutations";
import { WaitlistListItem } from "@/app/Waitlists/GraphQL/waitlistListQuery";

export function useWaitlistActions(): ActionsHook<WaitlistListItem> {
  const { mutateAsync } = useGqlMutation(deleteWaitlistMutation);
  const { open } = useDialog(WaitlistEditDialog);
  const { open: openActivity } = useDialog(UserActivityDialog);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Activity",
                Icon: ActivityIcon,
                handleAction: () => {
                  openActivity({
                    userId: model.user.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                handleAction: async () => {
                  open({
                    waitlist: model,
                  });
                },
                intent: "neutral",
              },
              {
                label: "Delete",
                Icon: TrashIcon,
                handleAction: async () => {
                  await mutateAsync({ id: model.id });
                },
                intent: "danger",
              },
            ],
          },
        ],
      },
    ];
  };
}
