import { UpdateVehicleInput } from "@/gql/graphql";
import { RecordScreenFormCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenFormCard";
import { VehicleForm } from "@/app/Vehicles/Forms/VehicleForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateVehicleMutation } from "@/app/Vehicles/GraphQL/vehicleMutations";
import { useVehicleRecord } from "@/app/Vehicles/Hooks/useVehicleRecord";

export function VehicleDetailsScreen() {
  const record = useVehicleRecord();
  const { mutateAsync } = useGqlMutation(updateVehicleMutation);

  return (
    <div className="py-6">
      <RecordScreenFormCard<UpdateVehicleInput>
        title="Details"
        initialValues={{
          name: record.name,
          code: record.code,
          description: record.description,
          supplier: {
            connect: record.supplier.id,
          },
          model: record.model,
          brand: record.brand,
          seatbelts: record.seatbelts,
          type: record.type,
          fuel: record.fuel,
          transmission: record.transmission,
          extra_hire_unit_rate: record.extra_hire_unit_rate,
          extra_hire_unit_deposit_rate: record.extra_hire_unit_deposit_rate,
          extra_hire_unit_supplier_net_rate:
            record.extra_hire_unit_supplier_net_rate,
          hire_unit_rate: record.hire_unit_rate,
          hire_unit_type: record.hire_unit_type,

          approximate_age: record.approximate_age,
          child_seat_anchor_points: record.child_seat_anchor_points,
          has_heating: record.has_heating,
          has_kitchen: record.has_kitchen,
          has_shower_and_toilet: record.has_shower_and_toilet,
          is_pet_friendly: record.is_pet_friendly,
          is_self_contained: record.is_self_contained,
          pet_cleaning_fee: record.pet_cleaning_fee,
          sleeps: record.sleeps,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
      >
        <VehicleForm />
      </RecordScreenFormCard>
    </div>
  );
}
