import { FC, ReactNode, useMemo } from "react";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { get } from "lodash";
import { PlusIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Button } from "../../Button/Button";
import { useForm } from "../Hooks/useForm";
import { IconButton } from "../../Button/IconButton";
import { BaggageClaimIcon } from "lucide-react";

type FieldArrayProps<T> = {
  label?: ReactNode;
  children: (item: T, index: number, isEditing: boolean) => ReactNode;
  viewChildren?: ReactNode;
  fieldArrayKey: string;
  lineItemLabel?: string;
  newLineItemValues?: any;
  footer?: ReactNode;
  Header?: FC<FieldArrayHeaderProps>;
  addLineItemButton?: ({
    fieldHelpers,
  }: {
    fieldHelpers: FieldArrayRenderProps;
  }) => ReactNode;
  onRemove?: (index: number) => void;
  onAdd?: (index: number) => void;
  emptyState?: ReactNode;
  Icon?: FC<any>;
};

export interface FieldArrayHeaderProps<TData = any> {
  index: number;
  item: TData;
  fieldHelpers: FieldArrayRenderProps;
  fieldArrayKey: string;
  onRemove?: (index: number) => void;
}

export function GenericFieldArray<T extends object>({
  label,
  children,
  fieldArrayKey,
  newLineItemValues,
  footer,
  Header,
  addLineItemButton,
  onRemove,
  onAdd,
  emptyState,
  lineItemLabel = "line item",
  viewChildren,
  Icon = BaggageClaimIcon,
}: FieldArrayProps<T>) {
  const { values, initialValues, setFieldValue, isEditing } = useForm();

  const newValue = () =>
    newLineItemValues !== undefined
      ? newLineItemValues
      : get(initialValues, `${fieldArrayKey}[0]`);

  const items = useMemo(() => {
    return get(values, fieldArrayKey) ?? [];
  }, [values]);

  if (!isEditing && viewChildren) {
    return viewChildren;
  }

  return (
    <FieldArray name={fieldArrayKey}>
      {(fieldHelpers: any) => (
        <div className="col-span-full">
          <h2 className="flex h-5 items-center space-x-1 truncate text-sm font-medium text-gray-700">
            {label}
          </h2>
          <div className={classNames("rounded-lg bg-gray-50 px-6 py-3")}>
            <ul>
              {items.map((item: T, index: number) => {
                return (
                  <li key={index}>
                    {Header ? (
                      <Header
                        index={index}
                        onRemove={onRemove}
                        item={item}
                        fieldArrayKey={fieldArrayKey}
                        fieldHelpers={fieldHelpers}
                      />
                    ) : (
                      <div className="flex items-center text-xs font-semibold uppercase tracking-wider text-gray-500">
                        <div className="flex h-12 flex-grow items-center space-x-4 divide-x-[1px] divide-gray-300">
                          {lineItemLabel} {index + 1}
                        </div>
                        <div>
                          {isEditing ? (
                            <IconButton
                              tooltip="Remove"
                              intent="danger"
                              Icon={TrashIcon}
                              onClick={async () => {
                                fieldHelpers.remove(index);

                                const deleteKey = fieldArrayKey.replace(
                                  "upsert",
                                  "delete",
                                );

                                const val = get(values, deleteKey) ?? [];

                                if (item && "id" in item) {
                                  const id = item.id;
                                  await setFieldValue(deleteKey, [...val, id]);
                                }

                                onRemove?.(index);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    )}
                    <div className="mb-3 grid w-full grid-cols-12 gap-x-6">
                      {children(item, index, isEditing)}
                    </div>
                  </li>
                );
              })}
            </ul>

            {isEditing ? (
              <>
                {items?.length === 0 ? (
                  <button
                    type="button"
                    className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      fieldHelpers.push(newValue());
                      onAdd?.(items.length);
                    }}
                  >
                    <Icon className="mx-auto h-12 w-12 text-gray-400" />
                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                      Add {lineItemLabel}
                    </span>
                  </button>
                ) : (
                  <>
                    {addLineItemButton ? (
                      addLineItemButton({ fieldHelpers })
                    ) : (
                      <Button
                        type="outline"
                        onClick={() => {
                          fieldHelpers.push(newValue());
                          onAdd?.(items.length);
                        }}
                        LeadingIcon={PlusIcon}
                      >
                        Add {lineItemLabel}
                      </Button>
                    )}
                  </>
                )}
              </>
            ) : (
              <>{items.length === 0 ? emptyState : null}</>
            )}

            {footer}
          </div>
        </div>
      )}
    </FieldArray>
  );
}
