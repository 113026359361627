import { GenericField, InputProps } from "../Fields/GenericField";
import { useField } from "formik";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Calendar, CalendarProps } from "@/components/ui/calendar";
import dayjs from "dayjs";

export type DatePickerCalendarProps = Omit<
  Partial<CalendarProps>,
  "mode" | "selected"
>;

export type DateInputProps = InputProps & {
  min?: string;
  max?: string;
  onChange?: (val: string | null) => void;
  calendarProps?: DatePickerCalendarProps;
};

export function DateInput({
  min,
  max,
  onChange,
  calendarProps,
  ...props
}: DateInputProps) {
  const { emptyValue = null } = props;
  const [field, meta, { setValue }] = useField<string | null>(props.name);
  const { value } = field;
  const showError = !!meta.error && meta.touched;

  return (
    <GenericField viewNode={<span>{formatDate(value)}</span>} {...props}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            aria-label="select a date"
            className={cn(
              "w-full justify-start text-left font-normal border-gray-300 text-sm shadow-sm transition-shadow duration-100",
              !value && "text-muted-foreground",
              {
                "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
                  showError,
                "focus:border-indigo-500 focus:ring-indigo-500": !showError,
              },
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value ? (
              <span>{formatDate(value)}</span>
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            defaultMonth={new Date(value ?? Date.now())}
            disabled={(date) => {
              const day = dayjs(date);
              if (min && dayjs(min).isAfter(day)) {
                return true;
              }

              if (max && dayjs(max).isBefore(day)) {
                return true;
              }

              return false;
            }}
            selected={value ? new Date(value) : undefined}
            onSelect={async (date) => {
              if (date) {
                await setValue(date.toDateString());
                onChange?.(date.toDateString());
              } else {
                await setValue(emptyValue);
                onChange?.(emptyValue);
              }
            }}
            initialFocus
            {...calendarProps}
          />
        </PopoverContent>
      </Popover>
    </GenericField>
  );
}
