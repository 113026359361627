import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";

export function ImportTypeInput(props: Omit<SelectInputProps<any>, "options">) {
  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "type",
          options: [
            {
              value: "new",
              label: "Start a new list",
            },
            {
              value: "append",
              label: "Append to existing list",
            },
          ],
        },
      ]}
    />
  );
}
