import {
  CreateRelocationInput,
  Measurement,
  RelocationInclusionType,
  RelocationRatingFieldsFragment,
} from "@/gql/graphql";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { LineChartIcon } from "lucide-react";
import { Badge } from "@/lib/Components/Badges/Badge";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { DescriptionList } from "@/lib/Components/DescriptionList/DescriptionListCard";
import { relocationRatingQuery } from "@/app/Relocations/GraphQL/relocationRatingQuery";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { keepPreviousData } from "@tanstack/react-query";
import { cn } from "@/lib/utils";

export function RelocationRatingCard() {
  const { values } = useForm<CreateRelocationInput>();

  let fuelAmount = 0;
  values.inclusions?.upsert?.forEach((inclusion) => {
    if (inclusion?.type === RelocationInclusionType.Fuel) {
      fuelAmount += inclusion.value ?? 0;
    }
  });

  const { data, isFetching } = useGqlQuery(
    relocationRatingQuery,
    {
      input: {
        available_from_date: values.available_from_date,
        available_to_date: values.available_to_date,
        distance_allowed: values.distance_allowed,
        extra_hire_units_allowed: values.extra_hire_units_allowed,
        fixed_latest_departure_date: values.fixed_latest_departure_date,
        fuel_allowance: fuelAmount,
        hire_unit_rate: values.hire_unit_rate,
        hire_unit_type: values.hire_unit_type,
        hire_units_allowed: values.hire_units_allowed,
        minimum_hire_units: values.minimum_hire_units,
        vehicle_id: values.vehicle?.connect!,
        supplier_id: values.supplier?.connect!,
        departure_office_id: values.departureOffice?.connect!,
        delivery_office_id: values.deliveryOffice?.connect!,
        measurement: values.measurement,
      },
    },
    {
      placeholderData: keepPreviousData,
    },
  );

  if (!data) {
    return null;
  }

  return (
    <div
      className={cn(
        `bg-gray-200 rounded-md overflow-hidden px-4 py-6 sm:px-6 lg:px-8 flex items-center space-x-2`,
        { "animate-pulse": isFetching },
      )}
    >
      <LineChartIcon className="w-6 h-6" />
      <span>Relocation rating:</span>
      <span className="font-bold mx-2">
        {data.relocationRating.total_score}/{data.relocationRating.score_out_of}
      </span>
      <Badge label="Beta" intent="highlight" />
      <Dialog>
        <DialogTrigger>
          <InformationCircleIcon className="w-6 h-6 text-gray-500" />
        </DialogTrigger>
        <DialogContent className="max-w-2xl">
          <DialogTitle className="flex items-center space-x-2">
            <span>
              Relocation rating: <Badge label="Beta" intent="highlight" />
            </span>
            <span className="font-bold mx-2">
              {data.relocationRating.total_score}/
              {data.relocationRating.score_out_of}
            </span>
          </DialogTitle>
          <RelocationRating data={data.relocationRating} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export function RelocationRating({
  data,
}: {
  data: RelocationRatingFieldsFragment;
}) {
  const {
    scores: {
      flexibility_score,
      distance_allowed_score,
      time_allowed_score,
      fuel_allowance_score,
      lead_time_score,
      price_score,
    },
    recommended_hire_units_allowed,
    recommended_distance_allowed,
    measurement,
  } = data;

  const units = measurement === Measurement.Imperial ? "miles" : "kms";

  return (
    <DescriptionList
      items={[
        {
          key: "Distance allowed",
          value: distance_allowed_score
            ? "✅"
            : `❌ We recommend at least ${recommended_distance_allowed} ${units} for this relocation.`,
        },
        {
          key: "Time allowed",
          value: time_allowed_score
            ? "✅"
            : `❌ We recommend at least ${recommended_hire_units_allowed} day(s) for this relocation. We suggest you allow for 3hrs of driving per day.`,
        },
        {
          key: "Flexibility",
          value: flexibility_score
            ? "✅"
            : "❌ Not enough valid departure dates. Too small of a window for the customer to pick up the vehicle. Remember to consider what days your depot is open.",
        },
        {
          key: "Fuel",
          value: fuel_allowance_score
            ? "✅"
            : "❌ Consider adding fuel to the relocation to make it more attractive to drivers.",
        },
        {
          key: "Lead time",
          value: lead_time_score
            ? "✅"
            : "❌ Not enough time until the latest departure date. Consider extending the available to date.",
        },
        {
          key: "Price",
          value: price_score
            ? "✅"
            : "❌ Consider making the price per day $5.00 or less.",
        },
      ]}
    />
  );
}
