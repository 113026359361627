import { organisationRoute } from "@/app/Organisations/Routes/organisationRoutes";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { organisationRecordQueryGraphql } from "@/app/Organisations/GraphQL/organisationRecordQuery.graphql";

export function useOrganisationRecord() {
  const { id } = organisationRoute.useParams();

  const { data } = useSuspenseGqlQuery(organisationRecordQueryGraphql, {
    id,
  });

  if (!data.organisation) {
    throw new Error("Could not find organisation");
  }

  return data.organisation;
}
