import { SidebarList } from "@/lib/Components/SideBarList/SideBarList";
import { VehicleIcon } from "@/app/Vehicles/Components/VehicleIcon";
import { useSearch } from "@tanstack/react-router";
import { useState } from "react";
import { getVehicleListQueryVariables } from "@/app/Vehicles/Utils/getVehicleListQueryVariables";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  VehicleListItem,
  vehicleListQuery,
} from "@/app/Vehicles/GraphQL/vehicleListQuery";

import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { Badge } from "@/components/catalyst/badge";

export function VehicleListScreen() {
  const searchParams = useSearch({
    from: "/_app/vehicles",
  });
  const [search, setSearch] = useState(searchParams.vehicleSearch ?? "");

  const queryVariables = getVehicleListQueryVariables({
    ...searchParams,
    vehicleSearch: search,
  });

  const { data, fetchNextPage, isFetching, isFetchingNextPage } =
    useInfiniteGqlQuery(vehicleListQuery, queryVariables, {
      getNextPageParam: (lastPage) => {
        const paginatorInfo = lastPage.vehicles.paginatorInfo;
        if (paginatorInfo.hasMorePages) {
          return {
            page: paginatorInfo.currentPage + 1,
          };
        }

        return undefined;
      },
      initialPageParam: {
        page: 1,
      },
    });

  const items = data?.pages.flatMap((i) => i.vehicles.data);
  const total = data?.pages.at(0)?.vehicles.paginatorInfo.total ?? 0;

  return (
    <SidebarList
      search={search}
      onClearFilters={() => {
        setSearch("");
      }}
      onSearchChange={(newStr) => {
        setSearch(newStr);
      }}
      listRoute={"/vehicles"}
      createRoute={"/vehicles/create"}
      items={items}
      totalCount={total}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      itemNode={(model) => <VehicleItem model={model} />}
      linkProps={(item) => ({
        to: "/vehicles/$id",
        params: {
          id: item.id,
        },
      })}
    />
  );
}

function VehicleItem({ model }: { model: VehicleListItem }) {
  const { supplier } = useGlobalSupplier();

  return (
    <div className="flex space-x-2 relative">
      <div className="">
        <VehicleIcon model={model} />
      </div>
      <div className="min-w-0 flex-1">
        <p className="truncate text-sm font-medium text-gray-900">
          {model.name}
        </p>
        <p>
          <span className="text-gray-500 text-xs">{model?.brand}</span>{" "}
          <span className="text-gray-500 text-xs">{model.model}</span>
        </p>
        <p className="text-gray-500 text-xs">{model.code}</p>
        {!supplier ? (
          <p className="text-gray-500 text-xs">{model.supplier.name}</p>
        ) : null}

        <p>
          <time dateTime={model.created_at} className="text-xs text-gray-500">
            {formatDateTimeSinceNow(model.created_at)}
          </time>
        </p>
      </div>

      {model.archived_at ? (
        <div className="absolute right-0 top-0">
          <Badge color="purple">archived</Badge>
        </div>
      ) : null}
    </div>
  );
}
