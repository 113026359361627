import { RelocationTable } from "@/app/Relocations/Components/RelocationTable";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { ReactNode } from "react";
import { RelocationStatus } from "@/gql/graphql";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useMatch, useNavigate } from "@tanstack/react-router";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationCreateDialog } from "@/app/Relocations/Components/RelocationCreateDialog";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { relocationStatusCountsQuery } from "@/app/Relocations/GraphQL/relocationStatusCountsQuery";
import { Badge, BadgeColor } from "@/components/catalyst/badge";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import {
  Navbar,
  NavbarItemLink,
  NavbarSection,
} from "@/components/catalyst/navbar";

type Filter = {
  label: ReactNode;
  statuses: RelocationStatus[];
  hideBadge?: boolean;
  badgeColor?: BadgeColor;
};

const filters: Filter[] = [
  {
    label: "Active",
    statuses: [RelocationStatus.Ready],
    badgeColor: "green",
  },
  {
    label: "Paused",
    statuses: [RelocationStatus.Paused],
    badgeColor: "zinc",
  },
  {
    label: "Sold out",
    statuses: [RelocationStatus.SoldOut],
    badgeColor: "yellow",
  },
  {
    label: "Expired",
    statuses: [RelocationStatus.Expired],
    badgeColor: "red",
  },

  {
    label: "Draft",
    statuses: [RelocationStatus.Draft],
  },
  {
    label: "Archived",
    statuses: [RelocationStatus.Archived],
    hideBadge: true,
  },
];

export function RelocationTableScreen() {
  const { search } = useMatch({
    from: "/_app/relocations",
  });

  const navigate = useNavigate({
    from: "/relocations/",
  });

  return (
    <FullPageScreen>
      <Header />
      <RelocationTable
        id="relocations"
        statuses={search?.relocationStatus}
        onStatusChange={(newStatuses) => {
          navigate({
            to: "/relocations",
            search: (old) => {
              return {
                ...old,
                relocationStatus: newStatuses,
              };
            },
          });
        }}
      />
    </FullPageScreen>
  );
}

function Header() {
  const { open } = useDialog(RelocationCreateDialog);
  const { supplierId } = useGlobalSupplier();
  const { data } = useGqlQuery(relocationStatusCountsQuery, {
    supplierId: supplierId ?? undefined,
  });

  return (
    <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
      <div className="md:flex md:items-center md:justify-between">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Relocations
        </h3>
        <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({});
            }}
          >
            Add relocation
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          >
            {filters.map((tab, idx) => (
              <option key={idx}>{tab.label}</option>
            ))}
          </select>
        </div>
        <Navbar className="-mb-px">
          <NavbarSection className="max-lg:hidden">
            {filters.map(({ label, statuses, hideBadge, badgeColor }, idx) => {
              const count = statuses.reduce((acc, status) => {
                return (
                  acc +
                  (data?.relocationStatusCounts.find(
                    (counts) => counts.status === status,
                  )?.count ?? 0)
                );
              }, 0);

              return (
                <NavbarItemLink
                  key={idx}
                  to="/relocations"
                  search={(old) => {
                    return {
                      ...old,
                      relocationStatus: statuses,
                    };
                  }}
                >
                  {label}

                  {count && !hideBadge ? (
                    <Badge className="py-0" color={badgeColor}>
                      {count}
                    </Badge>
                  ) : null}
                </NavbarItemLink>
              );
            })}
          </NavbarSection>
        </Navbar>
      </div>
    </div>
  );
}
