import { Region, State } from "@/gql/graphql";

const state: { [key in State]: string } = {
  FJ: "Fiji",
  ID: "Indonesia",
  NC: "New Caledonia",
  PG: "Papua New Guinea",
  SB: "Solomon Islands",
  VU: "Vanuatu",
  [State.Ad]: "Andorra",
  [State.Al]: "Albania",
  [State.Am]: "Armenia",
  [State.At]: "Austria",
  [State.AuAct]: "Australian Capital Territory",
  [State.AuNsw]: "New South Wales",
  [State.AuNt]: "Northern Territory",
  [State.AuQld]: "Queensland",
  [State.AuSa]: "South Australia",
  [State.AuTas]: "Tasmania",
  [State.AuVic]: "Victoria",
  [State.AuWa]: "Western Australia",
  [State.Ax]: "Åland Islands",
  [State.Az]: "Azerbaijan",
  [State.Ba]: "Bosnia and Herzegovina",
  [State.Be]: "Belgium",
  [State.Bg]: "Bulgaria",
  [State.By]: "Belarus",
  [State.CaAb]: "Alberta",
  [State.CaBc]: "British Columbia",
  [State.CaMb]: "Manitoba",
  [State.CaNb]: "New Brunswick",
  [State.CaNl]: "Newfoundland and Labrador",
  [State.CaNs]: "Nova Scotia",
  [State.CaNt]: "Northwest Territories",
  [State.CaNu]: "Nunavut",
  [State.CaOn]: "Ontario",
  [State.CaPe]: "Prince Edward Island",
  [State.CaQc]: "Quebec",
  [State.CaSk]: "Saskatchewan",
  [State.CaYt]: "Yukon",
  [State.Ch]: "Switzerland",
  [State.Cy]: "Cyprus",
  [State.Cz]: "Czech Republic",
  [State.De]: "Germany",
  [State.Dk]: "Denmark",
  [State.Ee]: "Estonia",
  [State.Es]: "Spain",
  [State.Fa]: "Faroe Islands",
  [State.Fi]: "Finland",
  [State.Fo]: "Faroese",
  [State.Fr]: "France",
  [State.Gb]: "United Kingdom",
  [State.Ge]: "Georgia",
  [State.Gg]: "Guernsey",
  [State.Gi]: "Gibraltar",
  [State.Gr]: "Greece",
  [State.Hr]: "Croatia",
  [State.Hu]: "Hungary",
  [State.Ie]: "Ireland",
  [State.Im]: "Isle of man",
  [State.Is]: "Iceland",
  [State.It]: "Italy",
  [State.Je]: "Jersey",
  [State.Li]: "Liechtenstein",
  [State.Lt]: "Lithuania",
  [State.Lu]: "Luxembourg",
  [State.Lv]: "Latvia",
  [State.Mc]: "Monaco",
  [State.Md]: "Moldova",
  [State.Me]: "Montenegro",
  [State.Mk]: "Macedonia",
  [State.Mt]: "Malta",
  [State.Nl]: "Netherlands",
  [State.No]: "Norway",

  [State.NzAuk]: "Auckland",
  [State.NzBop]: "Bay of Plenty",
  [State.NzCan]: "Canterbury",
  [State.NzGis]: "Gisborne",
  [State.NzHkb]: "Hawke's Bay",
  [State.NzMbh]: "Marlborough",
  [State.NzMwt]: "Manawatu-Wanganui",
  [State.NzNsn]: "Nelson",
  [State.NzNtl]: "Northland",
  [State.NzOta]: "Otago",
  [State.NzStl]: "Southland",
  [State.NzTas]: "Tasman",
  [State.NzTki]: "North Island",
  [State.NzWgn]: "Wellington",
  [State.NzWko]: "Waikato",
  [State.NzWtc]: "West Coast",

  [State.Pl]: "Poland",
  [State.Pt]: "Portugal",
  [State.Ro]: "Romania",
  [State.Rs]: "Republic of Serbia",
  [State.Ru]: "Russia",
  [State.Se]: "Sweden",
  [State.Si]: "Slovenia",
  [State.Sj]: "Svalbard and Jan Mayen",
  [State.Sk]: "Slovakia",
  [State.Sm]: "San Marino",
  [State.Tr]: "Turkey",
  [State.Ua]: "Ukraine",

  [State.UsAk]: "Alaska",
  [State.UsAl]: "Alabama",
  [State.UsAr]: "Arkansas",
  [State.UsAz]: "Arizona",
  [State.UsCa]: "California",
  [State.UsCo]: "Colorado",
  [State.UsCt]: "Connecticut",
  [State.UsDc]: "Washington, D.C.",
  [State.UsDe]: "Delaware",
  [State.UsFl]: "Florida",
  [State.UsGa]: "Georgia",
  [State.UsHi]: "Hawaii",
  [State.UsIa]: "Iowa",
  [State.UsId]: "Idaho",
  [State.UsIl]: "Illinois",
  [State.UsIn]: "Indiana",
  [State.UsKs]: "Kansas",
  [State.UsKy]: "Kentucky",
  [State.UsLa]: "Louisiana",
  [State.UsMa]: "Massachusetts",
  [State.UsMd]: "Maryland",
  [State.UsMe]: "Maine",
  [State.UsMi]: "Michigan",
  [State.UsMn]: "Minnesota",
  [State.UsMo]: "Missouri",
  [State.UsMs]: "Mississippi",
  [State.UsMt]: "Montana",
  [State.UsNc]: "North Carolina",
  [State.UsNd]: "North Dakota",
  [State.UsNe]: "Nebraska",
  [State.UsNh]: "New Hampshire",
  [State.UsNj]: "New Jersey",
  [State.UsNm]: "New Mexico",
  [State.UsNv]: "Nevada",
  [State.UsNy]: "New York",
  [State.UsOh]: "Ohio",
  [State.UsOk]: "Oklahoma",
  [State.UsOr]: "Oregon",
  [State.UsPa]: "Pennsylvania",
  [State.UsRi]: "Rhode Island",
  [State.UsSc]: "South Carolina",
  [State.UsSd]: "South Dakota",
  [State.UsTn]: "Tennessee",
  [State.UsTx]: "Texas",
  [State.UsUt]: "Utah",
  [State.UsVa]: "Virginia",
  [State.UsVt]: "Vermont",
  [State.UsWa]: "Washington",
  [State.UsWi]: "Wisconsin",
  [State.UsWv]: "West Virginia",
  [State.UsWy]: "Wyoming",
  [State.Va]: "Vatican City",
  [State.Xk]: "Kosovo",

  [State.Jp_01]: "Hokkaido",
  [State.Jp_02]: "Aomori",
  [State.Jp_03]: "Iwate",
  [State.Jp_04]: "Miyagi",
  [State.Jp_05]: "Akita",
  [State.Jp_06]: "Yamagata",
  [State.Jp_07]: "Fukushima",
  [State.Jp_08]: "Ibaraki",
  [State.Jp_09]: "Tochigi",
  [State.Jp_10]: "Gunma",
  [State.Jp_11]: "Saitama",
  [State.Jp_12]: "Chiba",
  [State.Jp_13]: "Tokyo",
  [State.Jp_14]: "Kanagawa",
  [State.Jp_15]: "Niigata",
  [State.Jp_16]: "Toyama",
  [State.Jp_17]: "Ishikawa",
  [State.Jp_18]: "Fukui",
  [State.Jp_19]: "Yamanashi",
  [State.Jp_20]: "Nagano",
  [State.Jp_21]: "Gifu",
  [State.Jp_22]: "Shizuoka",
  [State.Jp_23]: "Aichi",
  [State.Jp_24]: "Mie",
  [State.Jp_25]: "Shiga",
  [State.Jp_26]: "Kyoto",
  [State.Jp_27]: "Osaka",
  [State.Jp_28]: "Hyogo",
  [State.Jp_29]: "Nara",
  [State.Jp_30]: "Wakayama",
  [State.Jp_31]: "Tottori",
  [State.Jp_32]: "Shimane",
  [State.Jp_33]: "Okayama",
  [State.Jp_34]: "Hiroshima",
  [State.Jp_35]: "Yamaguchi",
  [State.Jp_36]: "Tokushima",
  [State.Jp_37]: "Kagawa",
  [State.Jp_38]: "Ehime",
  [State.Jp_39]: "Kochi",
  [State.Jp_40]: "Fukuoka",
  [State.Jp_41]: "Saga",
  [State.Jp_42]: "Nagasaki",
  [State.Jp_43]: "Kumamoto",
  [State.Jp_44]: "Oita",
  [State.Jp_45]: "Miyazaki",
  [State.Jp_46]: "Kagoshima",
  [State.Jp_47]: "Okinawa",

  [State.ZaEc]: "Eastern Cape",
  [State.ZaFs]: "Free State",
  [State.ZaGp]: "Gauteng",
  [State.ZaKzn]: "KwaZulu-Natal",
  [State.ZaLp]: "Limpopo",
  [State.ZaMp]: "Mpumalanga",
  [State.ZaNc]: "Northern Cape",
  [State.ZaNw]: "North West",
  [State.ZaWc]: "Western Cape",
  [State.BwCe]: "Central",
  [State.BwCh]: "Chobe",
  [State.BwGh]: "Ghanzi",
  [State.BwKg]: "Kgalagadi",
  [State.BwKl]: "Kgatleng",
  [State.BwKw]: "Kweneng",
  [State.BwNe]: "North-East",
  [State.BwNw]: "North-West",
  [State.BwSe]: "South-East",
  [State.BwSo]: "Southern",
  [State.Ls]: "Lesotho",
  [State.NaCa]: "Zambezi",
  [State.NaEr]: "Erongo",
  [State.NaHa]: "Hardap",
  [State.NaKa]: "Karas",
  [State.NaKe]: "Kavango East",
  [State.NaKh]: "Kavango West",
  [State.NaKu]: "Kunene",
  [State.NaKw]: "Khomas",
  [State.NaOd]: "Otjozondjupa",
  [State.NaOh]: "Omaheke",
  [State.NaOn]: "Omusati",
  [State.NaOs]: "Oshana",
  [State.NaOt]: "Oshikoto",
  [State.NaOw]: "Ohangwena",
  [State.SzHh]: "Hhohho",
  [State.SzLu]: "Lubombo",
  [State.SzMa]: "Manzini",
  [State.SzSh]: "Shiselweni",
};

const region: {
  [key in Region]: string;
} = {
  [Region.Pi]: "Pacific Islands",
  [Region.Au]: "Australia",
  [Region.Ca]: "Canada",
  [Region.Eu]: "Europe / UK",
  [Region.Nz]: "New Zealand",
  [Region.Us]: "United States",
  [Region.Jp]: "Japan",
  [Region.Sacu]: "Southern Africa",
};

const resource = {
  state,
  region,
};

export default resource;
export type CityResource = typeof resource;
