import { useEffect, useState } from "react";
import { useForm } from "../../Components/Form/Hooks/useForm";
import { Badge, BadgeProps } from "@/lib/Components/Badges/Badge";

export function FormationErrorBadge() {
  const { errorCount, isValid, submitCount, isEditing, isSubmitting } =
    useForm();
  const [count, setCount] = useState(submitCount);
  const isError = !(isValid || count === submitCount || !isEditing);

  useEffect(() => {
    setCount(submitCount);
  }, [isEditing]);

  useEffect(() => {
    if (!isSubmitting || !isError) return;
  }, [isSubmitting]);

  if (!isError) {
    return null;
  }

  return <ErrorBadge errorCount={errorCount} className="ml-2" />;
}

export function ErrorBadge({
  errorCount,
  ...props
}: { errorCount: number } & Partial<BadgeProps>) {
  if (errorCount === 0) {
    return null;
  }

  return (
    <Badge
      intent="danger"
      label={`${errorCount} ${errorCount === 1 ? "Error" : "Errors"}`}
      {...props}
    ></Badge>
  );
}
