import { SidebarListItemProps } from "@/lib/Components/SideBarList/SideBarList";
import { MailIcon, PhoneIcon } from "lucide-react";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { UserProfilePicture } from "@/app/Users/Components/UserProfilePicture";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { AllUserListItem } from "@/app/Users/GraphQL/allUserListQuery";

export function UserListScreen() {
  const [search, setSearch] = useState("");
  const navigate = useNavigate({
    from: "/users",
  });

  useEffect(() => {
    navigate({
      search: (s) => ({
        ...s,
        userSearch: search,
      }),
      replace: true,
    });
  }, [search]);

  return null;
}

function Item({ model }: SidebarListItemProps<AllUserListItem>) {
  return (
    <div className="flex space-x-3">
      <div className="flex items-center -ml-3">
        <UserProfilePicture model={model} />
      </div>
      <div className="min-w-0 flex-1">
        <p className="truncate text-sm font-bold text-gray-900">{model.name}</p>
        <p className="truncate text-xs text-gray-500 flex items-center">
          <MailIcon className="w-4 h-4 inline mr-2 flex-shrink-0" />
          {model.primary_email ?? "--"}
        </p>
        <p className="truncate text-xs text-gray-500 flex items-center">
          <PhoneIcon className="w-4 h-4 inline mr-2 flex-shrink-0" />
          {model.primary_phone ?? "--"}
        </p>
        <p>
          <time dateTime={model.created_at} className="text-xs text-gray-500">
            {formatDateTimeSinceNow(model.created_at)}
          </time>
        </p>
      </div>
    </div>
  );
}
