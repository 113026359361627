import dayjs from "dayjs";
import React from "react";
import { AxisOptions, Chart } from "react-charts";
import colors from "tailwindcss/colors";
import { ReportRelocations } from "@/gql/graphql";

type ReportingRelocationTableProps = {
  rangeStartDate: string;
  rangeEndDate: string;
  data: ReportRelocations["daily_stats"];
};

type DailyStat = {
  date: string;
  total: number;
};

type Series = {
  label: string;
  data: DailyStat[];
};

export function ReportingRelocationTable({
  rangeStartDate,
  rangeEndDate,
  data,
}: ReportingRelocationTableProps) {
  const localStart = dayjs(rangeStartDate);
  const localEnd = dayjs(rangeEndDate);
  const days = localEnd.diff(localStart, "day") + 1;

  const items = Array.from({ length: days }).map((_, i) => {
    const date = localStart.add(i, "day");

    const foundStat = data.find(
      (item) => item.date === date.format("YYYY-MM-DD"),
    );

    return {
      date: date.format("YYYY-MM-DD"),
      count_bookings_confirmed: foundStat?.successful_bookings_count ?? 0,
      count_bookings_cancelled: foundStat?.cancelled_bookings_count ?? 0,
      count_relocations_ready: foundStat?.counts.active_count ?? 0,
      count_relocations_paused: foundStat?.counts.paused_count ?? 0,
      count_relocations_draft: foundStat?.counts.draft_count ?? 0,
      count_relocations_archived: foundStat?.counts.archived_count ?? 0,
      count_relocations_sound_out: foundStat?.counts.sold_out_count ?? 0,
    };
  });

  const foo: Series[] = [
    {
      label: "Booked",
      data: items.map((item) => {
        return {
          date: item.date,
          total: item.count_bookings_confirmed,
        };
      }),
    },
    {
      label: "Ready",
      data: items.map((item) => {
        return {
          date: item.date,
          total: item.count_relocations_ready,
        };
      }),
    },
    {
      label: "Paused",
      data: items.map((item) => {
        return {
          date: item.date,
          total: item.count_relocations_paused,
        };
      }),
    },
    {
      label: "Draft",
      data: items.map((item) => {
        return {
          date: item.date,
          total: item.count_relocations_draft,
        };
      }),
    },
    {
      label: "Archived",
      data: items.map((item) => {
        return {
          date: item.date,
          total: item.count_relocations_archived,
        };
      }),
    },
  ];

  const primaryAxis = React.useMemo(
    (): AxisOptions<DailyStat> => ({
      getValue: (datum) => datum.date,
    }),
    [],
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<DailyStat>[] => [
      {
        getValue: (datum) => datum.total,
        stacked: true,
        tickCount: 5,
        minTickPaddingForRotation: 1,
      },
    ],
    [],
  );

  return (
    <div className="w-full h-[500px]">
      <h2 className="text-2xl font-bold">Relocations</h2>
      <Chart
        options={{
          data: foo,
          primaryAxis,
          secondaryAxes,
          defaultColors: [
            colors.green["500"],
            colors.yellow["500"],
            colors.blue["500"],
            colors.gray["500"],
            colors.purple["500"],
          ],
        }}
      />
    </div>
  );
}
