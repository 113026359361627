import { graphql } from "@/gql";

export const relocationRatingQuery = graphql(`
  query RelocationRating($input: RelocationRatingInput!) {
    relocationRating(input: $input) {
      ...RelocationRatingFields
    }
  }
`);

export const ratingFragmentFields = graphql(`
  fragment RelocationRatingFields on RelocationRating {
    scores {
      time_allowed_score
      distance_allowed_score
      flexibility_score
      fuel_allowance_score
      lead_time_score
      price_score
    }
    measurement
    hire_unit_type
    recommended_distance_allowed
    recommended_hire_units_allowed
    total_score
    score_out_of
  }
`);
