import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { ImportTable, ImportType } from "@/app/Import/Components/ImportTable";
import { Suspense } from "react";
import { ImportTypeInput } from "@/app/Import/Components/ImportTypeInput";
import { ImportTemplateInput } from "@/app/Import/Components/ImportTemplateInput";
import { Link, useNavigate } from "@tanstack/react-router";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { ImportPasteTemplate } from "@/app/Import/Utils/pasteTemplate";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { importRelocationsMutation } from "@/app/Import/GraphQL/importMutations";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "sonner";
import { supplierRelocationsRoute } from "@/app/Suppliers/Routes/supplierRoutes";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { InProgressBanner } from "@/app/Reports/Screens/ReportInvoiceScreen";

type ImportScreenFormValues = {
  import_template: ImportPasteTemplate;
  supplier_id: string;
  type: ImportType;
};

export function ImportScreen() {
  const { supplier } = useGlobalSupplier();

  return (
    <div className="flex flex-col h-full overflow-y-scroll p-6 space-y-6">
      <InProgressBanner />

      <GenericForm<ImportScreenFormValues>
        className="block h-full"
        onSubmit={async () => {
          return;
        }}
        initialValues={{
          type: "new",
          supplier_id: supplier?.id ?? null!,
          import_template:
            (supplier?.import_template as ImportPasteTemplate) ?? null!,
        }}
      >
        <Form />
      </GenericForm>
    </div>
  );
}

function Form() {
  const { values, setFieldValue, initialValues } =
    useForm<ImportScreenFormValues>();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const { mutateAsync: importRelocation } = useGqlMutation(
    importRelocationsMutation,
  );
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full col-span-full">
      <div className="grid grid-cols-12 gap-6">
        <ImportTypeInput
          name="type"
          label="Import type"
          className="col-span-3"
        />

        {!initialValues.supplier_id ? (
          <SupplierInput
            name="supplier_id"
            label="Supplier"
            onChange={(supplier) => {
              setFieldValue(
                "import_template",
                (supplier?.import_template as ImportPasteTemplate) ?? null,
              );
            }}
            className="col-span-3"
          />
        ) : null}

        {values.supplier_id ? (
          <ImportTemplateInput
            className="col-span-3"
            name={"import_template"}
            label={"Template"}
          />
        ) : null}

        {values.import_template ? (
          <div className="col-span-3 flex items-center">
            <TextButton
              Icon={Link}
              onClick={async () => {
                await mutateAsync({
                  id: values.supplier_id,
                  input: {
                    import_template: values.import_template,
                  },
                });
              }}
            >
              assign
            </TextButton>
          </div>
        ) : null}
      </div>

      {values.type && values.supplier_id && values.import_template ? (
        <div className="flex-grow">
          <Suspense fallback={<Skeleton className="w-full h-[400px]" />}>
            <ImportTable
              key={values.type}
              type={values.type}
              pasteTemplate={values.import_template}
              supplierId={values.supplier_id}
              onUpload={async (data) => {
                await importRelocation({
                  input: {
                    supplierId: values.supplier_id,
                    relocations: data?.map((relocation) => {
                      return {
                        id: relocation.id,
                        line: relocation.line,
                        quantity: relocation.quantity || 1,
                        vehicle_code: relocation.vehicle_code,
                        fuel_amount: relocation.fuel_amount,
                        available_to: relocation.available_to,
                        available_from: relocation.available_from,
                        latest_departure_date: relocation.latest_departure_date,
                        fuel_note: relocation.fuel_note,
                        delivery_office_code: relocation.delivery_office_code,
                        departure_office_code: relocation.departure_office_code,
                        distance_allowed: relocation.distance_allowed,
                        hire_unit_rate: relocation.hire_unit_rate,
                        extra_hire_unit_rate: relocation.extra_hire_unit_rate,
                        hire_units_allowed: relocation.hire_units_allowed,
                        extra_hire_units_allowed:
                          relocation.extra_hire_units_allowed,
                        ferry_note: relocation.ferry_note,
                        toll_charge_amount: relocation.toll_charge_amount,
                        toll_charge_note: relocation.toll_charge_note,
                        inclusion_note: relocation.inclusion_note,
                      };
                    }),
                  },
                });

                toast("Imported successfully");

                await navigate({
                  to: supplierRelocationsRoute.to,
                  params: {
                    id: values.supplier_id,
                  },
                });
              }}
            />
          </Suspense>
        </div>
      ) : null}
    </div>
  );
}
