import {
  FieldLayout,
  GenericField,
  GenericFieldProps,
} from "@/lib/Components/Form/Fields/GenericField";
import {
  Combobox,
  ComboboxProps,
} from "@/lib/Components/Form/Components/Combobox";
import { cn } from "@/lib/utils";
import { useField } from "formik";
import { ReactNode, Suspense, useState } from "react";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  useGqlQuery,
  useNullableSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { Variables } from "graphql-request";
import { keepPreviousData } from "@tanstack/react-query";
import { Skeleton } from "@/components/ui/skeleton";

export type BaseComboboxInputProps<
  TData,
  TVariables extends Variables | undefined,
  TValue,
  RData = any,
  RVariables = any,
> = Partial<ComboboxInputProps<TData, TVariables, TValue, RData, RVariables>> &
  Required<
    Pick<
      ComboboxInputProps<TData, TVariables, TValue, RData, RVariables>,
      "label" | "name"
    >
  >;

export type ComboboxInputProps<
  TData,
  TVariables extends Variables | undefined,
  TValue,
  RData,
  RVariables,
> = {
  document: TypedDocumentNode<TData, TVariables>;
  accessor: (res: TData) => TValue[];
  getQueryVariables: (search: string) => TVariables;
  recordDocument: TypedDocumentNode<RData, RVariables>;
  recordAccessor: (res: RData) => NoInfer<TValue>;
  onChange?: (value: TValue | null) => void;
  getViewNode: (value: TValue | null) => ReactNode;
} & Omit<GenericFieldProps, "viewNode" | "children"> &
  Pick<
    ComboboxProps<TValue>,
    | "getInputNode"
    | "getListItemNode"
    | "getKey"
    | "fixture"
    | "clearable"
    | "disabled"
  >;

export function ComboboxInput<
  TData,
  TVariables extends Variables | undefined,
  TValue,
  RData,
  RVariables,
>(props: ComboboxInputProps<TData, TVariables, TValue, RData, RVariables>) {
  return (
    <Suspense
      fallback={
        <FieldLayout
          name={props.name}
          label={props.label}
          className={props.className}
          optionalLabel={props.optionalLabel}
          tooltip={props.tooltip}
        >
          <Skeleton className="w-full h-[33px] rounded-md" />
        </FieldLayout>
      }
    >
      <ComboboxInputComp {...props} />
    </Suspense>
  );
}

function ComboboxInputComp<
  TData,
  TVariables extends Variables | undefined,
  TValue,
  RData,
  RVariables,
>({
  getInputNode,
  getListItemNode,
  getViewNode,
  getKey,
  document,
  getQueryVariables,

  accessor,
  recordDocument,

  recordAccessor,
  fixture,

  onChange,
  clearable,
  ...props
}: ComboboxInputProps<TData, TVariables, TValue, RData, RVariables>) {
  const [field, meta, { setValue }] = useField(props.name);
  const showError = !!meta.error && meta.touched;

  const [search, setSearch] = useState("");

  const { data: initialData } = useNullableSuspenseGqlQuery(
    recordDocument,
    {
      id: meta.initialValue,
    } as any,
    !!meta.initialValue,
  );

  const { data } = useGqlQuery(document, getQueryVariables(search), {
    placeholderData: keepPreviousData,
  });

  const items = data ? accessor(data) : undefined;
  const initialItem = initialData ? recordAccessor(initialData) : undefined;
  const selected =
    [...(items ?? []), ...(initialItem ? [initialItem] : [])].find(
      (i) => getKey(i) === field.value,
    ) ?? null;

  return (
    <GenericField viewNode={getViewNode(selected)} {...props}>
      <Combobox
        fixture={fixture}
        buttonProps={{
          id: props.name,
          className: cn(
            showError
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "focus:border-indigo-500 focus:ring-indigo-500",
            "border-gray-300 shadow-sm text-sm transition-shadow duration-100 ring-offset-0",
          ),
        }}
        search={search}
        onSearchChange={setSearch}
        items={items}
        selected={selected}
        onChange={async (newItem) => {
          await setValue(getKey(newItem));
          onChange?.(newItem);
        }}
        onClear={async () => {
          await setValue(null);
          onChange?.(null);
        }}
        getKey={getKey}
        getListItemNode={getListItemNode}
        getInputNode={getInputNode}
        clearable={clearable}
      />
    </GenericField>
  );
}
