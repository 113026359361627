import { IconMenu } from "@/lib/Components/Menu/IconMenu";
import {
  ActionGroupButton,
  ActionItemGroup,
  ActionsHook,
} from "@/lib/Components/Actions/ActionGroupButtons";

interface SidebarListActionsProps<T> {
  model: T;
  getModelActionHook: () => ActionsHook<T>;
}

export function ModelIconMenuActions<T>({
  model,
  getModelActionHook,
}: SidebarListActionsProps<T>) {
  const modelActions = getModelActionHook()(model) ?? [];

  const items = modelActions
    //Put our status change actions first
    .flatMap((item) => {
      return (item.items ?? []).filter((actionItem) => {
        return (
          actionItem.actions.filter((action) => !action.isHidden).length > 0
        );
      });
    })
    .filter(Boolean) as ActionItemGroup[];

  if (items.length === 0) return null;

  return <IconMenu actionGroups={items} />;
}

export function TableActions({ actions }: { actions: ActionGroupButton[] }) {
  const items = actions
    .flatMap((item) => {
      return (item.items ?? []).filter((actionItem) => {
        return (
          actionItem.actions.filter((action) => !action.isHidden).length > 0
        );
      });
    })
    .filter(Boolean) as ActionItemGroup[];

  if (items.length === 0) return null;

  return <IconMenu actionGroups={items} />;
}
