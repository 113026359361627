import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Fragment } from "react";
import classNames from "classnames";
import { cn } from "@/lib/utils";

export function Table({ data, columns, ExpandableRow, getRowClassName }: any) {
  const table = useReactTable({
    data,
    columns,
    columnResizeMode: "onChange",
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 80,
    },
  });

  return (
    <table
      className="min-w-full divide-y divide-gray-200"
      {...{
        style: {
          width: table.getCenterTotalSize(),
        },
      }}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="relative bg-gray-50 px-6 py-4 text-left text-sm font-semibold text-gray-900"
                {...{
                  colSpan: header.colSpan,
                  style: {
                    width: header.getSize(),
                  },
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: classNames(
                      header.column.columnDef.enableResizing === false
                        ? ""
                        : "resizer",
                      header.column.getIsResizing() ? "isResizing" : "",
                    ),
                  }}
                />
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <tr
              key={row.id}
              className={cn("bg-white relative", getRowClassName?.(row))}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    key={cell.id}
                    className={classNames(
                      "whitespace-nowrap py-4 text-sm text-gray-900",
                      cell.column.id === "actions"
                        ? "sticky right-0 z-10 bg-inherit"
                        : "px-4",
                    )}
                    {...{
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
            {ExpandableRow && row.getIsExpanded() && (
              <tr>
                {/* 2nd row is a custom 1 cell row */}
                <td colSpan={row.getVisibleCells().length}>
                  <ExpandableRow row={row} />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}
