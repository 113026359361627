import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { Region } from "@/gql/graphql";
import { useTranslation } from "react-i18next";

export function RegionInput(props: Omit<SelectInputProps<any>, "options">) {
  const { t } = useTranslation("city");

  const sections = [
    {
      label: "Oceania",
      regions: [Region.Au, Region.Nz, Region.Pi],
    },
    {
      label: "North America",
      regions: [Region.Ca, Region.Us],
    },
    {
      label: "Europe",
      regions: [Region.Eu],
    },
    {
      label: "Asia",
      regions: [Region.Jp],
    },
    {
      label: "Africa",
      regions: [Region.Sacu],
    },
  ];

  const options = sections.map((section) => ({
    label: section.label,
    options: section.regions.map((region) => ({
      label: t(`region.${region}`),
      value: region,
    })),
  }));

  return (
    <SelectInput
      options={options}
      filter={(value, search) => {
        const label: string = t(`region.${value as Region}`);
        return label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
      }}
      {...props}
    />
  );
}
