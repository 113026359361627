import {
  BookingPaymentRequestStatus,
  QueryBookingPaymentRequestsWhereColumn,
} from "@/gql/graphql";
import { toUnderscoreUpper } from "@/lib/Utils/string";
import { BookingPaymentRequestTable } from "@/app/Bookings/Components/BookingPaymentRequestTable";

export function DashboardPendingPaymentRequests() {
  return (
    <BookingPaymentRequestTable
      id={"dashboard-pending-payment-requests"}
      queryVariables={{
        where: {
          column: QueryBookingPaymentRequestsWhereColumn.Status,
          value: toUnderscoreUpper(BookingPaymentRequestStatus.PaymentPending),
        },
      }}
    />
  );
}
