import { OrganisationTable } from "@/app/Organisations/Components/OrganisationTable";
import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";

export function SettingsOrganisationsScreen() {
  return (
    <SettingCard title="Organisations" description="The companies">
      <OrganisationTable id="settings-organisations" />
    </SettingCard>
  );
}
