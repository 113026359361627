import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import dayjs from "dayjs";
import { DATE_STRING_FORMAT } from "@/app/Import/Utils/pasteTemplate";

export function parseCruisin(grid: AgGridReact, row: any) {
  const fields = [
    "line",
    "price",
    "pickup_loc",
    "pickup_date",
    "pickup_time",
    "dropoff_loc",
    "dropoff_date",
    "dropoff_time",
    "days_allowed",
    "extra_hire_unit_rate",
    "extra_hire_units_allowed",
    "vehicle_code",
    "distance_allowed",
    "inclusions",
    "special_offer",
    "spirit_of_tasmania",
  ] as const;

  const rowObject: Partial<RelocationItemInput> = {
    quantity: 1,
  };

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "line":
        rowObject.line = item;
        break;
      case "price":
        rowObject.hire_unit_rate = parseInt(item.replace("$", ""));
        break;

      case "pickup_loc":
        rowObject.departure_office_code = item;
        break;
      case "pickup_date":
        rowObject.available_from = dayjs(item, "DD.MM.YY").format(
          DATE_STRING_FORMAT,
        );
        break;

      case "dropoff_date":
        rowObject.available_to = dayjs(item, "DD.MM.YY").format(
          DATE_STRING_FORMAT,
        );
        break;

      case "dropoff_loc":
        rowObject.delivery_office_code = item;
        break;

      case "days_allowed":
        rowObject.hire_units_allowed = parseInt(item);
        break;

      case "distance_allowed":
        rowObject.distance_allowed = parseInt(item);
        break;

      case "extra_hire_unit_rate":
        rowObject.extra_hire_unit_rate =
          parseInt(item.replace("$", "")) || null;
        break;

      case "extra_hire_units_allowed":
        rowObject.extra_hire_units_allowed = parseInt(item) || null;
        break;

      case "inclusions":
        rowObject.inclusion_note = item;
        break;

      case "spirit_of_tasmania":
        rowObject.ferry_note = item;
        break;

      case "special_offer":
        //We cover vehicle and 1 person on boat, free LPG and table & chairs + $400 Fuel

        //Extra the fuel amount
        const fuelAmount = parseInt(
          item.match(/\$[0-9]+/g)?.[0].replace("$", ""),
        );

        rowObject.fuel_amount = fuelAmount || 0;
        break;

      case "vehicle_code":
        rowObject.vehicle_code = item;
        break;

      default:
        break;
    }
  });

  return rowObject as RelocationItemInput;
}
