import dayjs, { Dayjs } from "dayjs";

export function formatDateTime(date?: string | null | Date | Dayjs) {
  if (!date) {
    return null;
  }
  return dayjs(date).format("DD MMM YYYY, hh:mma");
}

export function formatDateTimeLocal(date?: string | null | Date | Dayjs) {
  if (!date) {
    return null;
  }

  return dayjs(date, {
    utc: true,
  })
    .local()
    .format("DD MMM YYYY, hh:mma");
}
