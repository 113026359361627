import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { RelocationInput } from "@/app/Relocations/Components/RelocationInput";
import { RelocationStatus } from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateRelocationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { RelocationRecord } from "@/app/Relocations/GraphQL/relocationRecordQuery";

export type RelocationLinkLineDialogProps = {
  relocation: RelocationRecord;
};
export function RelocationLinkLineDialog({
  relocation,
}: RelocationLinkLineDialogProps) {
  const { mutateAsync } = useGqlMutation(updateRelocationMutation);
  const { close } = useDialogState();

  return (
    <GenericDialog title={"Link line"}>
      <GenericForm<{ relocation: { connect: string } }>
        initialValues={{
          relocation: null!,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: values.relocation.connect,
            input: {
              line: {
                upsert: {
                  id: relocation.line.id,
                },
              },
            },
          });

          close();
        }}
      >
        <RelocationInput
          name="relocation.connect"
          label="Relocation"
          getQueryVariables={(search) => ({
            first: 20,
            page: 1,
            search,
            supplier_id: relocation.supplier.id,
            status: [RelocationStatus.Ready],
          })}
        />
        <DialogSubmitButton text={"Link"} />
      </GenericForm>
    </GenericDialog>
  );
}
