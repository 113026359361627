import { graphql } from "@/gql";

export const adminDashboardStatsQuery = graphql(`
  query DashboardStats($start: DateTimeTz!, $end: DateTimeTz!) {
    adminDashboardStats(start: $start, end: $end) {
      bookings {
        date
        count_cancelled
        count_confirmed
        count_pending
        count_vip
        count_completed
      }
      bookings_count
      waitlists_count
      paid_drivers_count
      regions {
        region
        bookings_count
        relocations_count
      }
    }
  }
`);
