import {
  Fuel,
  Transmission,
  VehicleApproximateAge,
  VehicleType,
} from "@/gql/graphql";

const resource = {
  type: {
    [VehicleType.BoxTruck]: "Box truck",
    [VehicleType.CamperVan]: "Campervan",
    [VehicleType.Car]: "Car",
    [VehicleType.FourWheelDrive]: "4WD",
    [VehicleType.FourWheelDriveCamper]: "4WD camper",
    [VehicleType.LuxuryCar]: "Luxury car",
    [VehicleType.MiniVan]: "Mini-van",
    [VehicleType.MotorHome]: "Motorhome",
    [VehicleType.Suv]: "SUV",
    [VehicleType.Ute]: "Ute",
    [VehicleType.Van]: "Van",
  } satisfies {
    [key in VehicleType]: string;
  },
  transmission: {
    [Transmission.Automatic]: "Automatic",
    [Transmission.Manual]: "Manual",
  } satisfies {
    [key in Transmission]: string;
  },
  fuel: {
    [Fuel.Diesel]: "Diesel",
    [Fuel.Electric]: "Electric",
    [Fuel.Gasoline]: "Gasoline",
    [Fuel.Hybrid]: "Hybrid",
  } satisfies {
    [key in Fuel]: string;
  },
  approximate_age: {
    [VehicleApproximateAge.LessThanThreeYears]: "<3 years",
    [VehicleApproximateAge.LessThanFiveYears]: "<5 years",
    [VehicleApproximateAge.LessThanTenYears]: "<10 years",
    [VehicleApproximateAge.LessThanTwentyYears]: "<20 years",
    [VehicleApproximateAge.MoreThanTwentyYears]: ">20 years",
    [VehicleApproximateAge.New]: "New",
  } satisfies {
    [key in VehicleApproximateAge]: string;
  },
};

export default resource;
export type VehicleResource = typeof resource;
