import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { VehicleForm } from "@/app/Vehicles/Forms/VehicleForm";
import { CreateVehicleInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { createVehicleSchema } from "@/app/Vehicles/Validators/createVehicleSchema";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createVehicleMutation } from "@/app/Vehicles/GraphQL/vehicleMutations";
import { VehicleRecord } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGetInitialVehicleValues } from "@/app/Vehicles/Utils/useGetInitialVehicleValues";

export function VehicleCreateDialog({
  initialValues,
}: {
  initialValues?: Partial<CreateVehicleInput>;
}) {
  const { mutateAsync } = useGqlMutation(createVehicleMutation);
  const { close, resolve } = useDialogState<VehicleRecord>();
  const { supplier } = useGlobalSupplier();
  const initialInput = useGetInitialVehicleValues(supplier);

  return (
    <GenericDialog title="Add vehicle">
      <GenericForm<CreateVehicleInput>
        validationSchema={createVehicleSchema}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          resolve?.(res.createVehicle);

          close();
        }}
        initialValues={{
          ...initialInput,
          ...initialValues,
        }}
      >
        <VehicleForm />
        <DialogSubmitButton text={"Add vehicle"} />
      </GenericForm>
    </GenericDialog>
  );
}
