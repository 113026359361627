import { useOrganizationList } from "@clerk/clerk-react";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { NoOrgAlert } from "@/lib/Components/Layout/Layout/Layout";
import { Link, useNavigate } from "@tanstack/react-router";
import { onboardingCreateOrgRoute } from "@/app/Onboarding/Routes/onboardingRoutes";
import { useQueryClient } from "@tanstack/react-query";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getInitials } from "@/lib/Utils/getInitials";
import { useLocalStorage } from "react-use";
import { GlobalSupplierStorageObject } from "@/app/Suppliers/Components/GlobalSupplierProvider";
import { localStorageKeys } from "@/lib/utils";
import { useState } from "react";

export function OnboardingScreen() {
  const { userMemberships, isLoaded } = useOrganizationList({
    userMemberships: {
      initialPage: 1,
      pageSize: 200,
    },
  });

  if (!isLoaded) {
    return <Spinner />;
  }

  const { data: memberships } = userMemberships;

  const showOrganisationList = (memberships?.length ?? 0) > 0;

  return (
    <div className="max-w-sm space-y-10 w-full">
      {showOrganisationList ? (
        <ul className="w-full max-h-80 overflow-y-auto">
          {userMemberships.data?.map((membership) => (
            <OrgListItem membership={membership} key={membership.id} />
          ))}
        </ul>
      ) : (
        <NoOrgAlert />
      )}

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-center text-sm font-medium leading-6">
          <span className="bg-white px-6 text-gray-900">Or</span>
        </div>
      </div>

      <Link
        to={onboardingCreateOrgRoute.to}
        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Create Organisation
      </Link>
    </div>
  );
}

function OrgListItem({ membership }: { membership: any }) {
  const { setActive } = useOrganizationList({
    userMemberships: {
      initialPage: 1,
      pageSize: 200,
    },
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [, , resetGlobalSupplier] =
    useLocalStorage<GlobalSupplierStorageObject>(
      localStorageKeys.GLOBAL_SUPPLIER,
    );
  return (
    <li key={membership.id} className="rounded-md w-full">
      <button
        disabled={isLoading}
        className="bg-white w-full flex space-x-2 items-center px-3 py-2 text-left text-sm font-medium leading-6 text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={async () => {
          setIsLoading(true);
          resetGlobalSupplier();
          await setActive?.({
            organization: membership.organization.id,
          });
          await queryClient.invalidateQueries({
            refetchType: "all",
          });
          await navigate({
            to: "/",
          });
          setIsLoading(false);
        }}
      >
        {!isLoading ? (
          <Avatar>
            <AvatarImage
              src={membership.organization.imageUrl}
              className="object-contain"
            />
            <AvatarFallback>
              {getInitials(membership.organization.name)}
            </AvatarFallback>
          </Avatar>
        ) : (
          <Spinner className="size-10" />
        )}
        <span>{membership.organization.name}</span>
      </button>
    </li>
  );
}
