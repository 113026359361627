import { StripePaymentFieldsFragment } from "@/gql/graphql";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { CalendarDaysIcon, CreditCardIcon } from "@heroicons/react/20/solid";
import { formatDate } from "@/lib/Formatters/formatDate";
import { ReceiptIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { PaymentListItem } from "@/app/Payments/GraphQL/paymentListQuery";
import { Badge } from "@/components/catalyst/badge";
import { paymentStatusColorMap } from "@/app/Payments/Utils/paymentColorMaps";

export function PaymentItem({ payment }: { payment: PaymentListItem }) {
  const { t } = useTranslation("payment");

  let fulfilledBy = <NoFulfillment />;
  if (
    payment.fulfilledBy &&
    payment.fulfilledBy.__typename === "StripePayment"
  ) {
    fulfilledBy = <StripePaymentSection stripePayment={payment.fulfilledBy} />;
  }

  return (
    <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
      <dl className="flex flex-wrap">
        <div className="flex-auto pl-6 pt-6">
          <dt className="text-sm font-semibold leading-6 text-gray-900">
            Amount
          </dt>
          <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
            <p>
              {formatCurrency(payment.amount_paid, payment.currency, {
                showZero: true,
              })}
              <span className="text-xs text-gray-500">
                {payment.amount_refunded
                  ? ` ${formatCurrency(
                      payment.amount_refunded,
                      payment.currency,
                    )} refunded`
                  : null}
              </span>
            </p>
            <p className="text-xs text-gray-500">
              of {formatCurrency(payment.amount, payment.currency)}
            </p>
          </dd>
        </div>

        <div className="self-end px-6 pt-4">
          <dt className="sr-only">Status</dt>
          <dd>
            <Badge color={paymentStatusColorMap[payment.status]}>
              {t(`status.${payment.status}`)}
            </Badge>
          </dd>
        </div>

        <div className="mt-6 w-full">{fulfilledBy}</div>
      </dl>
    </div>
  );
}

function StripePaymentSection({
  stripePayment,
}: {
  stripePayment: StripePaymentFieldsFragment;
}) {
  return (
    <div className="w-full border-t border-gray-900/5 pt-6">
      <p className="px-6 text-gray-500 text-xs">
        <span>via stripe</span>
      </p>
      <div className="flex w-full flex-none gap-x-4 px-6 mt-2">
        <dt className="flex-none">
          <CalendarDaysIcon
            className="h-6 w-6 text-gray-400"
            aria-hidden="true"
          />
        </dt>
        <dd className="text-sm leading-6 text-gray-500">
          <time dateTime="2023-01-31">
            {formatDate(stripePayment.created_at)}
          </time>
        </dd>
      </div>
      <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
        <dt className="flex-none">
          <span className="sr-only">Status</span>
          <CreditCardIcon
            className="h-6 w-6 text-gray-400"
            aria-hidden="true"
          />
        </dt>
        <dd className="text-sm leading-6 text-gray-500">
          Paid with {stripePayment.brand}
        </dd>
      </div>
      <div className="mt-4 flex w-full flex-none gap-x-4 px-6 pb-6">
        <dt className="flex-none">
          <ReceiptIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        </dt>
        <dd className="text-sm leading-6 text-gray-500 overflow-hidden">
          <a
            href={`https://dashboard.stripe.com/payments/${stripePayment.payment_intent_id}`}
            target="_blank"
            rel="noreferrer"
            className="flex items-center space-x-2 underline-offset-1 hover:underline"
          >
            <span className="truncate w-full">
              {stripePayment.payment_intent_id}
            </span>
          </a>
        </dd>
      </div>
    </div>
  );
}

function NoFulfillment() {
  return <div>empty state...</div>;
}
