import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { VehicleCreateDialog } from "@/app/Vehicles/Components/VehicleCreateDialog";
import { SupplierPurchasableExtrasTable } from "@/app/Suppliers/Components/SupplierPurchasableExtrasTable";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { VehicleTable } from "@/app/Vehicles/Components/VehicleTable";
import { PlusIcon } from "@heroicons/react/24/outline";

export function SupplierVehicleScreen() {
  const record = useSupplierRecord();
  const { open } = useDialog(VehicleCreateDialog);

  return (
    <div className="space-y-8">
      <VehicleTable
        id="supplier-vehicles"
        rightButtons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({
                initialValues: {
                  supplier: {
                    connect: record.id,
                  },
                },
              });
            }}
          >
            Add vehicle
          </Button>
        }
        queryVariables={{
          supplier_id: record.id,
        }}
      />

      <SupplierPurchasableExtrasTable supplier={record} />
    </div>
  );
}
