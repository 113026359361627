import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { TaskList } from "@/app/Tasks/Components/TaskList";
import {
  BookingChangeRequestStatus,
  ModelType,
  QueryBookingChangeRequestsWhereColumn,
  QueryTasksWhereColumn,
  SqlOperator,
  TaskStatus,
} from "@/gql/graphql";
import { ChangeRequestTable } from "@/app/Bookings/Components/ChangeRequestTable";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";

export function BookingTaskScreen() {
  const record = useBookingRecord();
  const { isAdmin } = useTenant();

  return (
    <>
      <RecordScreenCard>
        <TaskList
          id={"booking-tasks"}
          queryVariables={{
            status: [TaskStatus.Pending, TaskStatus.Overdue],
            where: {
              AND: [
                {
                  column: QueryTasksWhereColumn.RelatedToId,
                  value: record.id,
                },
                {
                  column: QueryTasksWhereColumn.RelatedToType,
                  value: ModelType.Booking,
                },
              ],
            },
          }}
        />
      </RecordScreenCard>

      {isAdmin ? (
        <RecordScreenCard>
          <ChangeRequestTable
            queryVariables={{
              where: {
                AND: [
                  {
                    column: QueryBookingChangeRequestsWhereColumn.BookingId,
                    value: record.id,
                  },
                  {
                    column: QueryBookingChangeRequestsWhereColumn.Status,
                    operator: SqlOperator.In,
                    value: [
                      BookingChangeRequestStatus.Approved,
                      BookingChangeRequestStatus.Declined,
                      BookingChangeRequestStatus.Pending,
                    ],
                  },
                ],
              },
            }}
            id={"booking-change-requests"}
          />
        </RecordScreenCard>
      ) : null}
    </>
  );
}
