import { TripTable } from "@/app/Cities/Trips/Components/TripTable";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useCityRecord } from "@/app/Cities/Cities/Hooks/useCityRecord";

export function CityTripScreen() {
  const city = useCityRecord();

  return (
    <RecordScreenCard>
      <TripTable
        id={"city-trips"}
        queryVariables={{
          cityIds: [city.id],
        }}
      />
    </RecordScreenCard>
  );
}
