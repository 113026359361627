import { QuerySuppliersOrderByColumn, SortOrder } from "@/gql/graphql";
import { SupplierListSearchParams } from "@/app/Suppliers/Routes/supplierRoutes";
import { SupplierListQueryVariables } from "@/app/Suppliers/GraphQL/supplierListQuery";

export function getSupplierListQueryVariables(
  searchParams: SupplierListSearchParams,
): SupplierListQueryVariables {
  const baseVariables: SupplierListQueryVariables = {
    first: 20,
    page: 1,
    search: searchParams.supplierSearch,
  };

  if (searchParams.supplierSearch) {
    return baseVariables;
  }

  return {
    ...baseVariables,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QuerySuppliersOrderByColumn.Name,
      },
    ],
  };
}
