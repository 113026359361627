import { createRoute, Outlet } from "@tanstack/react-router";
import { loginLayoutRoute } from "@/routes";
import { OnboardingCreateOrgScreen } from "@/app/Onboarding/Screens/OnboardingCreateOrgScreen";
import { OnboardingPricingScreen } from "@/app/Onboarding/Screens/OnboardingPricingScreen";
import { OnboardingScreen } from "@/app/Onboarding/Screens/OnboardingScreen";

export const onboardingRoute = createRoute({
  getParentRoute: () => loginLayoutRoute,
  path: "/onboarding",
  component: Outlet,
});

export const onboardingCreateOrJoinRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "/",
  component: OnboardingScreen,
});

export const onboardingCreateOrgRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "/create-org",
  component: OnboardingCreateOrgScreen,
});

export const onboardingPricingRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "/pricing",
  component: OnboardingPricingScreen,
});
