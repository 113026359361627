import { ReactNode, Suspense } from "react";
import {
  OrganizationSwitcher,
  SignOutButton,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { DialogProvider } from "@/lib/Components/Dialog/Providers/DialogProvider";
import { GlobalSupplierProvider } from "@/app/Suppliers/Components/GlobalSupplierProvider";
import { Sidebar } from "@/lib/Components/Layout/Sidebar/Sidebar";
import * as Portal from "@radix-ui/react-portal";
import { Toaster } from "@/components/ui/sonner";
import { OutdatedVersionWarning } from "@/app/Meta/Components/OutdatedVersionWarning";
import { AccountVerificationWarning } from "@/app/Meta/Components/AccountVerificationWarning";
import { OverdueInvoicesWarning } from "@/app/Meta/Components/OverdueInvoicesWarning";
import { GenericErrorBoundary } from "@/lib/Components/Layout/ErrorBoundary/ErrorBoundary";
import { Outlet } from "@tanstack/react-router";

export function AppLayout() {
  return (
    <Suspense>
      <OrganizationGuard>
        <GlobalSupplierProvider>
          <DialogProvider>
            <Sidebar>
              <OutdatedVersionWarning />
              <AccountVerificationWarning />
              <OverdueInvoicesWarning />
              <GenericErrorBoundary>
                <Outlet />
              </GenericErrorBoundary>
              <Portal.Root>
                <Toaster />
              </Portal.Root>
            </Sidebar>
          </DialogProvider>
        </GlobalSupplierProvider>
      </OrganizationGuard>
    </Suspense>
  );
}

function OrganizationGuard({ children }: { children: ReactNode }) {
  const { isLoaded, organization } = useOrganization();
  const { userMemberships, isLoaded: isListLoaded } = useOrganizationList({
    userMemberships: {
      initialPage: 1,
    },
  });

  if (!isLoaded || !isListLoaded) {
    return null;
  }

  if (!organization) {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Select an organization
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Welcome to the Imoova B2B Portal. Please select an organization to
            continue.
          </p>

          <div className="my-12 text-left">
            {userMemberships.data?.length ? (
              <div className="flex justify-center">
                <OrganizationSwitcher hidePersonal={true} defaultOpen={true} />
              </div>
            ) : (
              <NoOrgAlert />
            )}
          </div>
          <SignOutButton />
        </div>
      </div>
    );
  }
  return children;
}

export function NoOrgAlert() {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Attention needed
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              You are not a member of any organization. Please contact support
              your account administrator to get access.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
