import { SidebarList } from "@/lib/Components/SideBarList/SideBarList";
import { RelocationStatus } from "@/gql/graphql";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CarIcon, FilterIcon } from "lucide-react";
import {
  BuildingOffice2Icon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { formatDate } from "@/lib/Formatters/formatDate";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useState } from "react";
import { relocationsRoute } from "@/app/Relocations/Routes/relocationRoute";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  RelocationListItem,
  relocationListQuery,
} from "@/app/Relocations/GraphQL/relocationListQuery";
import { getRelocationListQueryVariables } from "@/app/Relocations/Utils/getRelocationListQueryVariables";
import { Badge } from "@/components/catalyst/badge";
import { relocationStatusColorMap } from "@/app/Relocations/Utils/relocationStatusColorMap";
import { useTranslation } from "react-i18next";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function RelocationListScreen() {
  const searchParams = useSearch({ from: relocationsRoute.id });
  const [search, setSearch] = useState(searchParams.relocationSearch ?? "");
  const queryVariables = getRelocationListQueryVariables({
    ...searchParams,
    relocationSearch: search,
  });
  const { data, fetchNextPage, isFetchingNextPage, isFetching } =
    useInfiniteGqlQuery(relocationListQuery, queryVariables, {
      getNextPageParam: (prevPage) => {
        return prevPage.relocations.paginatorInfo.hasMorePages
          ? {
              page: prevPage.relocations.paginatorInfo.currentPage + 1,
            }
          : undefined;
      },
      initialPageParam: {
        page: 1,
      },
    });

  const items = data?.pages.flatMap((p) => p.relocations.data);
  const totalCount = data?.pages.at(0)?.relocations.paginatorInfo.total ?? 0;

  return (
    <SidebarList
      createRoute={"/relocations/create"}
      listRoute={"/relocations"}
      search={search}
      items={items}
      itemNode={(item) => <RelocationItem model={item} />}
      isFetchingNextPage={isFetchingNextPage}
      isFetching={isFetching}
      onSearchChange={(newStr) => {
        setSearch(newStr);
      }}
      onClearFilters={() => {
        setSearch("");
      }}
      totalCount={totalCount}
      linkProps={(item) => ({
        to: "/relocations/$relocationId",
        params: {
          relocationId: item.id,
        },
      })}
      fetchNextPage={fetchNextPage}
    />
  );
}

function RelocationItem({ model }: { model: RelocationListItem }) {
  const { t } = useTranslation("relocation");
  const { hasSupplier } = useGlobalSupplier();

  return (
    <div className="flex space-x-2">
      <div className="space-y-1 flex-grow">
        <div className="truncate text-xs font-semibold">
          <p>{model.departureOffice.name}</p>
          <p>{model.deliveryOffice.name}</p>
        </div>

        <p className="flex items-center truncate text-xs text-gray-500">
          <CalendarDaysIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {`${formatDate(model.available_from_date)} - ${formatDate(
            model.available_to_date,
          )}`}
        </p>

        {!hasSupplier ? (
          <p className="flex items-center truncate text-xs text-gray-500">
            <BuildingOffice2Icon className="mr-2 inline h-4 w-4 flex-shrink-0" />
            {model.supplier.name}
          </p>
        ) : null}

        <p className="flex items-center truncate text-xs text-gray-500">
          <CarIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {model.vehicle.name}
        </p>

        <p>
          <time dateTime={model.created_at} className="text-xs text-gray-500">
            {formatDateTimeSinceNow(model.created_at)}
          </time>
        </p>
      </div>

      <div className="">
        <Badge
          color={relocationStatusColorMap[model.status]}
          className="capitalize"
        >
          {t(`status.${model.status}`)}
        </Badge>
      </div>
    </div>
  );
}

export function RelocationStatusFilter() {
  const { relocationStatus } = useSearch({ from: relocationsRoute.id });
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <FilterIcon className="h-4 w-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Status</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {Object.entries(RelocationStatus).map(([key, value]) => {
          return (
            <DropdownMenuCheckboxItem
              key={key}
              onSelect={(e) => {
                e.preventDefault();
              }}
              checked={relocationStatus?.includes(value)}
              onCheckedChange={async (val) => {
                const newStatuses = relocationStatus;
                if (val) {
                  newStatuses?.push(value);
                } else {
                  const index = newStatuses?.indexOf(value);
                  if (index) {
                    newStatuses?.splice(index, 1);
                  }
                }

                await navigate({
                  to: relocationsRoute.to,
                  search: {
                    relocationStatus: newStatuses?.sort(),
                  },
                });
              }}
            >
              {value}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
