import { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { TripRenderCell } from "@/app/Import/Components/TripRenderCell";
import { VehicleRenderCell } from "@/app/Import/Components/VehicleRenderCell";
import {
  QuerySupplierOfficesOrderByColumn,
  QueryVehiclesOrderByColumn,
  RelocationItemInput,
  RelocationStatus,
  SortOrder,
} from "@/gql/graphql";
import {
  constructObject,
  ImportPasteTemplate,
} from "@/app/Import/Utils/pasteTemplate";
import { groupBy } from "@/lib/Utils/groupBy";
import { FutureDateCell } from "@/app/Import/Components/FutureDateCell";
import { Button } from "@/lib/Components/Button/Button";
import {
  CloudArrowUpIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { UniqueValueCell } from "@/app/Import/Components/UniqueValueCell";
import { CurrencyRenderCell } from "@/app/Import/Components/CurrencyRenderCell";
import { FileSpreadsheetIcon } from "lucide-react";
import dayjs from "dayjs";
import { DistanceRenderCell } from "@/app/Import/Components/DistanceRenderCell";
import {
  RelocationListItem,
  relocationListQuery,
} from "@/app/Relocations/GraphQL/relocationListQuery";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { vehicleListQuery } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { supplierOfficeListQuery } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

function mapRelocationsToImportItems(
  relocations: RelocationListItem[],
): RelocationItemInput[] {
  return relocations.map((relocation) => {
    return {
      id: relocation.id,
      line: relocation.line.reference ?? null,
      available_from: relocation.available_from_date!,
      available_to: relocation.available_to_date!,
      departure_office_code: relocation.departureOffice.code!,
      delivery_office_code: relocation.deliveryOffice.code!,
      vehicle_code: relocation.vehicle.code!,
      hire_unit_rate: relocation.hire_unit_rate / 100,
      hire_units_allowed: relocation.hire_units_allowed,
      extra_hire_units_allowed: relocation.extra_hire_units_allowed,
      distance_allowed: relocation.distance_allowed,
      extra_hire_unit_rate:
        (relocation.extra_hire_unit_supplier_net_rate ?? 0) / 100,
      quantity: relocation.count,
      fuel_amount: null,
      fuel_note: null,
    };
  });
}
export type ImportType = "new" | "append";
type ImportTableProps = {
  supplierId: string;
  type: ImportType;
  pasteTemplate: ImportPasteTemplate;
  onUpload: (items: RelocationItemInput[]) => void;
};
export function ImportTable({
  supplierId,
  type,
  pasteTemplate,
  onUpload,
}: ImportTableProps) {
  const gridRef = useRef<AgGridReact<RelocationItemInput>>();

  const { data: supplierRes } = useSuspenseGqlQuery(supplierRecordQuery, {
    id: supplierId,
  });
  const supplier = supplierRes.supplier;

  const { data: relocationRes } = useSuspenseGqlQuery(relocationListQuery, {
    page: 1,
    first: 200,
    supplier_id: supplierId,
    status: [RelocationStatus.Ready],
  });

  const { data: vehicleRes } = useSuspenseGqlQuery(vehicleListQuery, {
    page: 1,
    first: 200,
    supplier_id: supplierId,
    archived: false,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QueryVehiclesOrderByColumn.Code,
      },
    ],
  });
  const vehicles = vehicleRes.vehicles.data;

  const { data: officesRes } = useSuspenseGqlQuery(supplierOfficeListQuery, {
    page: 1,
    first: 200,
    supplier_id: supplierId,
    archived: false,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QuerySupplierOfficesOrderByColumn.Code,
      },
    ],
  });
  const offices = officesRes.supplierOffices.data;

  const [rowData] = useState<RelocationItemInput[]>(
    type === "append"
      ? mapRelocationsToImportItems(relocationRes.relocations.data)
      : [{} as any],
  );
  const [selectedRows, setSelectedRows] = useState<RelocationItemInput[]>([]);

  const vehicleCodes = vehicles.map((vehicle) => vehicle.code);
  const officeCodes = offices.map((office) => office.code);

  // Each Column Definition results in one Column.
  const [columnDefs] = useState<ColDef<RelocationItemInput>[]>([
    {
      field: "id",
      headerName: "ID",
      editable: false,
      resizable: false,
    },
    { field: "line", cellRenderer: UniqueValueCell },
    { field: "quantity", cellDataType: "number", resizable: false },
    {
      field: "vehicle_code",
      cellEditor: "agRichSelectCellEditor",
      cellRenderer: VehicleRenderCell,
      headerName: "Vehicle",
      cellRendererParams: {
        supplier: supplier,
      },
      cellEditorParams: {
        values: vehicleCodes,
        cellHeight: 20,
      },
    },
    {
      field: "departure_office_code",
      headerName: "From",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: officeCodes,
        cellHeight: 20,
      },
      cellRenderer: TripRenderCell,
      cellRendererParams: {
        supplier,
        officeType: "departure",
      },
    },
    {
      field: "delivery_office_code",
      headerName: "To",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: officeCodes,
        cellHeight: 20,
      },
      cellRenderer: TripRenderCell,
      cellRendererParams: {
        supplier: supplier,
        officeType: "delivery",
      },
    },
    {
      field: "available_from",
      cellDataType: "dateString",
      cellRenderer: FutureDateCell,
      headerName: "Available from",
    },
    {
      field: "available_to",
      cellDataType: "dateString",
      headerName: "Available to",
      cellRenderer: FutureDateCell,
    },
    {
      field: "latest_departure_date",
      headerName: "Latest departure",
      cellDataType: "dateString",
      cellRenderer: FutureDateCell,
    },
    {
      field: "hire_units_allowed",
      cellDataType: "number",
      headerName: "Relocation days",
    },
    {
      field: "hire_unit_rate",
      cellDataType: "number",
      headerName: "Relocation rate",
      cellRenderer: CurrencyRenderCell,
      cellRendererParams: {
        currency: supplier.currency,
      },
    },
    {
      field: "extra_hire_units_allowed",
      cellDataType: "number",
      headerName: "Extra days",
    },
    {
      field: "extra_hire_unit_rate",
      cellDataType: "number",
      headerName: "Extra day rate",
      cellRenderer: CurrencyRenderCell,
      cellRendererParams: {
        currency: supplier.currency,
      },
    },
    {
      field: "distance_allowed",
      cellDataType: "number",
      headerName: "Distance allowed",
      cellRenderer: DistanceRenderCell,
      cellRendererParams: {
        measurement: supplier.measurement,
      },
    },
    {
      field: "fuel_amount",
      cellDataType: "number",
      headerName: "Fuel",
      cellRenderer: CurrencyRenderCell,
      cellRendererParams: {
        currency: supplier.currency,
      },
    },
    { field: "fuel_note", headerName: "Fuel note" },
    { field: "ferry_note", headerName: "Ferry note" },
    { field: "inclusion_note", headerName: "Inclusions note" },
    {
      field: "toll_charge_amount",
      cellDataType: "number",
      headerName: "Preparation charge",
      cellRenderer: CurrencyRenderCell,
      cellRendererParams: {
        currency: supplier.currency,
      },
    },
    {
      field: "toll_charge_note",
      headerName: "Preparation charge note",
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef: ColDef<any> = useMemo(
    () => ({
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
    }),
    [],
  );

  const processDataFromClipboard = useCallback((params: any) => {
    if (!gridRef.current) return;

    const data = [...params.data];
    const emptyLastRow =
      data[data.length - 1][0] === "" && data[data.length - 1].length === 1;
    if (emptyLastRow) {
      data.splice(data.length - 1, 1);
    }

    const rowsToAdd = data
      .map((row) => constructObject(gridRef.current!, row, pasteTemplate))
      .filter(Boolean);

    let items: any[] = [];

    // If Cruisin template, just add the rows, don't group them
    if (pasteTemplate === ImportPasteTemplate.Cruisin) {
      items = rowsToAdd;
    } else {
      //Group similar rows
      const groupedRows = groupBy(rowsToAdd, (row) => {
        return [
          row.vehicle_code,
          row.available_from,
          row.available_to,
          row.departure_office_code,
          row.delivery_office_code,
          row.fuel_amount,
          row.fuel_note,
          row.latest_departure_date,
        ].join(",");
      });

      Object.entries(groupedRows).forEach(([, value]) => {
        const sum = value.reduce((acc, curr) => {
          return acc + curr.quantity;
        }, 0);

        const line = value
          .map((item) => item.line)
          .filter(Boolean)
          .join(",");

        items.push({
          ...value[0],
          quantity: sum,
          line,
        });
      });
    }

    gridRef.current?.api?.applyTransaction({ add: items });

    // Auto resize columns after data is loaded
    setTimeout(() => {
      gridRef.current?.api.autoSizeAllColumns();
    });
  }, []);

  const getSelectedRows = () => {
    const range = gridRef.current?.api.getCellRanges();
    const rowStart = range?.[0].startRow!.rowIndex;
    const rowEnd = range?.[0].endRow!.rowIndex;

    if (rowStart === undefined || rowStart === null) return;
    if (rowEnd === undefined || rowEnd === null) return;

    const start = rowStart < rowEnd ? rowStart : rowEnd;
    const end = rowStart < rowEnd ? rowEnd : rowStart;

    return Array.from({ length: end - start + 1 }, (_, i) => i + start!)
      .map((rowIndex) => {
        return gridRef.current?.api.getDisplayedRowAtIndex(rowIndex)?.data;
      })
      .filter(Boolean);
  };

  const deleteSelected = useCallback(() => {
    const rows = getSelectedRows();

    gridRef.current?.api.applyTransaction({
      remove: rows,
    });
  }, [gridRef]);

  const getContextMenuItems = useCallback(() => {
    return [
      "copy",
      "paste",
      "separator",
      "csvExport",
      "excelExport",
      "separator",
      {
        name: "Delete row",
        icon: '<span class="ag-icon ag-icon-not-allowed" unselectable="on" role="presentation"></span>',
        action: deleteSelected,
      },
    ];
  }, []);

  return (
    <div className="h-full w-full flex flex-col gap-y-2">
      <div className="flex justify-between">
        <div className="flex space-x-2">
          <Button
            intent="primary"
            type="outline"
            LeadingIcon={PlusIcon}
            onClick={() => {
              gridRef.current?.api.applyTransaction({
                add: [{} as any],
              });
            }}
          >
            Add a row
          </Button>
          {selectedRows.length > 0 ? (
            <Button
              intent="danger"
              LeadingIcon={TrashIcon}
              onClick={deleteSelected}
            >
              Delete rows ({selectedRows.length})
            </Button>
          ) : null}
        </div>

        <div className="flex space-x-2">
          <Button
            LeadingIcon={FileSpreadsheetIcon}
            type="outline"
            onClick={() => {
              gridRef.current?.api.exportDataAsExcel({
                author: "Imoova",
                fileName: `Relocation Export ${dayjs().format(
                  "YYYY-MM-DD HH:mm:ss",
                )}`,
              });
            }}
          >
            Export
          </Button>
          <Button
            LeadingIcon={CloudArrowUpIcon}
            intent="primary"
            onClick={() => {
              const data: RelocationItemInput[] = [];
              gridRef.current?.api.forEachNode((node) => {
                if (node.data && Object.keys(node.data!).length > 0)
                  data.push(node.data!);
              });
              return onUpload(data);
            }}
          >
            Upload
          </Button>
        </div>
      </div>
      <div className="ag-theme-quartz flex-grow">
        <AgGridReact<RelocationItemInput>
          className="flex-grow"
          ref={gridRef as any}
          rowData={rowData}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple"
          enableRangeSelection={true}
          getContextMenuItems={getContextMenuItems}
          processDataFromClipboard={processDataFromClipboard as any}
          suppressClipboardApi={true}
          onGridReady={(event) => {
            event.api.autoSizeAllColumns();
          }}
          onRangeSelectionChanged={() => {
            setSelectedRows(getSelectedRows() ?? []);
          }}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalRowCountComponent",
              },
            ],
          }}
        />
      </div>
    </div>
  );
}
