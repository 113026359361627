import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type SupplierRecord = ResultOf<typeof supplierRecordQuery>["supplier"];
export const supplierRecordQuery = graphql(`
  query SupplierRecordQuery($id: ID!) {
    supplier(id: $id) {
      id
      email
      phone

      collect_address_on_bookings
      import_template
      email_on_line_sold
      relocation_lines_valid_until
      line_sold_email
      line_sold_timezone
      billing_currency
      contact_notes

      name
      region
      currency
      measurement
      hire_unit_type

      booking_fee_amount

      debit_card_accepted

      is_tax_included
      tax_type
      billing_currency
      confirm_delivery_before_billing

      has_age_surcharge
      age_surcharge_amount
      maximum_no_surcharge_age
      minimum_no_surcharge_age
      minimum_age
      maximum_age

      default_extra_hire_units_allowed
      extra_distance_allowed_per_extra_hire_unit
      excess_distance_rate
      standard_inclusions

      holding_deposit_amount
      holding_deposit_refunded_at
      holding_deposit_refunded_by
      is_holding_deposit_required
      charge_per_move_amount

      is_tax_included

      terms_and_conditions
      terms_and_conditions_type

      departure_hours
      delivery_hours
      closed_dates

      logistics_hours
      hours_to_confirm
      timezone

      defaultInsuranceOption {
        id
        name
        is_default
        bond_amount
        standard_liability_amount
        liability_reduction_options
        created_at
        updated_at
      }

      termsAndConditionsPdf {
        ...MediaFields
      }

      created_at
      updated_at

      ...SupplierIconFields

      billingEntity {
        ...ContactRecordFields
        stripeCustomer {
          paymentMethods {
            id
            type
            is_primary
            card_brand
            card_last_four
            card_exp_month
            card_exp_year
          }
        }
      }

      managedBy {
        id
        contact {
          ...ContactRecordFields
        }
      }

      notificationSettings {
        ...NotificationSettingsFields
      }
    }
  }
`);

export const supplierNotificationSettingsFragment = graphql(`
  fragment NotificationSettingsFields on SupplierNotificationSetting {
    id
    type
    email
    event
    updated_at
    created_at
    __typename
  }
`);

export const supplierFieldsFragment = graphql(`
  fragment SupplierFields on Supplier {
    id
    email
    phone

    collect_address_on_bookings

    email_on_line_sold
    relocation_lines_valid_until
    line_sold_email
    line_sold_timezone
    billing_currency

    name
    region
    currency
    measurement
    hire_unit_type

    booking_fee_amount

    debit_card_accepted

    is_tax_included
    tax_type
    billing_currency

    has_age_surcharge
    age_surcharge_amount
    maximum_no_surcharge_age
    minimum_no_surcharge_age
    minimum_age
    maximum_age

    default_extra_hire_units_allowed
    extra_distance_allowed_per_extra_hire_unit
    excess_distance_rate
    standard_inclusions

    holding_deposit_amount
    holding_deposit_refunded_at
    holding_deposit_refunded_by
    is_holding_deposit_required
    charge_per_move_amount

    is_tax_included

    terms_and_conditions

    departure_hours
    delivery_hours
    closed_dates

    logistics_hours
    hours_to_confirm
    timezone

    defaultInsuranceOption {
      id
      name
      is_default
      bond_amount
      standard_liability_amount
      liability_reduction_options
      created_at
      updated_at
    }

    created_at
    updated_at

    ...SupplierIconFields

    billingEntity {
      ...ContactRecordFields
    }

    managedBy {
      ...OrganisationFields
    }

    notificationSettings {
      ...NotificationSettingsFields
    }
  }
`);
