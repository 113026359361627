import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { CheckIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingChangeRequestApproveDialog } from "@/app/Bookings/Components/BookingChangeRequestApproveDialog";
import { BookingChangeRequestStatus, ModelType } from "@/gql/graphql";
import { BookingChangeRequestListItem } from "@/app/Bookings/GraphQL/bookingChangeRequestListQuery";
import { ActivityIcon } from "lucide-react";
import { ActivityLogDialog } from "@/app/ActivityLogs/Components/ActivityLogDialog";

export function useBookingChangeRequestActions(): ActionsHook<BookingChangeRequestListItem> {
  const { open } = useDialog(BookingChangeRequestApproveDialog);
  const { open: openActivityLog } = useDialog(ActivityLogDialog);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Activity",
                Icon: ActivityIcon,
                handleAction: () => {
                  openActivityLog({
                    modelId: model.booking.id,
                    modelType: ModelType.Booking,
                  });
                },
                intent: "primary",
              },
            ],
          },
          {
            actions: [
              {
                label: "Resolve",
                Icon: CheckIcon,
                intent: "success",
                isHidden: model.status !== BookingChangeRequestStatus.Pending,
                handleAction: () => {
                  open({
                    changeRequest: model,
                    type: "approve",
                  });
                },
              },
              {
                label: "Reject",
                isHidden: model.status !== BookingChangeRequestStatus.Pending,
                Icon: XMarkIcon,
                intent: "danger",
                handleAction: () => {
                  open({
                    changeRequest: model,
                    type: "reject",
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
