import { ActivityLogEvent } from "@/gql/graphql";
import { ReactNode } from "react";
import { DefaultActivityLogItem } from "@/app/ActivityLogs/Components/ActivityLogEventItems/DefaultActivityLogItem";
import { NotationActivityLogItem } from "@/app/ActivityLogs/Components/ActivityLogEventItems/NotationActivityLogItem";
import {
  CheckCircleIcon,
  EnvelopeIcon,
  MegaphoneIcon,
  PhoneArrowDownLeftIcon,
} from "@heroicons/react/24/outline";
import {
  CircleEllipsisIcon,
  CrownIcon,
  DollarSignIcon,
  FileCheckIcon,
  FileXIcon,
  LockIcon,
  MailIcon,
  UnlockIcon,
  XOctagonIcon,
} from "lucide-react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ActivityLogListItem } from "@/app/ActivityLogs/GraphQL/activityLogListQuery";

const activityLogEventNodes: {
  [key in ActivityLogEvent]: (log: ActivityLogListItem) => ReactNode;
} = {
  [ActivityLogEvent.BookingAssignedLine]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={LockIcon}
      iconColor="text-green-400"
    />
  ),
  [ActivityLogEvent.BookingCancelled]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={XOctagonIcon}
      iconColor="text-red-400"
    />
  ),
  [ActivityLogEvent.BookingChangeRequestApproved]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={FileCheckIcon}
      iconColor="text-green-400"
    />
  ),
  [ActivityLogEvent.BookingChangeRequestPlaced]: (log) => (
    <NotationActivityLogItem log={log} title="requested a change" />
  ),
  [ActivityLogEvent.BookingChangeRequestRejected]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={FileXIcon}
      iconColor="text-red-400"
    />
  ),
  [ActivityLogEvent.BookingConfirmed]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={CheckIcon}
      iconColor="text-green-400"
    />
  ),
  [ActivityLogEvent.BookingPaymentRequestCancelled]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={DollarSignIcon}
      iconColor="text-red-400"
    />
  ),
  [ActivityLogEvent.BookingPaymentRequestCaptured]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={DollarSignIcon}
      iconColor="text-green-400"
    />
  ),
  [ActivityLogEvent.BookingPaymentRequestCreated]: (log) => (
    <NotationActivityLogItem log={log} title="requested payment" />
  ),
  [ActivityLogEvent.BookingPaymentRequestPending]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={DollarSignIcon}
      iconColor="text-yellow-400"
    />
  ),
  [ActivityLogEvent.BookingPending]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={CircleEllipsisIcon}
      iconColor="text-yellow-400"
    />
  ),
  [ActivityLogEvent.BookingReleasedLine]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={UnlockIcon}
      iconColor="text-red-400"
    />
  ),
  [ActivityLogEvent.BookingVip]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={CrownIcon}
      iconColor="text-purple-400"
    />
  ),
  [ActivityLogEvent.Created]: (log) => <DefaultActivityLogItem log={log} />,
  [ActivityLogEvent.Deleted]: (log) => <DefaultActivityLogItem log={log} />,
  [ActivityLogEvent.Email]: (log) => (
    <DefaultActivityLogItem log={log} Icon={EnvelopeIcon} />
  ),
  [ActivityLogEvent.Note]: (log) => (
    <NotationActivityLogItem log={log} title="commented" />
  ),
  [ActivityLogEvent.PhoneCall]: (log) => (
    <DefaultActivityLogItem log={log} Icon={PhoneArrowDownLeftIcon} />
  ),
  [ActivityLogEvent.Updated]: (log) => <DefaultActivityLogItem log={log} />,
  [ActivityLogEvent.BookingHoldingDepositReleased]: (log) => (
    <DefaultActivityLogItem log={log} />
  ),
  [ActivityLogEvent.BookingHoldingDepositReleaseFailed]: (log) => (
    <DefaultActivityLogItem log={log} />
  ),
  [ActivityLogEvent.BookingCompleted]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={CheckCircleIcon}
      iconColor="text-green-400"
    />
  ),
  [ActivityLogEvent.IntegrationJucyBookingPushed]: (log) => {
    return (
      <DefaultActivityLogItem
        log={log}
        Icon={CheckCircleIcon}
        iconColor="text-green-400"
      />
    );
  },
  [ActivityLogEvent.IntegrationJucyBookingPushedFailed]: (log) => {
    return (
      <DefaultActivityLogItem
        log={log}
        Icon={CheckCircleIcon}
        iconColor="text-green-400"
      />
    );
  },
  [ActivityLogEvent.MarketingEventTriggered]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={MegaphoneIcon}
      iconColor="text-fuchsia-400"
    />
  ),
  [ActivityLogEvent.WaitlistMatchesSent]: (log) => (
    <DefaultActivityLogItem
      log={log}
      Icon={MailIcon}
      iconColor="text-gray-400"
    />
  ),
};

export function ModelEventItem({ log }: { log: ActivityLogListItem }) {
  const fn = activityLogEventNodes[log.event];

  if (!fn) {
    throw new Error("No event handler found for event: " + log.event);
  }

  return fn(log);
}
