import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import dayjs from "dayjs";
import { DATE_STRING_FORMAT } from "@/app/Import/Utils/pasteTemplate";

export function parseIndieCampersEu(
  grid: AgGridReact,
  row: any,
): RelocationItemInput | null {
  const fields = [
    "line",
    "vehicle_code",
    "origin",
    "destination",
    "available_from",
    "available_to",
    "relocation_units",
    "extra_units",
    "extra_unit_cost",
  ] as const;

  const rowObject: Partial<RelocationItemInput> = {
    quantity: 1,
    hire_unit_rate: 1,
  };

  if (!row.at(0)) {
    return null;
  }

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "line":
        rowObject.line = item;
        break;
      case "origin":
        rowObject.departure_office_code = item;
        break;
      case "destination":
        rowObject.delivery_office_code = item;
        break;
      case "available_from":
        rowObject.available_from = dayjs(item, "D/M/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;

      case "available_to":
        rowObject.available_to = dayjs(item, "D/M/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "vehicle_code":
        rowObject.vehicle_code = item;
        break;

      case "relocation_units":
        rowObject.hire_units_allowed = +item;
        break;

      case "extra_units":
        rowObject.extra_hire_units_allowed = +item;
        break;

      case "extra_unit_cost":
        //Strip the euro sign
        rowObject.extra_hire_unit_rate = +item.replace("€", "");
        break;

      default:
        break;
    }
  });

  return rowObject as RelocationItemInput;
}
