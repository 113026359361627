import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { RelocationMultiTable } from "@/app/Relocations/Components/RelocationMultiTable";
import { useSearch } from "@tanstack/react-router";

export function SupplierRelocationScreen() {
  const record = useSupplierRecord();
  const { relocationStatus } = useSearch({
    from: "/_app/suppliers/_supplierRecordLayout/$id/relocations",
  });

  return (
    <RelocationMultiTable
      tableId={"supplier-relocations"}
      supplierId={record.id}
      statuses={relocationStatus}
      getTabLinkProps={(status) => {
        return {
          to: "/suppliers/$id/relocations",
          search: {
            relocationStatus: [status],
          },
        };
      }}
    />
  );
}
