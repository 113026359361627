import {
  QuerySupplierTripsOrderByColumn,
  QuerySupplierTripsWhereColumn,
  SortOrder,
} from "@/gql/graphql";
import { CellContext } from "@tanstack/react-table";
import {
  formatCurrency,
  formatDistance,
} from "@/lib/Formatters/formatCurrency";
import {
  SupplierTripListItem,
  supplierTripListQuery,
} from "@/app/SupplierTrips/GraphQL/supplierTripListQuery";
import { useSupplierTripsActions } from "@/app/SupplierTrips/Actions/useSupplierTripsActions";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierTripCreateDialog } from "@/app/SupplierTrips/Components/SupplierTripCreateDialog";
import { PlusIcon } from "@heroicons/react/24/outline";

type SupplierTripColumnID =
  | "trip"
  | "days"
  | "distance"
  | "inclusions"
  | "ferry";

export function SupplierTripsTable({
  id,
  supplierId,
}: {
  id: TableId;
  supplierId: string;
}) {
  const getActions = useSupplierTripsActions();
  const { open } = useDialog(SupplierTripCreateDialog);

  const columns: DataTableColDef<
    SupplierTripListItem,
    unknown,
    SupplierTripColumnID
  >[] = [
    {
      id: "trip",
      header: "Trip",
      accessorFn: (row) =>
        `${row.officeA.city.name} - ${row.officeB.city.name}`,
    },
    {
      id: "days",
      accessorFn: (row) =>
        `${row.hire_units_allowed} + ${row.extra_hire_units_allowed}`,
      cell: DeEmphasisedCell,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Days" />
      ),
    },
    {
      id: "distance",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Distance" />
      ),
      accessorFn: (row) =>
        `${formatDistance(row.distance_allowed, row.supplier.measurement)}`,
      cell: DeEmphasisedCell,
    },
    {
      id: "ferry",
      header: "Ferry",
      accessorFn: (row) => row,
      cell: FerryCell,
    },
    {
      id: "inclusions",
      header: "Inclusions",
      cell: InclusionsCell,
    },
  ];

  return (
    <DataTable
      id={id}
      columns={columns}
      getActions={getActions}
      document={supplierTripListQuery}
      accessor={(data) => data.supplierTrips}
      searchable={false}
      rightButtons={
        <Button
          LeadingIcon={PlusIcon}
          onClick={() => {
            open({
              initialValues: {
                supplier: {
                  connect: supplierId,
                },
              },
            });
          }}
        >
          Add Trip
        </Button>
      }
      getQueryVariables={({ sorting, pagination }) => {
        return {
          page: pagination.pageIndex,
          first: pagination.pageSize,
          where: {
            column: QuerySupplierTripsWhereColumn.SupplierId,
            value: supplierId,
          },
          orderBy: sorting.map((sort) => {
            const order = sort.desc ? SortOrder.Desc : SortOrder.Asc;

            if (sort.id === "distance") {
              return {
                column: QuerySupplierTripsOrderByColumn.DistanceAllowed,
                order,
              };
            }

            if (sort.id === "days") {
              return {
                column: QuerySupplierTripsOrderByColumn.HireUnitsAllowed,
                order,
              };
            }

            throw new Error(`Unknown sort column: ${sort.id}`);
          }),
        };
      }}
    />
  );
}

function InclusionsCell({ row }: CellContext<SupplierTripListItem, any>) {
  return (
    <div>
      <ul>
        {row.original.inclusions.map((inclusion) => {
          return (
            <li key={inclusion.id}>
              <p className="text-xs text-gray-500">{inclusion.type}</p>
              {inclusion.value ? (
                <p>
                  {formatCurrency(
                    inclusion.value,
                    row.original.supplier.currency,
                  )}
                </p>
              ) : null}
              <p
                dangerouslySetInnerHTML={{
                  __html: inclusion.description ?? "",
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function FerryCell({ row }: CellContext<SupplierTripListItem, any>) {
  return (
    <div>
      <p>{row.original.is_ferry_required ? "Yes" : "No"}</p>
    </div>
  );
}
