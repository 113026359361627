import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  CogIcon,
  CurrencyDollarIcon,
  MegaphoneIcon,
  SquaresPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Link, LinkProps, Outlet } from "@tanstack/react-router";
import { cn } from "@/lib/utils";
import {
  BarChart3Icon,
  CarIcon,
  ComponentIcon,
  DollarSignIcon,
  GlobeIcon,
  MapPinIcon,
  ShieldCheckIcon,
  ShoppingCartIcon,
} from "lucide-react";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { FC } from "react";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { Badge } from "@/components/catalyst/badge";

type VisibleTo =
  | "all"
  | "supplier"
  | "no-supplier"
  | "admin"
  | "supplier-admin";

export function SettingsScreen() {
  const subNavigation: {
    name: string;
    to: LinkProps["to"];
    icon: FC<any>;
    visibleTo: VisibleTo;
  }[] = [
    {
      name: "General",
      to: "/settings",
      icon: CogIcon,
      visibleTo: "supplier",
    },
    {
      name: "Vehicles",
      to: "/settings/vehicles",
      icon: CarIcon,
      visibleTo: "supplier",
    },
    {
      name: "Offices",
      to: "/settings/offices",
      icon: MapPinIcon,
      visibleTo: "supplier",
    },
    {
      name: "Opens hours",
      icon: CalendarDaysIcon,
      to: "/settings/availability",
      visibleTo: "supplier",
    },
    {
      name: "Insurance",
      icon: ShieldCheckIcon,
      to: "/settings/insurance",
      visibleTo: "supplier",
    },
    {
      name: "Extras",
      icon: ShoppingCartIcon,
      to: "/settings/extras",
      visibleTo: "supplier",
    },
    {
      name: "Notifications",
      icon: MegaphoneIcon,
      to: "/settings/notifications",
      visibleTo: "supplier",
    },
    {
      name: "Billing",
      icon: CurrencyDollarIcon,
      to: "/settings/billing",
      visibleTo: "supplier",
    },
    {
      name: "Integrations",
      icon: SquaresPlusIcon,
      to: "/settings/integrations",
      visibleTo: "supplier",
    },
    {
      name: "Brands",
      icon: BuildingOfficeIcon,
      to: "/settings/suppliers",
      visibleTo: "no-supplier",
    },
    {
      name: "Reporting",
      icon: BarChart3Icon,
      to: "/settings/reports",
      visibleTo: "all",
    },
    {
      name: "Team",
      icon: UsersIcon,
      to: "/settings/organisation/organization-members",
      visibleTo: "all",
    },
    {
      name: "Cities",
      icon: GlobeIcon,
      to: "/settings/cities",
      visibleTo: "admin",
    },
    {
      name: "Organisations",
      icon: ComponentIcon,
      to: "/settings/organisations",
      visibleTo: "admin",
    },
    {
      name: "Rates",
      icon: DollarSignIcon,
      to: "/settings/default-rates",
      visibleTo: "admin",
    },
  ];

  const { supplier } = useGlobalSupplier();
  const { isAdmin } = useTenant();

  return (
    <div className="bg-gray-50 h-full overflow-y-auto scroll-gutter-stable">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 mx-auto max-w-7xl lg:px-8 lg:py-12 w-full">
        <aside className="px-2 py-6 sm:px-6 lg:col-span-2 lg:px-0 lg:py-0 relative">
          <nav className="space-y-1 sticky top-3">
            {subNavigation
              .filter((item) => {
                if (item.visibleTo === "all") {
                  return true;
                }

                if (item.visibleTo === "admin" && isAdmin) {
                  return true;
                }

                if (
                  item.visibleTo === "supplier-admin" &&
                  isAdmin &&
                  supplier
                ) {
                  return true;
                }

                if (item.visibleTo === "supplier" && supplier) {
                  return true;
                }

                return item.visibleTo === "no-supplier" && !supplier;
              })
              .map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  search={{}}
                  className={cn(
                    "group flex items-center rounded-md px-3 py-2 text-sm font-medium hover:bg-white hover:text-indigo-500 text-gray-900",
                  )}
                  activeProps={{
                    className: "bg-gray-50 text-indigo-600 ",
                    "aria-current": "page",
                  }}
                  activeOptions={{
                    exact: true,
                  }}
                >
                  {({ isActive }) => {
                    return (
                      <>
                        <div className="flex-grow flex">
                          <item.icon
                            className={cn(
                              isActive
                                ? "text-indigo-500"
                                : "text-gray-400 group-hover:text-indigo-500",
                              "-ml-1 mr-3 h-6 w-6 flex-shrink-0",
                            )}
                            aria-hidden="true"
                          />
                          <span className="truncate mr-3">{item.name}</span>
                        </div>
                        {item.visibleTo === "admin" ? (
                          <Badge color="blue">admin</Badge>
                        ) : null}
                      </>
                    );
                  }}
                </Link>
              ))}
          </nav>
        </aside>
        {/* Payment details */}
        <div className="p w-full space-y-6 sm:px-6 lg:col-span-9">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
