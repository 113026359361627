import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { BookingStatus } from "@/gql/graphql";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useTranslation } from "react-i18next";

export function BookingCancelledStatusInput(
  props: Omit<SelectInputProps<any>, "options"> & {
    enabledStatuses?: BookingStatus[];
  },
) {
  const { isAdmin } = useTenant();
  const { t } = useTranslation("booking");

  const customerOptions: BookingStatus[] = [
    BookingStatus.CustomerCancelledNoShow,
    BookingStatus.CustomerCancelledConfirmationTimeliness,
    BookingStatus.CustomerCancelledRebooked,
    BookingStatus.CustomerCancelled,
  ].filter((status) => {
    return props.enabledStatuses?.includes(status) ?? true;
  });

  const supplierOptions: BookingStatus[] = [
    BookingStatus.SupplierCancelledNoFerry,
    BookingStatus.SupplierCancelledMechanicalFailure,
    BookingStatus.SupplierCancelledNotAvailable,
    BookingStatus.SupplierCancelled,
  ].filter((status) => {
    return props.enabledStatuses?.includes(status) ?? true;
  });

  const adminOptions: BookingStatus[] = [BookingStatus.AdminCancelled];

  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "customer",
          options: customerOptions.map((status) => {
            return {
              value: status,
              label: t(`statusInputLabel.${status}`),
            };
          }),
        },
        {
          label: "supplier",
          options: supplierOptions.map((status) => {
            return {
              value: status,
              label: t(`statusInputLabel.${status}`),
            };
          }),
        },
        ...(isAdmin
          ? [
              {
                label: "admin",
                options: adminOptions.map((status) => {
                  return {
                    value: status,
                    label: t(`statusInputLabel.${status}`),
                  };
                }),
              },
            ]
          : []),
      ]}
    />
  );
}

export function BookingStatusInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const { t } = useTranslation("booking");

  const options: Record<BookingStatus, string> = {
    AWAITING_CONFIRMATION: "",
    [BookingStatus.AwaitingPayment]: "Awaiting payment",
    [BookingStatus.Completed]: "Completed",
    [BookingStatus.Confirmed]: "Confirmed",
    [BookingStatus.Vip]: "VIP",
    [BookingStatus.AdminCancelled]: "Admin cancelled",
    [BookingStatus.CustomerCancelled]: "Customer cancelled",
    CUSTOMER_CANCELLED_CONFIRMATION_TIMELINESS: "",
    CUSTOMER_CANCELLED_NO_SHOW: "",
    CUSTOMER_CANCELLED_REBOOKED: "",
    [BookingStatus.SupplierCancelled]: "Supplier cancelled",
    SUPPLIER_CANCELLED_MECHANICAL_FAILURE: "",
    SUPPLIER_CANCELLED_NOT_AVAILABLE: "",
    SUPPLIER_CANCELLED_NO_FERRY: "",
  };

  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "customer",
          options: Object.entries(options).map(([status]) => {
            return {
              value: status,
              label: t(`status.${status as BookingStatus}`),
            };
          }),
        },
      ]}
    />
  );
}
