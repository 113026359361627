import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import dayjs, { Dayjs } from "dayjs";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { days, DayType, OpenHours } from "@/app/Offices/Utils/days";
import { GenericField } from "@/lib/Components/Form/Fields/GenericField";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { Switch } from "@headlessui/react";

export function OpenHoursInput({
  prefix,
  className,
}: {
  prefix: string;
  className?: string;
}) {
  return (
    <div className={cn("col-span-6", className)}>
      {days.map((day) => (
        <ToFromSelect day={day} prefix={prefix} key={day} />
      ))}
    </div>
  );
}

function ToFromSelect({ day, prefix }: { day: DayType; prefix: string }) {
  const { setFieldValue, values } = useForm<Record<string, OpenHours>>();

  const value = values[prefix]?.[day]?.at(0);
  const startTime = value?.split("-")[0] ?? null;
  const endTime = value?.split("-")[1] ?? null;
  const enabled = values[prefix]?.[day]?.length > 0;

  return (
    <GenericField
      name={`${prefix}.${day}`}
      label={day}
      className="group"
      viewNode={<ViewNode value={values[prefix]?.[day]} />}
    >
      <div className="flex items-center space-x-3">
        <Switch
          checked={enabled}
          onChange={(checked) => {
            if (checked) {
              setFieldValue(`${prefix}.${day}`, ["09:00-16:00"]);
            } else {
              setFieldValue(`${prefix}.${day}`, []);
            }
          }}
          className={`${
            enabled ? "bg-blue-600" : "bg-gray-200"
          } relative mt-1 inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>

        <fieldset disabled={!enabled} className="space-x-3">
          <TimeSelect
            value={startTime}
            onSelect={(newStart) => {
              setFieldValue(`${prefix}.${day}`, [`${newStart}-${endTime}`]);
            }}
          />
          <TimeSelect
            value={endTime}
            relativeTo={startTime ?? undefined}
            onSelect={(newEnd) => {
              setFieldValue(`${prefix}.${day}`, [`${startTime}-${newEnd}`]);
            }}
          />
        </fieldset>

        {enabled ? (
          <TextButton
            onClick={() => {
              const newHours = {
                monday: [`${startTime}-${endTime}`],
                tuesday: [`${startTime}-${endTime}`],
                wednesday: [`${startTime}-${endTime}`],
                thursday: [`${startTime}-${endTime}`],
                friday: [`${startTime}-${endTime}`],
                saturday: [`${startTime}-${endTime}`],
                sunday: [`${startTime}-${endTime}`],
              };
              setFieldValue(prefix, newHours);
            }}
            className="opacity-0 group-hover:opacity-100 transition"
            intent="primary"
          >
            apply to all days
          </TextButton>
        ) : null}
      </div>
    </GenericField>
  );
}

function ViewNode({ value }: { value: string[] }) {
  if (value.length === 0) {
    return <span className="font-bold">Closed</span>;
  }
  return value.map((time: any) => {
    //transform 09:00-16:00 to 9am-4pm

    return (
      <span key={time} className="block">
        {dayjs(time.split("-")[0], "HH:mm").format("h:mma")} -{" "}
        {dayjs(time.split("-")[1], "HH:mm").format("h:mma")}
      </span>
    );
  });
}

type TimeSelectProps = {
  relativeTo?: string;
  value: string | null;
  onSelect: (value: string) => void;
  className?: string;
};
export function TimeSelect({
  relativeTo: rt,
  value,
  onSelect,
  className,
}: TimeSelectProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const relativeTo = rt ? dayjs(rt, "HH:mm") : undefined;

  const startTime = relativeTo
    ? dayjs()
        .set("hours", relativeTo.hour())
        .set("minutes", relativeTo.minute())
    : dayjs().startOf("day");

  const minsInDay = 1440;
  const minutes = startTime.hour() * 60 + startTime.minute();
  const minutesRemaining = minsInDay - minutes;
  const numSlots = Math.floor(minutesRemaining / 30);

  const times: {
    value: string;
    label: string;
  }[] = Array.from({ length: numSlots }).map((_, i) => {
    const newTime = startTime.add(i * 30, "minute");
    return {
      value: newTime.format("HH:mm"),
      label: formatLabel(newTime, relativeTo),
    };
  });

  const filteredTimes = times.filter((time) => {
    if (!search) return true;

    return time.label.toLowerCase().includes(search.toLowerCase());
  });

  if (search) {
    const time = dayjs(search, "H:mm");
    const time2 = dayjs(search, "h:mma");
    const time3 = dayjs(search, "h");

    let parsedTime = null;
    if (time.isValid()) {
      parsedTime = time;
    } else if (time2.isValid()) {
      parsedTime = time2;
    } else if (time3.isValid()) {
      parsedTime = time3;
    }

    if (parsedTime) {
      const newTime = dayjs()
        .set("hours", parsedTime.hour())
        .set("minutes", parsedTime.minute());

      if (relativeTo && newTime.isBefore(relativeTo)) {
        //Do nothing
      } else if (
        filteredTimes.findIndex((t) => t.value === newTime.format("HH:mm")!) ===
        -1
      ) {
        filteredTimes.unshift({
          value: newTime.format("HH:mm"),
          label: formatLabel(newTime, relativeTo),
        });
      }
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("justify-between min-w-[140px]", className)}
        >
          {value ? dayjs(value, "HH:mm").format("h:mma") : "Select a time"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search time"
            onValueChange={(newVal) => {
              setSearch(newVal);
            }}
          />
          <CommandEmpty>No Time Found</CommandEmpty>
          <CommandList>
            <CommandGroup>
              <ScrollArea className="w-full h-[200px]">
                {filteredTimes.map((time) => (
                  <CommandItem
                    key={time.value}
                    value={time.value}
                    onSelect={(currentValue) => {
                      //The value is changed to lower case, so we need to reparse it
                      onSelect(currentValue);
                      setOpen(false);
                      setSearch("");
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === time.value ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {time.label}
                  </CommandItem>
                ))}
              </ScrollArea>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function formatLabel(time: Dayjs, relativeTo?: Dayjs) {
  if (!relativeTo) {
    return time.format("h:mma");
  }

  const diff = time.diff(relativeTo, "minutes");

  if (diff < 60) {
    return `${time.format("h:mma")} (${diff} mins)`;
  }

  if (diff <= 1440) {
    return `${time.format("h:mma")} (${Math.round(diff / 60)} hrs)`;
  }

  return `${time.format("h:mma")} (${Math.round(diff / 1440)} days)`;
}
