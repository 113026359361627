import { OfficeTable } from "@/app/Offices/Components/OfficeTable";
import {
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { JucyOfficeCodeCell } from "@/app/Settings/Integrations/Jucy/Components/JucyOfficeCodeCell";
import { ModelLinkCell } from "@/lib/Components/Tables/Components/ModelLinkCell";

export function JucyOfficeTable() {
  const supplierOfficeCols: DataTableColDef<
    SupplierOfficeListItem,
    any,
    any
  >[] = [
    {
      id: "name",
      accessorFn: (data) => data.name,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <ModelLinkCell
            to={"/offices/$id"}
            params={{
              id: row.original.id,
            }}
          >
            {row.original.name}
          </ModelLinkCell>
        );
      },
    },
    {
      id: "code",
      accessorFn: (data) => data.code ?? "--",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Code" />
      ),
    },
    {
      id: "jucy",
      header: "Jucy Site Code",
      cell: JucyOfficeCodeCell,
    },
  ];

  return (
    <OfficeTable
      id="settings-integrations-jucy-offices"
      columns={supplierOfficeCols}
    />
  );
}
