import { ContactType, ModelType } from "@/gql/graphql";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { useContactRecord } from "@/app/Contacts/Hooks/useContactRecord";
import { ContactTable } from "@/app/Contacts/Components/ContactTable";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@/lib/Components/Button/Button";
import { ContactEntityCreateDialog } from "@/app/Contacts/Components/ContactEntityCreateDialog";

export function ContactEntityScreen() {
  const { open } = useDialog(ContactEntityCreateDialog);
  const record = useContactRecord();

  return (
    <div className="py-6 space-y-12">
      <ContactTable
        id="contact-entities"
        rightButtons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({
                contactId: record.id,
              });
            }}
          >
            Add entity
          </Button>
        }
        queryVariables={{
          type: [ContactType.Entity],
          objectId: record.id,
          objectType: ModelType.Contact,
        }}
      />
    </div>
  );
}
