import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { useTranslation } from "react-i18next";
import { InvoiceStatus } from "@/gql/graphql";

export function InvoiceStatusInput(props: Omit<SelectInputProps, "options">) {
  const { t } = useTranslation("invoice");

  const options = Object.values(InvoiceStatus).map((status) => {
    return {
      value: status,
      label: t(`status.${status}`),
    };
  });

  return (
    <SelectInput
      options={[
        {
          label: "Status",
          options,
        },
      ]}
      {...props}
    />
  );
}
