import { HireUnitType, RelocationStatus } from "@/gql/graphql";
import Resources from "../resources";

const resource: Resources["relocation"] = {
  hire_unit_type: {
    [HireUnitType.Day]: "day",
    [HireUnitType.Night]: "night",
    [HireUnitType.TwentyFourHours]: "24hr",
  },
  status: {
    [RelocationStatus.Archived]: "archived",
    [RelocationStatus.Draft]: "draft",
    [RelocationStatus.Expired]: "expired",
    [RelocationStatus.Paused]: "paused",
    [RelocationStatus.Ready]: "active",
    [RelocationStatus.SoldOut]: "sold",
  },
};

export default resource;
