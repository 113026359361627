import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";

export function SupplierPaymentSummary({
  booking,
}: {
  booking: BookingRecord;
}) {
  const { t } = useTranslation("relocation");

  const rows: {
    label: ReactNode;
    value: number;
    quantity?: ReactNode;
    isHidden?: boolean;
  }[] = [
    {
      label: "Relocation units",
      value: booking.pay_later_discounted_amount,
      quantity: `${booking.discounted_units}x ${t(
        `hire_unit_type.${booking.relocation.hire_unit_type}`,
      )}s @ ${formatCurrency(
        booking.relocation.hire_unit_rate,
        booking.currency,
      )}`,
    },

    {
      label: "Extra units",
      value: booking.pay_later_full_price_amount,
      quantity: `${booking.full_price_units}x ${t(
        `hire_unit_type.${booking.relocation.hire_unit_type}`,
      )}s @ ${formatCurrency(
        booking.relocation.extra_hire_unit_supplier_net_rate ?? 0,
        booking.currency,
      )}`,
      isHidden: booking.full_price_units === 0,
    },
    ...booking.purchasableExtras.map((extra) => {
      return {
        label: extra.supplierPurchasableExtra.name,
        quantity: `${extra.quantity} x ${formatCurrency(extra.supplierPurchasableExtra.unit_price, booking.currency)}`,
        value: extra.supplierPurchasableExtra.unit_price * extra.quantity,
      };
    }),

    ...(booking.supplierPaymentSummary.additional_charges_amount > 0
      ? [
          {
            label: "Additional charges",
            value: booking.supplierPaymentSummary.additional_charges_amount,
          },
        ]
      : []),
    ...(booking.supplierPaymentSummary.holding_deposit_amount > 0
      ? [
          {
            label: "Holding deposit",
            value: -booking.supplierPaymentSummary.holding_deposit_amount,
          },
        ]
      : []),
  ];

  return (
    <div className="rounded-md bg-gray-50 p-6">
      <table className="w-full">
        <tbody>
          {rows.map((row, index) => {
            if (row.isHidden) {
              return null;
            }

            return (
              <tr className="border-b border-gray-200" key={index}>
                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0 font-bold">
                  {row.label}
                </td>
                <td className="px-3 py-5 text-right text-sm text-gray-500">
                  {row.quantity}
                </td>
                <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                  {formatCurrency(row.value, booking.currency)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td className="pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900">
              Balance due
            </td>
            <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
              {formatCurrency(
                booking.supplierPaymentSummary.total,
                booking.currency,
              )}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
