import { graphql } from "@/gql";

export const createSupplierInsuranceOptionMutation = graphql(`
  mutation CreateSupplierInsuranceOption(
    $input: CreateSupplierInsuranceOptionInput!
  ) {
    createSupplierInsuranceOption(input: $input) {
      ...SupplierInsuranceOptionRecordFields
    }
  }
`);

export const updateSupplierInsuranceOptionMutation = graphql(`
  mutation UpdateSupplierInsuranceOption(
    $id: ID!
    $input: UpdateSupplierInsuranceOptionInput!
  ) {
    updateSupplierInsuranceOption(id: $id, input: $input) {
      ...SupplierInsuranceOptionRecordFields
    }
  }
`);
