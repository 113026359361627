import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import dayjs from "dayjs";
import { DATE_STRING_FORMAT } from "@/app/Import/Utils/pasteTemplate";

export function parseOthers(
  grid: AgGridReact,
  row: any,
): RelocationItemInput | null {
  const fields = [
    "line",
    "departure",
    "destination",
    "pickup_date",
    "dropoff_date",
    "vehicle_code",
    "quantity",
    "rego",
    "cost",
    "days",
    "kms",
    "fuel",
  ] as const;

  const rowObject: Partial<RelocationItemInput> = {};

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "line":
        rowObject.line = item;
        break;
      case "departure":
        rowObject.departure_office_code = item;
        break;
      case "destination":
        rowObject.delivery_office_code = item;
        break;
      case "pickup_date":
        if (item.trim() === "now") {
          rowObject.available_from = dayjs().format(DATE_STRING_FORMAT);
          break;
        }

        rowObject.available_from = dayjs(item, "D/MMM/YY").format(
          DATE_STRING_FORMAT,
        );
        break;

      case "dropoff_date":
        rowObject.available_to = dayjs(item, "D/MMM/YY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "vehicle_code":
        rowObject.vehicle_code = item;
        break;

      case "cost":
        rowObject.hire_unit_rate = parseInt(item.replace("$", ""));
        break;

      case "days":
        rowObject.hire_units_allowed = parseInt(item);
        break;

      case "fuel":
        //$100 fuel allowance, keep receipts
        //Extract the dollar amount with regex
        const fuelAmount = item.match(/\d+/g)?.[0];

        if (fuelAmount && parseInt(fuelAmount) > 0) {
          rowObject.fuel_amount = parseInt(fuelAmount);
          rowObject.fuel_note = "Keep receipts";
        }
        break;

      case "kms":
        rowObject.distance_allowed = parseInt(item);
        break;

      case "quantity":
        rowObject.quantity = parseInt(item);
        break;

      case "rego":
        if (!rowObject.line) {
          rowObject.line = item;
        }
        break;

      default:
        break;
    }
  });

  return rowObject as RelocationItemInput;
}
