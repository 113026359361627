import { SettingFormCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { ClosedDatesForm } from "@/app/Suppliers/Components/ClosedDatesForm";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationCalendar } from "@/assets/Illustrations";
import {
  fromOpenDaysSchema,
  toOpenDaysSchema,
} from "@/app/Suppliers/Utils/mapClosedDays";
import { sortOpenHours } from "@/app/Offices/Utils/sortOpenHours";
import { OpenHoursInput } from "@/app/Offices/Components/OpenHoursInput";
import { redirect } from "@tanstack/react-router";
import { settingsRoute } from "@/app/Settings/Settings/Routes/settingsRoute";

export function SettingOpenHoursScreen() {
  const { supplier } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);

  if (!supplier) {
    throw redirect({
      to: settingsRoute.to,
    });
  }

  return (
    <div className="space-y-12">
      <SettingFormCard
        title="Departure hours"
        description="Specify the hours when the offices are open for pickup"
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
        initialValues={{
          departure_hours: sortOpenHours(supplier.departure_hours),
        }}
      >
        <OpenHoursInput prefix="departure_hours" className="col-span-full" />
      </SettingFormCard>

      <SettingFormCard
        title="Delivery hours"
        description="Specify the hours when the offices are open for drop-off"
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
        initialValues={{
          delivery_hours: sortOpenHours(supplier.delivery_hours),
        }}
      >
        <OpenHoursInput prefix="delivery_hours" className="col-span-full" />
      </SettingFormCard>

      <SettingFormCard
        title="Closed dates"
        description="Specify the dates when all offices are closed"
        initialValues={{
          closed_dates: fromOpenDaysSchema(supplier.closed_dates),
        }}
        onSubmit={async (values) => {
          const foo = toOpenDaysSchema(values.closed_dates);
          await mutateAsync({
            id: supplier.id,
            input: {
              closed_dates: foo,
            },
          });
        }}
      >
        <ClosedDatesForm
          emptyState={
            <EmptyState
              Icon={IllustrationCalendar}
              header="No closed dates"
              subheader="Add date(s) you want to close all offices on"
            />
          }
        />
      </SettingFormCard>
    </div>
  );
}
