import { createRoute } from "@tanstack/react-router";
import { SignInScreen } from "@/app/Auth/Screens/SignInScreen";
import { SignUpScreen } from "@/app/Auth/Screens/SignUpScreen";
import { loginLayoutRoute } from "@/routes";
import { OnboardingScreen } from "@/app/Onboarding/Screens/OnboardingScreen";

export const signInRoute = createRoute({
  getParentRoute: () => loginLayoutRoute,
  path: "/sign-in",
  component: SignInScreen,
});

export const signUpRoute = createRoute({
  getParentRoute: () => loginLayoutRoute,
  path: "/sign-up",
  component: SignUpScreen,
});

export const changeOrganisationRoute = createRoute({
  getParentRoute: () => loginLayoutRoute,
  path: "/change-organisation",
  component: OnboardingScreen,
});
