import { ICellRendererParams } from "ag-grid-community";
import { formatDistance } from "@/lib/Formatters/formatCurrency";
import { Measurement } from "@/gql/graphql";

export function DistanceRenderCell(props: ICellRendererParams) {
  const cellValue = props.value;
  const measurement: Measurement =
    props.colDef?.cellRendererParams?.measurement;

  if (!measurement) return "ERR";
  if (!cellValue) return null;

  return (
    <span className="text-right w-full">
      {formatDistance(cellValue, measurement)}
    </span>
  );
}
