import { graphql } from "@/gql";

export const createRelocationMutation = graphql(`
  mutation CreateRelocation($input: CreateRelocationInput!) {
    createRelocation(input: $input) {
      id
    }
  }
`);

export const updateRelocationMutation = graphql(`
  mutation UpdateRelocation($id: ID!, $input: UpdateRelocationInput!) {
    updateRelocation(id: $id, input: $input) {
      id
    }
  }
`);

export const duplicateRelocationMutation = graphql(`
  mutation DuplicateRelocation($id: ID!) {
    duplicateRelocation(id: $id) {
      id
    }
  }
`);

export const archiveRelocationMutation = graphql(`
  mutation ArchiveRelocations($ids: [ID!]!) {
    archiveRelocations(ids: $ids)
  }
`);
