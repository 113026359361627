import { ContactType } from "@/gql/graphql";

const contactType: { [key in ContactType]: string } = {
  [ContactType.Entity]: "entity",
  [ContactType.Guest]: "guest",
  [ContactType.Organisation]: "organisation",
  [ContactType.System]: "system",
  [ContactType.User]: "user",
};

const contactResource = {
  type: contactType,
};

export default contactResource;
export type ContactResource = typeof contactResource;
