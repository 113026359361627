import { FC, useState } from "react";
import { MenuItem } from "@headlessui/react";
import classNames from "classnames";
import { Spinner } from "../Layout/Loaders/Spinner";
import { Intent } from "@/lib/Components/Common/Intent";
import { MultiActionGroupButton } from "@/lib/Components/Actions/Components/MultiActionGroupButton";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { cn } from "@/lib/utils";

export type ActionsHook<T> = (model: T) => ActionGroupButton[];

export interface ActionGroupProps {
  buttons: ActionGroupButton[];
}

export type ActionGroupButtonType = "primary" | "outlined" | "danger";

export type MultiActionGroupButtonType = {
  label: string;
  buttonType?: ActionGroupButtonType;
  items: ActionItemGroup[];
};

export type ActionGroupButton = MultiActionGroupButtonType;

export interface ActionItemGroup {
  actions: Action[];
}

export interface Action {
  intent?: Intent;
  label: string;
  Icon?: FC<any>;
  handleAction: () => Promise<any> | void;
  isHidden?: boolean;
  isAdminAction?: boolean;
  isHiddenTopBar?: boolean;
}

export function ActionGroupButtons({ buttons }: ActionGroupProps) {
  return (
    <div className="flex space-x-4">
      {buttons.map((item, index) => {
        return <MultiActionGroupButton key={index} {...item} />;
      })}
    </div>
  );
}

const colors: {
  [key in Intent]: {
    active: string;
    default: string;
  };
} = {
  highlight: {
    active: "bg-pink-100 text-pink-900",
    default: "text-pink-700",
  },
  primary: {
    active: "bg-indigo-100 text-indigo-900",
    default: "text-indigo-700",
  },
  danger: {
    active: "bg-red-100 text-red-900",
    default: "text-red-700",
  },
  neutral: {
    active: "bg-gray-100 text-gray-900",
    default: "text-gray-700",
  },
  success: {
    active: "bg-green-100 text-green-900",
    default: "text-green-700",
  },
  warning: {
    active: "bg-yellow-100 text-yellow-900",
    default: "text-yellow-700",
  },
  info: {
    active: "bg-yellow-100 text-yellow-900",
    default: "text-yellow-700",
  },
};

export function ActionMenuItem({
  Icon,
  label: actionLabel,
  handleAction,
  intent: actionType = "neutral",
  isHidden,
  isAdminAction,
}: Action) {
  const [isLoading, setIsLoading] = useState(false);
  const { isAdmin } = useTenant();

  if (isAdminAction && !isAdmin) return null;
  if (isHidden) return null;

  return (
    <MenuItem as="div">
      {({ focus, close }) => {
        return (
          <button
            type="button"
            className={cn(
              focus ? colors[actionType].active : colors[actionType].default,
              "group flex w-full items-center px-4 py-2 text-sm text-left min-w-0",
            )}
            onClick={async (e) => {
              e.preventDefault();
              setIsLoading(true);
              try {
                await handleAction();
                close();
              } catch (e: any) {
                console.error(e);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <span className="mr-3 text-gray-400 group-hover:text-gray-500 flex-shrink-0">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {Icon && (
                    <Icon
                      className={classNames(
                        "h-5 w-5",
                        focus
                          ? colors[actionType].active
                          : colors[actionType].default,
                      )}
                    />
                  )}
                </>
              )}
            </span>
            <span className="flex-grow truncate">{actionLabel}</span>
          </button>
        );
      }}
    </MenuItem>
  );
}
