import { days, OpenHours } from "@/app/Offices/Utils/days";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

export function OpenHoursViewState({
  openHours,
  parentOpenHours,
}: {
  openHours: OpenHours;
  parentOpenHours?: OpenHours;
}) {
  return (
    <div className="col-span-full grid grid-cols-7 gap-x-2">
      {days.map((day) => {
        const isClosed = openHours[day]?.length === 0;
        const isDifferentFromParent =
          parentOpenHours &&
          JSON.stringify(parentOpenHours[day]) !==
            JSON.stringify(openHours[day]);

        return (
          <div
            key={day}
            className={cn("p-1 rounded-md flex flex-col ", {
              "bg-yellow-50": isDifferentFromParent,
              "bg-red-50": isClosed,
            })}
          >
            <div>
              <div className="text-gray-500 text-xs capitalize flex items-center space-x-2">
                <span>{day}</span>
                {isDifferentFromParent ? (
                  <Tooltip>
                    <TooltipTrigger>
                      <InformationCircleIcon className="w-4 h-4 text-gray-400" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Supplier hours</p>
                      {parentOpenHours[day]?.map((hours) => {
                        return (
                          <div
                            className="font-semibold flex flex-col"
                            key={hours}
                          >
                            <time>
                              {dayjs(hours.split("-")[0], "HH:mm").format(
                                "h:mma",
                              )}
                            </time>
                            <time>
                              {dayjs(hours.split("-")[1], "HH:mm").format(
                                "h:mma",
                              )}
                            </time>
                          </div>
                        );
                      })}
                    </TooltipContent>
                  </Tooltip>
                ) : null}
              </div>
              <div>
                {isClosed ? (
                  <div className="font-bold text-red-700">CLOSED</div>
                ) : null}
                {openHours[day]?.map((hours) => {
                  return (
                    <div className="font-semibold flex flex-col" key={hours}>
                      <time>
                        {dayjs(hours.split("-")[0], "HH:mm").format("h:mma")}
                      </time>
                      <time>
                        {dayjs(hours.split("-")[1], "HH:mm").format("h:mma")}
                      </time>
                    </div>
                  );
                })}
              </div>
            </div>
            <div></div>
          </div>
        );
      })}
    </div>
  );
}
