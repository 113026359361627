import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  CreateRelocationInput,
  RelocationFieldsFragment,
  RelocationInclusionInput,
  RelocationInclusionType,
} from "@/gql/graphql";
import { RelocationRecord } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { FC } from "react";
import {
  DollarSignIcon,
  FuelIcon,
  SailboatIcon,
  ShipIcon,
  ShoppingCartIcon,
} from "lucide-react";
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/utils";
import { RelocationFuelInclusionInput } from "@/app/Relocations/Components/RelocationFuelInclusionInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { Field, Fieldset, Legend } from "@/components/catalyst/fieldset";
import { useTranslations } from "use-intl";
import { Text } from "@/components/catalyst/text";
import { IconButton } from "@/lib/Components/Button/IconButton";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function RelocationInclusionForm({
  relocation,
}: {
  relocation?: RelocationRecord;
}) {
  const { values, setFieldValue, isEditing } = useForm<CreateRelocationInput>();
  const t = useTranslations("relocation");

  function deleteValue(value: RelocationInclusionInput) {
    //If we have an ID we need to set the inclusion to delete
    if (value.id) {
      setFieldValue("inclusions.delete", [
        ...(values.inclusions?.delete ?? []),
        value.id,
      ]);
    }

    setFieldValue(
      "inclusions.upsert",
      values.inclusions?.upsert?.filter((v) => {
        console.log({
          v,
          value,
        });
        return v !== value;
      }),
    );
  }

  if (!isEditing) {
    return <RelocationsInclusionsViewCard relocation={relocation} />;
  }

  return (
    <div className="col-span-full space-y-12">
      <RelocationFuelInclusionInput />
      {values.inclusions?.upsert?.map((inclusion, idx) => {
        if (inclusion.type === RelocationInclusionType.Fuel) {
          return null;
        }

        return (
          <Fieldset key={idx} className="relative">
            <Legend>{t(`inclusions.type.${inclusion.type}`)}</Legend>
            <Text>{t(`inclusions.typeDescription.${inclusion.type}`)}</Text>

            <div className="absolute top-0 right-0">
              <IconButton
                Icon={TrashIcon}
                tooltip="Delete"
                onClick={() => {
                  deleteValue(inclusion);
                }}
              />
            </div>

            <Field className="mt-3">
              <TextAreaInput
                name={`inclusions.upsert.${idx}.description`}
                label="Description"
              />
            </Field>
          </Fieldset>
        );
      })}

      <EmptyState />
    </div>
  );
}

function RelocationsInclusionsViewCard({
  relocation,
}: {
  relocation?: RelocationFieldsFragment;
}) {
  if (!relocation || relocation.inclusions.length === 0) {
    return null;
  }

  return (
    <Card className="col-span-full">
      <CardHeader>
        <CardTitle>Inclusions</CardTitle>
        <CardDescription>
          Inclusions to the relocation to provide additional information to the
          driver. This could include ferry crossings, additional charges, or
          other benefits.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ul className="space-y-3">
          {relocation.inclusions.map((inclusion) => {
            const Icon = inclusionIconMap[inclusion.type];

            return (
              <li
                key={inclusion.id}
                className="flex text-sm font-medium leading-6 text-gray-700 items-center"
              >
                <Icon
                  className="h-5 w-5 text-gray-400 mr-2"
                  aria-hidden="true"
                />
                {inclusion.value ? (
                  <div className="mr-1 font-semibold">
                    {formatCurrency(inclusion.value, relocation.currency)}
                  </div>
                ) : null}
                <div
                  dangerouslySetInnerHTML={{
                    __html: inclusion.description ?? "--",
                  }}
                />
              </li>
            );
          })}
        </ul>
      </CardContent>
    </Card>
  );
}

const inclusionIconMap: {
  [key in RelocationInclusionType]: FC<any>;
} = {
  [RelocationInclusionType.Charge]: DollarSignIcon,
  [RelocationInclusionType.Ferry]: SailboatIcon,
  [RelocationInclusionType.Fuel]: FuelIcon,
  [RelocationInclusionType.Negative]: ExclamationTriangleIcon,
  [RelocationInclusionType.Other]: StarIcon,
};

const items = [
  {
    title: "Add a ferry inclusion",
    description: "Such as a pre-booked ferry, a reimbursement etc",
    icon: ShipIcon,
    background: "bg-sky-500",
    type: RelocationInclusionType.Ferry,
  },
  {
    title: "Add other benefits",
    description: "Such as a linen or kitchen kit",
    icon: ShoppingCartIcon,
    background: "bg-pink-500",
    type: RelocationInclusionType.Other,
  },
  {
    title: "Add a charge",
    description: "Additional charges (cleaning, preparation, tolls etc)",
    icon: DollarSignIcon,
    background: "bg-yellow-500",
    type: RelocationInclusionType.Charge,
  },
  {
    title: "Add a negative",
    description: "Damage to the vehicle, cleaning required etc",
    icon: ExclamationTriangleIcon,
    background: "bg-red-500",
    type: RelocationInclusionType.Negative,
  },
];

export function EmptyState() {
  const { values, setFieldValue } = useForm<CreateRelocationInput>();
  function hasInclusion(type: RelocationInclusionType) {
    return values.inclusions?.upsert?.some((i) => i.type === type);
  }

  return (
    <div>
      <ul
        role="list"
        className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
      >
        {items.map((item, itemIdx) => (
          <li
            key={itemIdx}
            className={cn("flow-root", {
              "opacity-50 pointer-events-none cursor-not-allowed": hasInclusion(
                item.type,
              ),
            })}
          >
            <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
              <div
                className={cn(
                  item.background,
                  "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg",
                )}
              >
                <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  <button
                    disabled={hasInclusion(item.type)}
                    className="focus:outline-none"
                    onClick={() => {
                      setFieldValue("inclusions.upsert", [
                        ...(values.inclusions?.upsert ?? []),
                        {
                          type: item.type,
                          description: "",
                          value: null!,
                        },
                      ]);
                    }}
                  >
                    <span aria-hidden="true" className="absolute inset-0" />
                    <span>{item.title}</span>
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </h3>
                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
