import { FC, useContext, useEffect, useState } from "react";
import { dialogContext } from "../Providers/DialogContext";
import { v4 as uuidv4 } from "uuid";

export function useDialog<TProps, TData = unknown>(
  dialog: FC<TProps>,
): {
  open: (props: TProps) => void;
  openAsPromise: (props: TProps) => Promise<TData>;
  remove: () => void;
} {
  const [uuid] = useState(uuidv4());
  const { createDialog, removeDialog } = useContext(dialogContext);

  const open = (props: TProps | undefined) => {
    createDialog(uuid, {
      Component: dialog,
      isOpen: true,
      props,
    });
  };

  const openAsPromise = async (props: TProps) => {
    return new Promise<TData>((resolve, reject) => {
      createDialog(uuid, {
        Component: dialog,
        props,
        resolve,
        reject,
        isOpen: true,
      });
    });
  };

  const remove = () => removeDialog(uuid);

  useEffect(() => {
    return () => {
      removeDialog(uuid);
    };
  }, []);

  return { open, openAsPromise, remove };
}
