import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { WaitlistTable } from "@/app/Waitlists/Components/WaitlistTable";

export function RelocationWaitlistScreen() {
  const record = useRelocationRecord();

  return (
    <RecordScreenCard title="Waitlists">
      <WaitlistTable
        id="relocation-waitlists"
        getQueryVariables={({ pagination }) => {
          return {
            page: pagination.pageIndex,
            first: pagination.pageSize,
            relocationId: record.id,
          };
        }}
      />
    </RecordScreenCard>
  );
}
