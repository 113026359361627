import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { ModelType } from "@/gql/graphql";
import { useVehicleRecord } from "@/app/Vehicles/Hooks/useVehicleRecord";

export function VehicleActivityScreen() {
  const vehicle = useVehicleRecord();

  return (
    <div className="py-6">
      <RecordScreenCard title="Activity log">
        <ActivityLogFeed modelId={vehicle.id} modelType={ModelType.Vehicle} />
      </RecordScreenCard>
    </div>
  );
}
