import { useMatch } from "@tanstack/react-router";
import { userRoute } from "@/app/Users/Routes/userRoutes";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { userRecordQuery } from "@/app/Users/GraphQL/userRecordQuery";

export function useUserRecord() {
  const match = useMatch({
    from: userRoute.id,
  });

  const { data } = useSuspenseGqlQuery(userRecordQuery, {
    id: (match.params as any).id,
  });

  return data.user;
}
