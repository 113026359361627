import { z } from "zod";
import { PaymentStatus } from "@/gql/graphql";
import { createRoute } from "@tanstack/react-router";
import { PaymentDetailScreen } from "@/app/Payments/Screens/PaymentDetailScreen";
import { PaymentRecordScreen } from "@/app/Payments/Screens/PaymentRecordScreen";
import { PaymentListScreen } from "@/app/Payments/Screens/PaymentListScreen";
import { appLayoutRoute } from "@/routes";
import { getPaymentListQueryVariables } from "@/app/Payments/Utils/getPaymentListQueryVariables";
import { paymentListQuery } from "@/app/Payments/GraphQL/paymentListQuery";
import {
  prefetchInfiniteQuery,
  prefetchQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { paymentRecordQuery } from "@/app/Payments/GraphQL/paymentRecordQuery";

const paymentSearchParams = z.object({
  paymentStatus: z
    .array(z.nativeEnum(PaymentStatus))
    .optional()
    .catch(undefined),
  paymentSearch: z.string().optional(),
});
export type PaymentListSearchParams = z.infer<typeof paymentSearchParams>;
export const paymentListRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/payments",
  component: PaymentListScreen,
  validateSearch: paymentSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      paymentListQuery,
      getPaymentListQueryVariables(deps),
      auth,
    );
  },
});
export const paymentRoute = createRoute({
  getParentRoute: () => paymentListRoute,
  path: "$id",
  component: PaymentRecordScreen,
  loader: ({ params: { id }, context: { auth } }) => {
    return prefetchQuery(paymentRecordQuery, { id }, auth);
  },
});
export const paymentDetailRoute = createRoute({
  getParentRoute: () => paymentRoute,
  path: "/",
  component: PaymentDetailScreen,
});
