import { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import {
  QuerySupplierOfficesOrderByColumn,
  RelocationItemInput,
  SortOrder,
} from "@/gql/graphql";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierTripCreateDialog } from "@/app/SupplierTrips/Components/SupplierTripCreateDialog";
import { OfficeCreateDialog } from "@/app/Offices/Components/OfficeCreateDialog";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierOfficeListQuery } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { supplierTripByOfficesQuery } from "@/app/SupplierTrips/GraphQL/supplierTripRecord";

export type OfficeType = "departure" | "delivery";

export function TripRenderCell(
  props: ICellRendererParams<RelocationItemInput>,
) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const supplierId = props.colDef?.cellRendererParams?.supplier?.id;
  const officeType: OfficeType = props.colDef?.cellRendererParams?.officeType;

  const { data } = useGqlQuery(supplierOfficeListQuery, {
    first: 200,
    page: 1,
    supplier_id: supplierId,
    orderBy: [
      {
        column: QuerySupplierOfficesOrderByColumn.Name,
        order: SortOrder.Asc,
      },
    ],
  });

  const offices = data?.supplierOffices.data ?? [];

  const departureCode = props.data?.departure_office_code?.toLowerCase().trim();
  const departureOffice = departureCode
    ? offices.find((office) => {
        return office.code?.toLowerCase().trim() === departureCode;
      })
    : null;

  const deliveryCode = props.data?.delivery_office_code?.toLowerCase().trim();
  const deliveryOffice = deliveryCode
    ? offices.find((office) => {
        return office.code?.toLowerCase().trim() === deliveryCode;
      })
    : null;

  const office = officeType === "departure" ? departureOffice : deliveryOffice;

  const { data: trip } = useGqlQuery(
    supplierTripByOfficesQuery,
    {
      officeAId: departureOffice?.id ?? "",
      officeBId: deliveryOffice?.id ?? "",
    },
    {
      enabled: !!departureOffice && !!deliveryOffice,
    },
  );

  const noOffice =
    officeType === "departure" ? !departureOffice : !deliveryOffice;

  const noTrip = trip?.supplierTripByOffices === null;

  const { open } = useDialog(SupplierTripCreateDialog);
  const { open: createOffice } = useDialog(OfficeCreateDialog);

  if (!cellValue) {
    return null;
  }

  return (
    <div
      className={classNames("flex w-full items-center space-x-2", {
        "bg-red-300 text-red-700": noOffice,
        "bg-pink-300 text-pink-700": noTrip,
      })}
    >
      {noOffice ? (
        <IconButton
          className="underline text-blue-400 text-xs"
          type="button"
          onClick={() => {
            createOffice({
              initialValues: {
                supplier: {
                  connect: supplierId,
                },
                code:
                  officeType === "departure"
                    ? props.data?.departure_office_code
                    : props.data?.delivery_office_code,
              },
            });
          }}
          Icon={PlusIcon}
          tooltip="Create office"
        />
      ) : null}
      {noTrip ? (
        <IconButton
          type="button"
          onClick={() => {
            open({
              initialValues: {
                supplier: {
                  connect: supplierId,
                },
                officeA: {
                  connect: departureOffice!.id,
                },
                officeB: {
                  connect: deliveryOffice!.id,
                },
              },
            });
          }}
          Icon={PlusIcon}
          tooltip="Create trip"
        />
      ) : null}
      <span>{cellValue}</span>
    </div>
  );
}
