import { IllustrationsHiker } from "@/assets/Illustrations";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ColumnDef } from "@tanstack/react-table";
import { FC, ReactNode, useMemo, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { UseQueryOptions } from "@tanstack/react-query";
import { Table } from "@/lib/Components/Tables/Table";

export type TableListProps<TQuery, TVariables, TValue> = {
  columns: ColumnDef<TValue>[];
  document: TypedDocumentNode<TQuery, TVariables>;
  title?: ReactNode;
  subtitle?: string;
  button?: ReactNode;
  queryVariables?: Partial<TVariables>;
  queryOptions?: UseQueryOptions;
  emptyState?: ReactNode;
  searchable?: boolean;
  ExpandableRow?: FC<any>;
  hidePagination?: boolean;
  statusFilter?: ReactNode;
  getRowClassName?: (item: any) => string;
  defaultPageSize?: number;
  accessor: (data: TQuery) => {
    paginatorInfo: {
      total: number;
      lastPage: number;
      currentPage: number;
      count: number;
      hasMorePages: boolean;
      perPage: number;
    };
    data: TValue[];
  };
};

export function TableList<TQuery, TVariables, TValue>({
  columns,
  title,
  subtitle,
  button,
  document,
  queryVariables,
  queryOptions,
  emptyState,
  searchable = false,
  ExpandableRow,
  hidePagination = false,
  statusFilter,
  getRowClassName,
  accessor,
  defaultPageSize = 50,
}: TableListProps<TQuery, TVariables, TValue>) {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [parent] = useAutoAnimate();
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const memoColumns = useMemo(() => columns, []);

  const { data, isError } = useGqlQuery(
    document,
    { page, first: pageSize, ...queryVariables } as any,
    {
      placeholderData: (previousData: any) => previousData,
      ...queryOptions,
    },
  );

  const pagination =
    data !== undefined ? accessor(data)?.paginatorInfo : undefined;

  const items = useMemo(() => {
    if (data === undefined) return [];
    return accessor(data).data;
  }, [data]);

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between pb-4">
        <div className="">
          {title && (
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h2>
          )}
          {subtitle && <p className="mt-2 text-sm text-gray-700">{subtitle}</p>}
        </div>

        <div className="flex space-x-2">
          {searchable ? (
            <div className="flex flex-shrink">
              <div className="relative text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          ) : null}
          <div>{statusFilter}</div>
          <div>{button}</div>
        </div>
      </div>

      <div className="rounded-md bg-gray-50 shadow" ref={parent}>
        {isError ? (
          <div className="flex h-full justify-center bg-red-50 p-12 text-red-500">
            Oops, Something went wrong
          </div>
        ) : data === undefined ? (
          <div className="flex items-center justify-center p-12">
            <Spinner />
          </div>
        ) : pagination?.count === 0 ? (
          emptyState ? (
            <>{emptyState}</>
          ) : (
            <EmptyState
              header="No results found"
              subheader="Try adjusting your search or filter to find what you're looking for."
              Icon={IllustrationsHiker}
            />
          )
        ) : (
          <div className="overflow-x-auto">
            <Table
              data={items}
              columns={memoColumns}
              ExpandableRow={ExpandableRow}
              getRowClassName={getRowClassName}
              accessor={accessor}
            />
          </div>
        )}

        {!hidePagination && pagination?.count !== 0 && (
          <nav
            className="flex items-center justify-between border-t border-gray-200 bg-gray-50 px-4 py-3 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="space-x-3 divide-x-2 text-sm text-gray-700">
                <span>
                  Showing{" "}
                  <span className="font-medium">
                    {(pagination?.total ?? 0) > 0
                      ? (page - 1) * pageSize + 1
                      : 0}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {(page - 1) * pageSize + pageSize > (pagination?.total ?? 0)
                      ? pagination?.total
                      : (page - 1) * pageSize + pageSize}
                  </span>{" "}
                  of <span className="font-medium">{pagination?.total}</span>{" "}
                  results
                </span>
                <span className="pl-3">
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(parseInt(e.target.value));
                    }}
                    className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    {[5, 10, 20, 50, 100].map((x) => (
                      <option value={x} key={x}>
                        per page {x}
                      </option>
                    ))}
                  </select>
                </span>
              </p>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end">
              <button
                type="button"
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                onClick={() => setPage((page) => page - 1)}
                disabled={page === 1}
              >
                Previous
              </button>
              <button
                type="button"
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                onClick={() => setPage((page) => page + 1)}
                disabled={page === pagination?.lastPage}
              >
                Next
              </button>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}
