import { RecordScreenFormCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenFormCard";
import { SupplierOfficeForm } from "@/app/Offices/Forms/SupplierOfficeForm";
import { UpdateSupplierOfficeInput } from "@/gql/graphql";
import { officeValidation } from "@/app/Offices/Validators/officeValidation";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierOfficeMutation } from "@/app/Offices/GraphQL/supplierOfficeMutations";
import { useOfficeRecord } from "@/app/Offices/Hooks/useOfficeRecord";

export function OfficeDetailsScreen() {
  const record = useOfficeRecord();
  const { mutateAsync } = useGqlMutation(updateSupplierOfficeMutation);

  return (
    <div className="py-6">
      <RecordScreenFormCard<UpdateSupplierOfficeInput>
        onSubmit={(values) => {
          return mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        validationSchema={officeValidation}
        title="Details"
        initialValues={{
          name: record.name,
          code: record.code,
          city: {
            connect: record.city.id,
          },
          supplier: {
            connect: record.supplier.id,
          },
          booking_email: record.booking_email,
          cc_booking_to_departure_office: record.cc_booking_to_departure_office,
          address: {
            upsert: {
              id: record.address?.id,
              address_line_1: record.address?.address_line_1,
              address_line_2: record.address?.address_line_2,
              city: record.address?.city,
              state: record.address?.state,
              name: record.address?.name,
              lat: record.address?.lat,
              lng: record.address?.lng,
              country: record.address?.country,
              place_id: record.address?.place_id,
              postcode: record.address?.postcode,
            },
          },
          email: record.email,
          phone: record.phone,
          pickup_instructions: record.pickup_instructions,
        }}
      >
        <SupplierOfficeForm />
      </RecordScreenFormCard>
    </div>
  );
}
