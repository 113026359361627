import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import {
  BookingChangeRequestStatus,
  ResolveBookingChangeRequestInput,
  StripePayment,
} from "@/gql/graphql";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import {
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  CreditCardIcon,
} from "@heroicons/react/20/solid";
import { formatDate } from "@/lib/Formatters/formatDate";
import { ReceiptIcon } from "lucide-react";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { resolveChangeRequestMutation } from "@/app/Bookings/GraphQL/bookingChangeRequestMutations";
import { BookingChangeRequestListItem } from "@/app/Bookings/GraphQL/bookingChangeRequestListQuery";

export function BookingChangeRequestApproveDialog({
  changeRequest,
  type,
}: {
  changeRequest: BookingChangeRequestListItem;
  type: "approve" | "reject";
}) {
  const { mutateAsync } = useGqlMutation(resolveChangeRequestMutation);
  const { close } = useDialogState();

  return (
    <GenericDialog
      title={
        type === "approve" ? "Approve change request" : "Reject change request"
      }
    >
      <div className="rounded-md bg-gray-100 p-6 mb-6">
        {changeRequest.request}
      </div>
      <GenericForm<ResolveBookingChangeRequestInput>
        onSubmit={async (values) => {
          await mutateAsync({
            id: changeRequest.id,
            input: values,
          });

          close();
        }}
        initialValues={{
          status:
            type === "approve"
              ? BookingChangeRequestStatus.Approved
              : BookingChangeRequestStatus.Declined,
          amount_to_capture: type === "approve" ? changeRequest.amount : 0,
        }}
      >
        <div className="col-span-full">
          <PaymentItem stripePayment={changeRequest.stripePayment!} />
        </div>
        <TextAreaInput
          name="customer_note"
          label="Customer note"
          className="col-span-full mt-6"
        />
        <DialogSubmitButton
          intent={type === "approve" ? "primary" : "danger"}
          text={
            type === "approve"
              ? "Resolve and take payment"
              : "Reject and cancel payment"
          }
        />
      </GenericForm>
    </GenericDialog>
  );
}

function PaymentItem({ stripePayment }: { stripePayment: StripePayment }) {
  return (
    <div className="lg:col-start-3 lg:row-end-1">
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              Amount
            </dt>
            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
              {formatCurrency(stripePayment.amount, stripePayment.currency)}
            </dd>
          </div>

          <div className="self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              {stripePayment.status}
            </dd>
          </div>

          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <CalendarDaysIcon
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <time dateTime="2023-01-31">
                {formatDate(stripePayment.created_at)}
              </time>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Status</span>
              <CreditCardIcon
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              Paid with {stripePayment.brand}
            </dd>
          </div>

          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <ReceiptIcon
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <a
                href={`https://dashboard.stripe.com/payments/${stripePayment.payment_intent_id}`}
                target="_blank"
                rel="noreferrer"
                className="flex items-center space-x-2 underline-offset-1 hover:underline"
              >
                <span>{stripePayment.payment_intent_id}</span>
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </a>
            </dd>
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
          <CurrencyInput
            currency={stripePayment.currency}
            name={`amount_to_capture`}
            label="Amount to capture"
          />
        </div>
      </div>
    </div>
  );
}
