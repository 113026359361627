import { RecordCreateScreen } from "@/lib/Components/RecordScreen/RecordCreateScreen";
import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import { SupplierOfficeForm } from "@/app/Offices/Forms/SupplierOfficeForm";
import { CreateSupplierOfficeInput } from "@/gql/graphql";
import { useNavigate } from "@tanstack/react-router";
import { officeValidation } from "@/app/Offices/Validators/officeValidation";
import { officeRoute } from "@/app/Offices/Routes/officeRoutes";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierOfficeMutation } from "@/app/Offices/GraphQL/supplierOfficeMutations";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function OfficeCreateScreen() {
  const { mutateAsync } = useGqlMutation(createSupplierOfficeMutation);
  const { supplier } = useGlobalSupplier();
  const navigate = useNavigate();

  return (
    <RecordCreateScreen>
      <RecordCreateCard<CreateSupplierOfficeInput>
        title="Add office"
        validationSchema={officeValidation}
        initialValues={{
          name: "",
          code: "",
          email: "",
          phone: "",
          cc_booking_to_departure_office: false,
          pickup_instructions: "",
          city: {
            connect: null!,
          },
          address: {
            upsert: {
              name: "",
              address_line_1: "",
              address_line_2: "",
              city: "",
              state: "",
              country: "",
              postcode: "",
              lat: null,
              lng: null,
              place_id: "",
            },
          },
          supplier: {
            connect: supplier?.id ?? null!,
          },
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          await navigate({
            to: officeRoute.to,
            params: {
              id: res.createSupplierOffice.id,
            },
          });
        }}
      >
        <SupplierOfficeForm />
      </RecordCreateCard>
    </RecordCreateScreen>
  );
}
