import { SidebarList } from "@/lib/Components/SideBarList/SideBarList";
import { ArrowLeftRightIcon } from "lucide-react";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { bookingRoute } from "@/app/Bookings/Routes/bookingRoutes";
import { useState } from "react";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  BookingListItem,
  bookingListQuery,
} from "@/app/Bookings/GraphQL/bookingListQuery";
import { getBookingListQueryVariables } from "@/app/Bookings/Utils/getBookingListQueryVariables";
import { keepPreviousData } from "@tanstack/react-query";
import { Badge } from "@/components/catalyst/badge";
import { bookingStatusColorMap } from "@/app/Bookings/Utils/bookingStatusColorMap";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { MultiSelectFilter } from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import { BookingStatus } from "@/gql/graphql";
import { useTranslations } from "use-intl";

export function BookingListScreen() {
  const { isAdmin } = useTenant();
  const [search, setSearch] = useState("");
  const t = useTranslations("booking");
  const [selectedStatus, setSelectedStatus] = useState<BookingStatus[]>([]);

  const queryVariables = getBookingListQueryVariables({
    bookingStatus: selectedStatus,
    search,
  });

  const { data, fetchNextPage, isFetching, isFetchingNextPage } =
    useInfiniteGqlQuery(bookingListQuery, queryVariables, {
      placeholderData: keepPreviousData,
      initialPageParam: {
        page: 1,
      },
      getNextPageParam: (lastPage) => {
        return lastPage.bookings.paginatorInfo.hasMorePages
          ? { page: lastPage.bookings.paginatorInfo.currentPage + 1 }
          : undefined;
      },
    });

  const items = data?.pages.flatMap((item) => item.bookings.data) ?? [];
  const totalCount = data?.pages.at(0)?.bookings.paginatorInfo.total ?? 0;

  return (
    <SidebarList
      items={items}
      totalCount={totalCount}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      createRoute={isAdmin ? "/bookings/create" : undefined}
      listRoute={"/bookings"}
      search={search}
      onSearchChange={setSearch}
      itemNode={(item) => <BookingItem model={item} />}
      linkProps={(item) => ({
        to: bookingRoute.to,
        params: {
          bookingId: item.id,
        },
      })}
      filters={
        <>
          <MultiSelectFilter
            label="Status"
            options={Object.values(BookingStatus).map((status) => ({
              label: t(`status.${status}`),
              value: status,
            }))}
            selected={selectedStatus}
            onChange={setSelectedStatus}
          />
        </>
      }
    />
  );
}

function BookingItem({ model }: { model: BookingListItem }) {
  const { t } = useTranslation("booking");
  const { hasSupplier } = useGlobalSupplier();

  return (
    <div className="flex space-x-2 justify-between">
      <div className="space-y-1 truncate">
        <p className="truncate text-sm font-medium text-gray-900">
          <span>{model.reference}</span>
        </p>
        {!hasSupplier ? (
          <p className="flex items-center truncate text-xs text-gray-500">
            <BuildingOfficeIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
            {`${model.relocation.supplier.name}`}
          </p>
        ) : null}

        <p className="flex items-center truncate text-xs text-gray-500">
          <UserIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {`${model.name}`}
        </p>
        <p className="flex items-center truncate text-xs text-gray-500">
          <ArrowLeftRightIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {`${model.relocation.departureOffice.name} - ${model.relocation.deliveryOffice.name}`}
        </p>
        <p className="flex items-center truncate text-xs text-gray-500">
          <CalendarDaysIcon className="mr-2 inline h-4 w-4 flex-shrink-0" />
          {`${formatDate(model.depart_at)} - ${formatDate(model.deliver_at)}`}
        </p>
        <p>
          <time dateTime={model.created_at} className="text-xs text-gray-500">
            {formatDateTimeSinceNow(model.created_at)}
          </time>
        </p>
      </div>

      <div className="flex-shrink-0 min-w-0">
        <Badge
          color={bookingStatusColorMap[model.status]}
          className="text-xs capitalize"
        >
          {t(`statusShortLabel.${model.status}`)}
        </Badge>
      </div>
    </div>
  );
}
