import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";

export function RelocationBookingScreen() {
  const relocation = useRelocationRecord();
  return (
    <RecordScreenCard>
      <BookingTable
        id="relocation-bookings"
        title="Bookings"
        queryVariables={{
          relocationId: relocation.id,
        }}
      />
    </RecordScreenCard>
  );
}
