import { RecordCreateScreen } from "@/lib/Components/RecordScreen/RecordCreateScreen";
import { CreateRelocationInput } from "@/gql/graphql";
import { RelocationForm } from "@/app/Relocations/Forms/RelocationForm";
import { relocationValidation } from "@/app/Relocations/Validation/relocationValidation";
import { useNavigate } from "@tanstack/react-router";
import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import { getDefaultRelocationValues } from "@/app/Relocations/Utils/getDefaultRelocationValues";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createRelocationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { toFormikValidationSchema } from "zod-formik-adapter";

export function RelocationCreateScreen() {
  const { supplier } = useGlobalSupplier();

  return (
    <RecordCreateScreen>
      <RelocationCreateForm initialSupplier={supplier} />
    </RecordCreateScreen>
  );
}

type RelocationCreateFormProps = {
  initialSupplier?: SupplierRecord;
};
export function RelocationCreateForm({
  initialSupplier,
}: RelocationCreateFormProps) {
  const { mutateAsync } = useGqlMutation(createRelocationMutation);
  const navigation = useNavigate();

  return (
    <RecordCreateCard<CreateRelocationInput>
      title="Create relocation"
      validationSchema={toFormikValidationSchema(relocationValidation)}
      onSubmit={async (values) => {
        const res = await mutateAsync({
          input: values,
        });

        await navigation({
          to: "/relocations/$relocationId",
          params: {
            relocationId: res.createRelocation.id,
          },
        });
      }}
      initialValues={getDefaultRelocationValues({
        supplier: initialSupplier,
      })}
    >
      <RelocationForm />
    </RecordCreateCard>
  );
}
