import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Checkbox,
  CheckboxField,
  CheckboxGroup,
} from "@/components/catalyst/checkbox";
import { Fieldset, Label } from "@/components/catalyst/fieldset";
import { CirclePlusIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

export type MultiSelectFilterOption<T extends string> = {
  label: string;
  value: T;
};

export function MultiSelectFilter<T extends string>({
  options,
  onChange,
  selected,
  label,
}: {
  options: MultiSelectFilterOption<T>[];
  onChange: (values: T[]) => void;
  selected: T[];
  label: ReactNode;
}) {
  const numSelected = Object.values(selected).filter((v) => v).length;

  const labels = selected
    .map((key) => {
      const option = options.find((option) => option.value === key);
      return option?.label;
    })
    .filter(Boolean);

  const selectedLabel =
    numSelected > 2
      ? `${labels[0]} and ${numSelected - 1} more`
      : labels.join(", ");

  return (
    <Popover>
      <PopoverTrigger className="px-2 py-[1px] rounded-full border-gray-300 hover:border-gray-500 border-dashed inline-flex text-xs border items-center space-x-1 hover:bg-gray-100 transition-all">
        <CirclePlusIcon
          onClick={(e) => {
            if (numSelected === 0) return;

            e.preventDefault();

            onChange([]);
          }}
          className={cn("h-3 w-3 transition-all", {
            "rotate-0": numSelected === 0,
            "rotate-45 hover:text-red-500": numSelected > 0,
          })}
        />
        <span className="font-semibold text-gray-700">{label}</span>

        {numSelected > 0 && (
          <span className="font-semibold text-indigo-500 border-l border-gray-300 px-1">
            {selectedLabel}
          </span>
        )}
      </PopoverTrigger>
      <PopoverContent align="start">
        <Fieldset>
          <CheckboxGroup>
            {options.map(({ label, value }) => (
              <CheckboxField key={value}>
                <Checkbox
                  color="indigo"
                  name={`status-${value}`}
                  checked={selected.includes(value)}
                  onChange={(checked) => {
                    onChange(
                      checked
                        ? [...selected, value]
                        : selected.filter((v) => v !== value),
                    );
                  }}
                />
                <Label>{label}</Label>
              </CheckboxField>
            ))}
          </CheckboxGroup>
        </Fieldset>
      </PopoverContent>
    </Popover>
  );
}
