import { graphql } from "@/gql";

export const globalSupplierSelectionList = graphql(`
  query GlobalSupplierSelectionList($first: Int!, $page: Int) {
    suppliers(
      page: $page
      first: $first
      orderBy: [{ column: NAME, order: ASC }]
    ) {
      data {
        id
        name

        created_at
        updated_at

        logo {
          ...MediaFields
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
