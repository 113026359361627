import { DialogTitle } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import { Button } from "@/lib/Components/Button/Button";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DismissibleDialog } from "@/lib/Components/Dialog/DismissibleDialog";

export type ConfirmDeleteDialogProps = {
  title: ReactNode;
  message: ReactNode;
  buttonText?: ReactNode;
  children?: ReactNode;
  onDelete: () => Promise<any> | any;
};

export function ConfirmDeleteDialog({
  onDelete,
  title,
  message,
  buttonText = "Delete",
  children,
}: ConfirmDeleteDialogProps) {
  const { close, resolve, reject } = useDialogState();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <DismissibleDialog
      onClose={() => {
        reject?.(false);
      }}
    >
      <div className="p-6">
        <div className="flex">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="ml-4 mt-0 text-left flex-grow min-w-0">
            <DialogTitle
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {title}
            </DialogTitle>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{message}</p>
            </div>
            <div className="w-full min-w-0 overflow-auto">{children}</div>
          </div>
        </div>
        <div className="mt-5 flex justify-end space-x-2 sm:mt-4">
          <Button
            intent="neutral"
            type="text"
            onClick={() => {
              close();
              reject?.(false);
            }}
          >
            Cancel
          </Button>
          <Button
            intent="danger"
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await onDelete();
                close();
                resolve?.(true);
              } catch (e) {
                setIsLoading(false);
              }
            }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </DismissibleDialog>
  );
}
