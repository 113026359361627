import { BookingStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const bookingStatusColorMap: { [key in BookingStatus]: BadgeColor } = {
  CUSTOMER_CANCELLED_CONFIRMATION_TIMELINESS: "red",
  CUSTOMER_CANCELLED_NO_SHOW: "red",
  CUSTOMER_CANCELLED_REBOOKED: "red",
  SUPPLIER_CANCELLED_MECHANICAL_FAILURE: "red",
  SUPPLIER_CANCELLED_NOT_AVAILABLE: "red",
  SUPPLIER_CANCELLED_NO_FERRY: "red",
  [BookingStatus.Vip]: "purple",
  [BookingStatus.SupplierCancelled]: "red",
  [BookingStatus.AdminCancelled]: "red",
  [BookingStatus.CustomerCancelled]: "red",
  [BookingStatus.AwaitingConfirmation]: "yellow",
  [BookingStatus.AwaitingPayment]: "zinc",
  [BookingStatus.Completed]: "blue",
  [BookingStatus.Confirmed]: "green",
};
