import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import vehicle from "./en/vehicle";
import relocation from "./en/relocation";
import generic from "./en/generic";
import booking from "./en/booking";
import payment from "./en/payment";
import supplier from "./en/supplier";
import invoice from "./en/invoice";
import city from "@/i18n/en/city";
import contact from "@/i18n/en/contact";
import task from "@/i18n/en/task";

export const defaultNS = "generic";

const i18n = i18next.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: {
      generic,
      city,
      contact,
      vehicle,
      relocation,
      booking,
      payment,
      supplier,
      task,
      invoice,
    },
  },
  defaultNS,
});

export default i18n;
