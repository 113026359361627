import { Badge, BadgeProps } from "./Badge";

export type BadgeGroupBadges = BadgeProps & { isHidden?: boolean };
export type BadgeGroupProps = {
  badges: BadgeGroupBadges[];
};

export function BadgeGroup({ badges }: BadgeGroupProps) {
  if (badges.length === 0) return null;

  return (
    <span className="space-x-1">
      {badges.map(({ isHidden, ...badge }, index) => {
        if (isHidden) return null;

        return <Badge key={index} {...badge} />;
      })}
    </span>
  );
}
