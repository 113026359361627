import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import dayjs from "dayjs";
import { Button } from "@/lib/Components/Button/Button";
import { FileSpreadsheetIcon } from "lucide-react";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { ReportingRelocationTable } from "@/app/Reports/Components/ReportingRelocationTable";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { RelocationTotalCard } from "@/app/Reports/Components/RelocationTotalCard";
import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { VehicleInput } from "@/app/Vehicles/Components/VehicleInput";
import { useDownload } from "@/lib/Utils/useDownload";
import { keepPreviousData } from "@tanstack/react-query";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { reportRelocationDownloadMutationGraphql } from "@/app/Reports/GraphQL/reportRelocationDownloadMutation.graphql";
import { reportRelocationsQueryGraphql } from "@/app/Reports/GraphQL/reportRelocationsQuery.graphql";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { SupplierOfficeInput } from "@/app/Offices/Components/SupplierOfficeInput";
import { ConnectBelongsTo } from "@/gql/graphql";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

type ReportRelocationVariables = {
  range_start_date: string;
  range_end_date: string;
  supplier: ConnectBelongsTo;
  vehicle: ConnectBelongsTo;
  departure_office: ConnectBelongsTo;
  delivery_office: ConnectBelongsTo;
};

export function ReportRelocationScreen() {
  const { supplier } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(
    reportRelocationDownloadMutationGraphql,
  );
  const download = useDownload();

  return (
    <>
      <GenericForm<ReportRelocationVariables>
        initialValues={{
          range_start_date: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
          range_end_date: dayjs().format("YYYY-MM-DD"),
          supplier: {
            connect: supplier?.id ?? null!,
          },
          vehicle: {
            connect: null!,
          },
          departure_office: {
            connect: null!,
          },
          delivery_office: {
            connect: null!,
          },
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            rangeStartDate: values.range_start_date,
            rangeEndDate: values.range_end_date,
            supplierId: values.supplier?.connect,
            vehicleId: values.vehicle?.connect,
            departureOfficeId: values.departure_office?.connect,
            deliveryOfficeId: values.delivery_office?.connect,
          });

          download(res.createReportRelocationsDownloadUrl);
        }}
      >
        <Charts />
      </GenericForm>
    </>
  );
}

function Charts() {
  const { values, isSubmitting, initialValues } =
    useForm<ReportRelocationVariables>();
  const {
    range_start_date,
    range_end_date,
    supplier,
    vehicle,
    departure_office,
    delivery_office,
  } = values;
  const { data } = useGqlQuery(
    reportRelocationsQueryGraphql,
    {
      rangeStartDate: range_start_date,
      rangeEndDate: range_end_date,
      deliveryOfficeId: delivery_office?.connect,
      departureOfficeId: departure_office?.connect,
      supplierId: supplier?.connect,
      vehicleId: vehicle?.connect,
    },
    {
      placeholderData: keepPreviousData,
    },
  );

  return (
    <>
      <DateInput
        name={"range_start_date"}
        label="From"
        max={dayjs().format("YYYY-MM-DD")}
      />
      <DateInput
        name={"range_end_date"}
        label="To"
        min={values.range_start_date}
        max={dayjs().format("YYYY-MM-DD")}
      />
      <div className="flex col-span-6 justify-end">
        <Button
          LeadingIcon={FileSpreadsheetIcon}
          htmlButtonType={"submit"}
          isLoading={isSubmitting}
        >
          Export
        </Button>
      </div>

      {!initialValues.supplier?.connect ? (
        <SupplierInput name="supplier.connect" label="Supplier" clearable />
      ) : null}
      <VehicleInput
        name="vehicle.connect"
        label="Vehicle"
        clearable
        disabled={!values.supplier?.connect}
        getQueryVariables={(search) => ({
          first: 20,
          page: 1,
          search,
          supplier_id: values.supplier?.connect,
        })}
      />
      <SupplierOfficeInput
        name="departure_office.connect"
        label="Departure office"
        clearable
        disabled={!values.supplier?.connect}
        getQueryVariables={(search) => ({
          first: 20,
          page: 1,
          search,
          supplier_id: values.supplier?.connect,
        })}
      />
      <SupplierOfficeInput
        name="delivery_office.connect"
        label="Delivery office"
        clearable
        disabled={!values.supplier?.connect}
        getQueryVariables={(search) => ({
          first: 20,
          page: 1,
          search,
          supplier_id: values.supplier?.connect,
        })}
      />

      <div className="col-span-full">
        {data ? (
          <div className="space-y-12">
            <RelocationTotalCard data={data.reportRelocations.summary_stats} />
            <ReportingRelocationTable
              rangeStartDate={range_start_date}
              rangeEndDate={range_end_date}
              data={data.reportRelocations.daily_stats}
            />
          </div>
        ) : (
          <EmptyState
            header="Select a date range"
            Icon={CalendarDaysIcon}
            iconWidth="w-20"
            subheader="Select a date range to view reports"
          />
        )}
      </div>
    </>
  );
}
