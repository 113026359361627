import "./App.css";
import "@/lib/DayJs/DayJs";
import { QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { MAX_AGE, QueryClient } from "./lib/ReactQuery/QueryClient";
import {
  ClerkLoaded,
  ClerkProvider,
  useAuth,
  useUser,
} from "@clerk/clerk-react";
import { RouterProvider } from "@tanstack/react-router";
import { router } from "@/routes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./i18n/config";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { GenericErrorBoundary } from "@/lib/Components/Layout/ErrorBoundary/ErrorBoundary";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ClientError } from "graphql-request";
import { toast } from "sonner";
import * as Sentry from "@sentry/react";
import { IntlProvider } from "use-intl";
import { messages } from "@/messages/messages";

const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

// Register your router for maximum type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

if (import.meta.env.PROD) {
  Sentry.init({
    environment: "production",
    dsn: "https://3472c2fdec876e08d907f109f25998c3@o4506212263067648.ingest.sentry.io/4506212264443904",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.browserTracingIntegration(),
    ],

    tracesSampleRate: 0.01,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default function AppWrapper() {
  const [client] = useState(QueryClient);

  return (
    <IntlProvider messages={messages} locale="en">
      <QueryClientProvider client={client}>
        <ClerkProvider
          publishableKey={clerkPubKey}
          routerPush={async (to: any) => {
            await router.navigate({
              to: to,
            });
          }}
          routerReplace={async (to: any) => {
            await router.navigate({
              to: to,
              replace: true,
            });
          }}
        >
          <ClerkLoaded>
            <GenericErrorBoundary>
              <TooltipProvider>
                <InnerApp />
              </TooltipProvider>
            </GenericErrorBoundary>
          </ClerkLoaded>
        </ClerkProvider>
        <ReactQueryDevtools />
        <QueryClientPlugin />
      </QueryClientProvider>
    </IntlProvider>
  );
}

function InnerApp() {
  const auth = useAuth();
  const user = useUser();

  useEffect(() => {
    if (!user.user) return;

    Sentry.setUser({
      email: user.user.primaryEmailAddress?.emailAddress,
      id: user.user.id,
      username: user.user.username ?? undefined,
      ip_address: "{{auto}}",
    });
  }, [user.user]);

  return (
    <RouterProvider
      router={router}
      context={{
        auth,
        user,
      }}
    />
  );
}

function QueryClientPlugin() {
  QueryClient.setDefaultOptions({
    queries: {
      notifyOnChangeProps: "all",
      refetchOnWindowFocus: "always",
      refetchInterval: MAX_AGE,
      staleTime: 5 * 1000,
    },
    mutations: {
      onError: (e) => {
        if (e instanceof ClientError) {
          toast.error("An error occurred", {
            description: e.response?.errors?.at(0)?.message,
          });
        }
      },
      onSuccess: () => {
        QueryClient.invalidateQueries({
          predicate: (query) => {
            const [key] = query.queryKey as [string, string];
            const whiteList = ["me", "csrf"];
            return !whiteList.includes(key);
          },
        });
      },
    },
  });

  return null;
}
