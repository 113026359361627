import { createRoute } from "@tanstack/react-router";
import { OfficeOpenHoursScreen } from "@/app/Offices/Screens/OfficeOpenHoursScreen";
import { OfficeDetailsScreen } from "@/app/Offices/Screens/OfficeDetailsScreen";
import { OfficeRelocationsScreen } from "@/app/Offices/Screens/OfficeRelocationsScreen";
import { OfficeRecordScreen } from "@/app/Offices/Screens/OfficeRecordScreen";
import { OfficeListScreen } from "@/app/Offices/Screens/OfficeListScreen";
import { OfficeBookingsScreen } from "@/app/Offices/Screens/OfficeBookingsScreen";
import { OfficeCreateScreen } from "@/app/Offices/Screens/OfficeCreateScreen";
import { OfficeActivityScreen } from "@/app/Offices/Screens/OfficeActivityScreen";
import { OfficeEmptyScreen } from "@/app/Offices/Screens/OfficeEmptyScreen";
import { appLayoutRoute } from "@/routes";
import { z } from "zod";
import { getOfficeListQueryVariables } from "@/app/Offices/Utils/getOfficeListQueryVariables";
import {
  prefetchInfiniteQuery,
  prefetchQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { supplierOfficeListQuery } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { supplierOfficeRecordQuery } from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";

const officeListSearchParams = z.object({
  officeSearch: z.string().optional(),
});

export type OfficeListSearchParams = z.infer<typeof officeListSearchParams>;

export const officeListRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/offices",
  component: OfficeListScreen,
  validateSearch: officeListSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      supplierOfficeListQuery,
      getOfficeListQueryVariables(deps),
      auth,
    );
  },
});
export const officeCreateRoute = createRoute({
  getParentRoute: () => officeListRoute,
  path: "/create",
  component: OfficeCreateScreen,
});
export const officeEmptyRoute = createRoute({
  getParentRoute: () => officeListRoute,
  path: "/",
  component: OfficeEmptyScreen,
});

export const officeRoute = createRoute({
  getParentRoute: () => officeListRoute,
  path: "$id",
  component: OfficeRecordScreen,
  loader: ({ params: { id }, context: { auth } }) => {
    return prefetchQuery(supplierOfficeRecordQuery, { id }, auth);
  },
});
export const officeDetailRoute = createRoute({
  getParentRoute: () => officeRoute,
  path: "/",
  component: OfficeDetailsScreen,
});
export const officeOpenHoursRoute = createRoute({
  getParentRoute: () => officeRoute,
  path: "/open-hours",
  component: OfficeOpenHoursScreen,
});
export const officeActivityRoute = createRoute({
  getParentRoute: () => officeRoute,
  path: "/activity",
  component: OfficeActivityScreen,
});
export const officeBookingsRoute = createRoute({
  getParentRoute: () => officeRoute,
  path: "/bookings",
  component: OfficeBookingsScreen,
});
export const officeRelocationsRoute = createRoute({
  getParentRoute: () => officeRoute,
  path: "/relocations",
  component: OfficeRelocationsScreen,
});
