import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";

import {
  CalendarDaysIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import {
  ActivityIcon,
  ArrowLeftRightIcon,
  CarIcon,
  ClockIcon,
  DollarSign,
  ForkliftIcon,
  MapPinIcon,
  MenuSquareIcon,
  ShieldCheckIcon,
} from "lucide-react";
import { SupplierMissingBookingNotificationBanner } from "@/app/Suppliers/Components/SupplierMissingBookingNotificationBanner";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { useSupplierActions } from "@/app/Suppliers/Hooks/useSupplierActions";
import { RelocationStatus } from "@/gql/graphql";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

export function SupplierRecordScreen() {
  const supplier = useSupplierRecord();
  const getActions = useSupplierActions();

  return (
    <RecordScreen
      title={supplier.name}
      banner={<SupplierMissingBookingNotificationBanner supplier={supplier} />}
      breadcrumbs={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink to="/suppliers">Suppliers</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{supplier.name}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
      actionsGroups={getActions(supplier)}
      tabs={[
        {
          name: "Details",
          to: "/suppliers/$id",
          Icon: DocumentTextIcon,
        },
        {
          name: "Relocations",
          to: "/suppliers/$id/relocations",
          search: {
            relocationStatus: [RelocationStatus.Ready],
          },
          Icon: ArrowLeftRightIcon,
        },
        {
          name: "Vehicles",
          to: "/suppliers/$id/vehicles",
          Icon: CarIcon,
        },
        {
          name: "Offices",
          to: "/suppliers/$id/offices",
          Icon: MapPinIcon,
        },
        {
          name: "Bookings",
          to: "/suppliers/$id/bookings",
          Icon: CalendarDaysIcon,
        },
        {
          name: "Open hours",
          to: "/suppliers/$id/default-hours",
          Icon: ClockIcon,
        },
        {
          name: "Insurance",
          to: "/suppliers/$id/insurance",
          Icon: ShieldCheckIcon,
        },
        {
          name: "Billing",
          to: "/suppliers/$id/billing",
          Icon: DollarSign,
        },
        {
          name: "Logistics",
          to: "/suppliers/$id/logistics",
          Icon: ForkliftIcon,
        },
        ...(supplier?.email_on_line_sold
          ? [
              {
                name: "Lines",
                to: "/suppliers/$id/active-lines",
                Icon: MenuSquareIcon,
              } as any,
            ]
          : []),
        {
          name: "Activity",
          to: "/suppliers/$id/activity",
          Icon: ActivityIcon,
        },
      ]}
    />
  );
}
