import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { RelocationStatus } from "@/gql/graphql";
import { ArchiveBoxIcon, PencilIcon } from "@heroicons/react/24/outline";
import { CopyIcon } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";
import { ArrowTopRightOnSquareIcon, PlayIcon } from "@heroicons/react/20/solid";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationEditDialog } from "@/app/Relocations/Components/RelocationEditDialog";
import { toast } from "sonner";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  duplicateRelocationMutation,
  updateRelocationMutation,
} from "@/app/Relocations/GraphQL/relocationMutations";
import { RelocationListItem } from "@/app/Relocations/GraphQL/relocationListQuery";

export function useRelocationActions(): ActionsHook<RelocationListItem> {
  const { mutateAsync } = useGqlMutation(updateRelocationMutation);
  const { mutateAsync: duplicate } = useGqlMutation(
    duplicateRelocationMutation,
  );
  const navigate = useNavigate();
  const { open } = useDialog(RelocationEditDialog);

  return (relocation) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Ready",
                Icon: PlayIcon,
                intent: "success",
                isHidden: relocation.status !== RelocationStatus.Draft,
                handleAction: () => {
                  return mutateAsync({
                    id: relocation.id,
                    input: {
                      status: RelocationStatus.Ready,
                    },
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "View",
                intent: "neutral",
                Icon: ArrowTopRightOnSquareIcon,
                handleAction: async () => {
                  window.open(
                    new URL(
                      `/en/relocations/${relocation.id}`,
                      import.meta.env.VITE_APP_URL,
                    ),
                    "_blank",
                  );
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Edit",
                intent: "neutral",
                isHiddenTopBar: true,
                Icon: PencilIcon,
                handleAction: () => {
                  open({
                    id: relocation.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Duplicate",
                intent: "neutral",
                Icon: CopyIcon,
                handleAction: async () => {
                  const res = await duplicate({
                    id: relocation.id,
                  });

                  toast.success("Relocation duplicated successfully!", {
                    action: {
                      label: "View",
                      onClick: () => {
                        navigate({
                          to: "/relocations/$relocationId",
                          params: {
                            relocationId: res.duplicateRelocation.id,
                          },
                        });
                      },
                    },
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                isHidden: relocation.status !== RelocationStatus.Ready,
                label: "Archive",
                intent: "danger",
                Icon: ArchiveBoxIcon,
                handleAction: async () => {
                  await mutateAsync({
                    id: relocation.id,
                    input: {
                      status: RelocationStatus.Archived,
                    },
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
