import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import {
  TripListItem,
  tripListQuery,
} from "@/app/Cities/Trips/GraphQL/tripList.graphql";
import { TableId } from "@/app/Common/Utils/tableIds";
import {
  Measurement,
  OrderByRelationWithColumnAggregateFunction,
  QueryTripsOrderCityAColumn,
  SortOrder,
  TripListQueryVariables,
} from "@/gql/graphql";
import { ModelLinkCell } from "@/lib/Components/Tables/Components/ModelLinkCell";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { formatDistance } from "@/lib/Formatters/formatCurrency";
import { useTripActions } from "@/app/Cities/Trips/Hooks/useTripActions";
import { Image } from "@/app/Media/Components/Image";

type TripTableColumnId =
  | "city_a"
  | "city_b"
  | "ab_image"
  | "ba_image"
  | "distance"
  | "duration";
export function TripTable({
  id,
  queryVariables,
}: {
  id: TableId;
  queryVariables?: Partial<TripListQueryVariables>;
}) {
  const getActions = useTripActions();

  const columns: DataTableColDef<TripListItem, any, TripTableColumnId>[] = [
    {
      id: "city_a",
      header: "City A",
      cell: ({ row }) => (
        <ModelLinkCell>{row.original.cityA.name}</ModelLinkCell>
      ),
    },
    {
      id: "city_b",
      header: "City B",
      cell: ({ row }) => (
        <ModelLinkCell>{row.original.cityB.name}</ModelLinkCell>
      ),
    },
    {
      id: "ab_image",
      header: "A to B Image",
      cell: ({ row }) => (
        <>
          {row.original.abImage ? (
            <div className="w-12 h-12 rounded-md overflow-hidden">
              <Image media={row.original.abImage} alt={`B A image`} />
            </div>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      id: "ba_image",
      header: "B to A Image",
      cell: ({ row }) => (
        <>
          {row.original.baImage ? (
            <div className="w-12 h-12 rounded-md overflow-hidden">
              <Image media={row.original.baImage} alt={`B A image`} />
            </div>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      id: "distance",
      header: "Distance",
      accessorFn: (data) => formatDistance(data.distance, Measurement.Metric),
      cell: DeEmphasisedCell,
    },
    {
      id: "duration",
      header: "Duration",
      accessorFn: (data) => `${data.duration} days`,
      cell: DeEmphasisedCell,
    },
  ];

  return (
    <DataTable
      searchable={false}
      columns={columns}
      document={tripListQuery}
      getActions={getActions}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          order: [
            {
              cityA: {
                column: QueryTripsOrderCityAColumn.Name,
                aggregate: OrderByRelationWithColumnAggregateFunction.Min,
              },
              order: SortOrder.Asc,
            },
          ],
          ...queryVariables,
        };
      }}
      accessor={(data) => data.trips}
      id={id}
    />
  );
}
