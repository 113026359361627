import { useMatch } from "@tanstack/react-router";
import { paidDriverRoute } from "@/app/PaidDrivers/PaidDrivers/Routes/paidDriverRoutes";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { paidDriverRecordQuery } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverRecordQuery";

export function usePaidDriverRecord() {
  const match = useMatch({
    from: paidDriverRoute.id,
  });

  const { data } = useSuspenseGqlQuery(paidDriverRecordQuery, {
    id: match.params.id,
  });

  return data.paidDriver;
}
