import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { userRoute } from "@/app/Users/Routes/userRoutes";
import { useUserRecord } from "@/app/Users/Hooks/useUserRecord";

export function UserRecordScreen() {
  const user = useUserRecord();

  return (
    <RecordScreen
      title={user.name ?? "--"}
      tabs={[
        {
          name: "Details",
          to: userRoute.to,
          Icon: DocumentTextIcon,
        },
      ]}
    />
  );
}
