import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { Currency } from "@/gql/graphql";

export function CurrencyTypeInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const options: {
    [key in Currency]: {
      label: string;
      value: Currency;
    };
  } = {
    [Currency.Aud]: {
      label: "AUD",
      value: Currency.Aud,
    },
    [Currency.Nzd]: { label: "NZD", value: Currency.Nzd },
    [Currency.Cad]: {
      label: "CAD",
      value: Currency.Cad,
    },
    [Currency.Eur]: {
      label: "EUR",
      value: Currency.Eur,
    },
    [Currency.Gbp]: {
      label: "GBP",
      value: Currency.Gbp,
    },
    [Currency.Usd]: {
      label: "USD",
      value: Currency.Usd,
    },
    IDR: { label: "IDR", value: Currency.Idr },
    PGK: { label: "PGK", value: Currency.Pgk },
    SBD: { label: "SBD", value: Currency.Sbd },
    VUV: { label: "VUV", value: Currency.Vuv },
    XPF: { label: "XPF", value: Currency.Xpf },
    FJD: { label: "FJD", value: Currency.Fjd },
    [Currency.Jpy]: {
      label: "JPY",
      value: Currency.Jpy,
    },
    [Currency.Zar]: {
      label: "ZAR",
      value: Currency.Zar,
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "Australia",
          options: [options[Currency.Aud]],
        },
        {
          label: "New Zealand",
          options: [options[Currency.Nzd]],
        },
        {
          label: "USA",
          options: [options[Currency.Usd]],
        },
        {
          label: "Canada",
          options: [options[Currency.Cad]],
        },
        {
          label: "Europe",
          options: [options[Currency.Eur], options[Currency.Gbp]],
        },
        {
          label: "Japan",
          options: [options[Currency.Jpy]],
        },
        {
          label: "Southern Africa",
          options: [options[Currency.Zar]],
        },
        {
          label: "Pacific Islands",
          options: [
            options[Currency.Fjd],
            options[Currency.Idr],
            options[Currency.Xpf],
            options[Currency.Pgk],
            options[Currency.Sbd],
            options[Currency.Vuv],
          ],
        },
      ]}
      {...props}
    />
  );
}
