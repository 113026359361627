import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Pie,
  PieChart,
  XAxis,
} from "recharts";
import {
  type ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { reportSalesQueryGraphql } from "@/app/Reports/GraphQL/reportSalesQuery.graphql";
import { toDateTimeTzString } from "@/lib/Formatters/toDateTimeString";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Suspense, useState, useTransition } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { DateRangePicker } from "@/components/ui/date-range/date-range-picker";
import { getDatesFromFilterOption } from "@/app/Dashboard/Utils/filterOptions";
import { TimelineFilterOption } from "@/gql/graphql";
import { DateRange } from "react-day-picker";
import dayjs from "dayjs";

const colors = [
  "hsl(var(--chart-1))",
  "hsl(var(--chart-2))",
  "hsl(var(--chart-3))",
  "hsl(var(--chart-4))",
  "hsl(var(--chart-5))",
];

export function ReportSalesScreen() {
  return (
    <Suspense fallback={<Skeleton className="w-full h-80" />}>
      <SalesChart />
    </Suspense>
  );
}

function SalesChart() {
  const { start, end } = getDatesFromFilterOption(
    TimelineFilterOption.ThirtyDays,
  );
  const [range, setRange] = useState<DateRange>({
    from: new Date(start),
    to: new Date(end),
  });
  const [isPending, startTransition] = useTransition();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Sales</CardTitle>
        <CardDescription>
          Showing sales data for{" "}
          <DateRangePicker
            className="px-1"
            showCompare={false}
            initialDateFrom={range.from}
            initialDateTo={range.to}
            align="start"
            onUpdate={(values) => {
              startTransition(() => {
                setRange(values.range);
              });
            }}
          />
        </CardDescription>
      </CardHeader>
      {range.from && range.to ? (
        <CardContent className="grid grid-cols-3 gap-6">
          <div className="col-span-2">
            <BarChartComp start={range.from} end={range.to} />
          </div>
          <div className="col-span-1 flex items-center">
            <PieChartComp start={range.from} end={range.to} />
          </div>
        </CardContent>
      ) : null}
    </Card>
  );
}

function BarChartComp({ start, end }: { start: Date; end: Date }) {
  const { data } = useSuspenseGqlQuery(reportSalesQueryGraphql, {
    start: toDateTimeTzString(start),
    end: toDateTimeTzString(end),
  });

  const dates = Array.from(
    { length: dayjs(end).diff(start, "day") + 1 },
    (_, i) => {
      return dayjs(start).add(i, "day").format("YYYY-MM-DD");
    },
  );

  const users = data.salesReport.map((report) => {
    return {
      user: report,
      orgs: report.organisations.map((org) => org.organisation),
    };
  });

  const chartData = dates.map((date) => {
    //Get the total for each date
    const total = Object.fromEntries(
      data.salesReport
        .map((report) => {
          return [
            report.user.id,
            report.organisations.reduce((acc, org) => {
              return (
                acc +
                (org.bookingsByDate.find((booking) => booking.date === date)
                  ?.count ?? 0)
              );
            }, 0),
          ] as const;
        })
        .filter(([, total]) => total > 0),
    );

    return {
      date: date,
      ...total,
    };
  });

  const chartConfig: ChartConfig = Object.fromEntries(
    users.map(({ user }, idx) => {
      return [
        user.user.id,
        {
          label: user.user.name,
          color: colors.at(idx),
        },
      ];
    }),
  );

  return (
    <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
      <BarChart accessibilityLayer data={chartData}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          minTickGap={32}
          tickFormatter={(value) => {
            const date = new Date(value);
            return date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
          }}
        />
        <ChartTooltip
          content={
            <ChartTooltipContent
              labelFormatter={(value) => {
                return new Date(value).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                });
              }}
              indicator="dot"
            />
          }
        />
        <ChartLegend content={<ChartLegendContent />} />

        {users.map(({ user }) => (
          <Bar
            dataKey={user.user.id}
            key={user.user.id}
            fill={`var(--color-${user.user.id})`}
            radius={4}
          />
        ))}
      </BarChart>
    </ChartContainer>
  );
}

function PieChartComp({ start, end }: { start: Date; end: Date }) {
  const { data } = useSuspenseGqlQuery(reportSalesQueryGraphql, {
    start: toDateTimeTzString(start),
    end: toDateTimeTzString(end),
  });

  const chartData = data.salesReport.map((report) => {
    return {
      user_id: report.user.id,
      sales: report.organisations.reduce((acc, org) => {
        return (
          acc +
          org.bookingsByDate.reduce((acc, booking) => {
            return acc + booking.count;
          }, 0)
        );
      }, 0),
      fill: `var(--color-${report.user.id})`,
    };
  });

  const chartConfig = Object.fromEntries(
    chartData.map(({ user_id }, idx) => {
      return [
        user_id,
        {
          label: data.salesReport.find((report) => report.user.id === user_id)
            ?.user.name,
          color: `hsl(var(--chart-${idx + 1}))`,
        },
      ];
    }),
  );

  const total = chartData.reduce((acc, data) => {
    return acc + data.sales;
  }, 0);

  return (
    <ChartContainer
      config={chartConfig}
      className={"mx-auto aspect-square min-h-[250px]"}
    >
      <PieChart>
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        <Pie
          data={chartData}
          dataKey="sales"
          nameKey="user_id"
          innerRadius={60}
          strokeWidth={5}
        >
          <Label
            content={({ viewBox }) => {
              if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy}
                      className="fill-foreground text-3xl font-bold"
                    >
                      {total.toLocaleString()}
                    </tspan>
                    <tspan
                      x={viewBox.cx}
                      y={(viewBox.cy || 0) + 24}
                      className="fill-muted-foreground"
                    >
                      Bookings
                    </tspan>
                  </text>
                );
              }
            }}
          />
        </Pie>
      </PieChart>
    </ChartContainer>
  );
}
