import { GenericField, InputProps } from "../Fields/GenericField";
import { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/utils";
import { useField } from "formik";

export type TextAreaInputProps = InputProps & {
  textAreaProps?: ComponentPropsWithoutRef<"textarea">;
};

export function TextAreaInput({ textAreaProps, ...props }: TextAreaInputProps) {
  const [field, meta] = useField(props.name);
  const { value } = field;
  const showError = !!meta.error && meta.touched;

  return (
    <GenericField
      {...props}
      viewNode={<div className="whitespace-pre-wrap">{value}</div>}
    >
      <>
        <textarea
          {...textAreaProps}
          {...field}
          className={cn(
            showError
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "focus:border-indigo-500 focus:ring-indigo-500",
            "mt-1 block w-full rounded-md border-gray-300 shadow-sm text-sm",
          )}
        />
      </>
    </GenericField>
  );
}
