import {
  GenericField,
  InputProps,
} from "@/lib/Components/Form/Fields/GenericField";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

export type TrueFalseNullInputProps = InputProps;
export function TrueFalseNullInput({ ...props }: TrueFalseNullInputProps) {
  const { name } = props;
  const [field, meta, { setValue }] = useField<boolean | null>(props.name);
  const { value } = field;
  const { error, touched } = meta;
  const showError = !!error && touched;

  const { t } = useTranslation("generic");

  let stringValue;
  switch (true) {
    case value === null || value === undefined:
      stringValue = "NULL";
      break;
    case value === false:
      stringValue = "FALSE";
      break;
    case value === true:
      stringValue = "TRUE";
      break;
    default:
      throw new Error(`Invalid value ${value}`);
  }

  return (
    <GenericField {...props} viewNode={t(`boolean.${value}`)}>
      <RadioGroup
        value={stringValue}
        onValueChange={(newValStr) => {
          let val;
          switch (newValStr) {
            case "NULL":
              val = null;
              break;
            case "FALSE":
              val = false;
              break;
            case "TRUE":
              val = true;
              break;
            default:
              throw new Error(`Invalid value ${newValStr}`);
          }

          setValue(val);
        }}
      >
        <div className="flex items-center space-x-2  text-gray-700">
          <RadioGroupItem value="NULL" id={`${name}_NULL`} />
          <Label htmlFor={`${name}_NULL`}>Unspecified</Label>
        </div>
        <div className="flex items-center space-x-2  text-gray-700">
          <RadioGroupItem value="TRUE" id={`${name}_TRUE`} />
          <Label htmlFor={`${name}_TRUE`}>Yes</Label>
        </div>
        <div className="flex items-center space-x-2 text-gray-700">
          <RadioGroupItem value="FALSE" id={`${name}_FALSE`} />
          <Label htmlFor={`${name}_FALSE`}>No</Label>
        </div>
      </RadioGroup>
    </GenericField>
  );
}
