import { Outlet } from "@tanstack/react-router";
import { UserButton, useUser } from "@clerk/clerk-react";
import { cn } from "@/lib/utils";
import { DialogProvider } from "@/lib/Components/Dialog/Providers/DialogProvider";

export function AuthLayout() {
  return (
    <DialogProvider>
      <div className="flex h-full">
        <div className="flex flex-col h-full overflow-y-auto w-[500px]">
          <div className="p-4">
            <img
              className="h-20 w-auto"
              src="/images/horizontal-logo.png"
              alt="Imoova"
            />
          </div>
          <div className="p-4 flex-grow flex justify-center">
            <Outlet />
          </div>

          <UserFooter />
        </div>

        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1551218611-b1004ba59913?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1900&q=80"
            alt=""
          />
        </div>
      </div>
    </DialogProvider>
  );
}

function UserFooter({ className }: { className?: string }) {
  const { user } = useUser();

  if (!user) return null;

  return (
    <div
      className={cn("flex border-t border-indigo-800 p-4 bg-brand", className)}
    >
      <UserButton afterSignOutUrl="/sign-in" />
      <div className="ml-3 flex-grow">
        <p className="text-left text-sm font-medium text-white line-clamp-2">
          {user?.fullName ?? "--"}
        </p>
        <p className="text-left text-xs font-medium text-indigo-200 group-hover:text-white">
          {user?.primaryEmailAddress?.emailAddress ?? "--"}
        </p>
      </div>
    </div>
  );
}
