import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import dayjs from "dayjs";
import { parseRCM } from "@/app/Import/Utils/parseRCM";
import { parseBritzAU } from "@/app/Import/Utils/parseBritzAU";
import { parseCruisin } from "@/app/Import/Utils/parseCruisin";
import { parseIndieCampers } from "@/app/Import/Utils/parseIndieCampers";
import { parseOthers } from "@/app/Import/Utils/parseOthers";
import { parseIndieCampersEu } from "@/app/Import/Utils/parseIndieCampersEu";

export const DATE_STRING_FORMAT = "YYYY-MM-DD";
export enum ImportPasteTemplate {
  Standard = "standard",
  Others = "others",
  ElMonte = "el-monte",
  BritzNZ = "britz-nz",
  BritzAU = "britz-au",
  CoSeats = "co-seats",
  Cruisin = "cruisin",
  IndieCampers = "indie-campers",
  IndieCampersEU = "indie-campers-eu",
}

export function constructObject(
  grid: AgGridReact,
  row: any,
  template: ImportPasteTemplate,
): RelocationItemInput | null {
  // Create row object
  switch (template) {
    case ImportPasteTemplate.Standard:
      return constructStandard(grid, row);
    case ImportPasteTemplate.ElMonte:
      return constructElMonte(grid, row);
    case ImportPasteTemplate.BritzNZ:
      return constructBritzNZ(grid, row);
    case ImportPasteTemplate.BritzAU:
      return parseBritzAU(grid, row);
    case ImportPasteTemplate.CoSeats:
      return parseRCM(grid, row);
    case ImportPasteTemplate.Cruisin:
      return parseCruisin(grid, row);
    case ImportPasteTemplate.IndieCampers:
      return parseIndieCampers(grid, row);
    case ImportPasteTemplate.Others:
      return parseOthers(grid, row);
    case ImportPasteTemplate.IndieCampersEU:
      return parseIndieCampersEu(grid, row);
    default:
      throw new Error("Template not implemented");
  }
}

function constructStandard(grid: AgGridReact, row: any) {
  const rowObject: any = {};
  const focusedCell = grid.api.getFocusedCell();
  let currentColumn: any = focusedCell?.column;

  row.forEach((item: any) => {
    if (!currentColumn) {
      return;
    }

    rowObject[currentColumn.colDef.field] = item;
    currentColumn = grid.api.getDisplayedColAfter(currentColumn);
  });

  return rowObject;
}

function constructElMonte(grid: AgGridReact, row: any): RelocationItemInput {
  //MIA	MCO	02-Oct-23	14-Oct-23	4P X 5	$1/night	3	$60 	500	$50
  // LAS	MCO	29-Sep-23	17-Oct-23	4P X 2	$1/night	12	$60 	2,500	$250
  // LAX	MCO	29-Sep-23	17-Oct-23	4P X 3	$1/night	13	$60 	2,700	$250
  // SFO	MCO	29-Sep-23	17-Oct-23	4P X 2	$1/night	15	$60 	3,000	$300

  const rowObject: any = {};

  const fields = [
    "departure",
    "destination",
    "pickup_date",
    "dropoff_date",
    "vehicle_x_quantity",
    "rate",
    "nights",
    "extra_night_charge",
    "distance_allowed",
    "fuel_allowance",
  ] as const;

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "departure":
        rowObject.departure_office_code = item;
        break;
      case "destination":
        rowObject.delivery_office_code = item;
        break;
      case "pickup_date":
        rowObject.available_from = dayjs(item, "DD-MMM-YY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "dropoff_date":
        rowObject.available_to = dayjs(item, "DD-MMM-YY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "vehicle_x_quantity":
        const [vehicle, quantity] = item.split(" X ");
        rowObject.vehicle_code = vehicle;
        rowObject.quantity = parseInt(quantity);
        break;

      case "fuel_allowance":
        rowObject.fuel_amount = parseInt(item.replace("$", ""));
        break;
      default:
        break;
    }
  });

  return rowObject;
}

function constructBritzNZ(
  grid: AgGridReact,
  row: any,
): RelocationItemInput | null {
  //1	CHC	AKL	1/10/2023	7/10/2023	2RE
  // 2	CHC	AKL	1/10/2023	7/10/2023	2RE

  const fields = [
    "line",
    "departure",
    "destination",
    "pickup_date",
    "dropoff_date",
    "vehicle_code",
  ] as const;

  if (!row.at(0)) {
    return null;
  }

  const rowObject: any = {
    quantity: 1,
  };

  row.forEach((item: any, index: number) => {
    switch (fields[index]) {
      case "line":
        rowObject.line = item;
        break;
      case "departure":
        rowObject.departure_office_code = item;
        break;
      case "destination":
        rowObject.delivery_office_code = item;
        break;
      case "pickup_date":
        rowObject.available_from = dayjs(item, "D/M/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;

      case "dropoff_date":
        rowObject.available_to = dayjs(item, "D/M/YYYY").format(
          DATE_STRING_FORMAT,
        );
        break;
      case "vehicle_code":
        rowObject.vehicle_code = item;
        break;
      default:
        break;
    }
  });

  return rowObject;
}
