import { OrganizationProfile } from "@clerk/clerk-react";

export function MyOrganisationScreen() {
  return (
    <div className="w-full flex justify-center py-12">
      <OrganizationProfile
        appearance={{
          elements: {
            card: "shadow-none",
          },
        }}
      />
    </div>
  );
}
