import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierInsuranceOptionEditDialog } from "@/app/Suppliers/Components/SupplierInsuranceOptionEditDialog";
import { StarIcon } from "@heroicons/react/20/solid";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierInsuranceOptionMutation } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionMutations";
import { SupplierInsuranceOptionRecordFieldsFragment } from "@/gql/graphql";

export function useSupplierInsuranceOptionActions(): ActionsHook<SupplierInsuranceOptionRecordFieldsFragment> {
  const { open } = useDialog(SupplierInsuranceOptionEditDialog);
  const { mutateAsync } = useGqlMutation(updateSupplierInsuranceOptionMutation);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Make primary",
                handleAction: async () => {
                  await mutateAsync({
                    id: model.id,
                    input: {
                      is_default: true,
                    },
                  });
                },
                Icon: StarIcon,
                intent: "primary",
                isHidden: model.is_default,
              },
            ],
          },
          {
            actions: [
              {
                label: "Edit",
                handleAction: () => {
                  open({ insuranceOptionId: model.id });
                },
                Icon: PencilIcon,
              },
            ],
          },
        ],
      },
    ];
  };
}
