import {
  ButtonHTMLAttributes,
  forwardRef,
  FunctionComponent,
  ReactNode,
  useState,
} from "react";
import classNames from "classnames";
import { Spinner } from "../Layout/Loaders/Spinner";
import { Intent } from "../Common/Intent";
import { cn } from "@/lib/utils";

type ButtonType = "filled" | "outline" | "inverted" | "text";
type WidthType = "auto" | "full";

export type ButtonProps = {
  onClick?: () => void | Promise<void>;
  Icon?: FunctionComponent<any>;
  LeadingIcon?: FunctionComponent<any>;
  isLoading?: boolean;
  type?: ButtonType;
  intent?: Intent;
  children: ReactNode;
  htmlButtonType?: ButtonHTMLAttributes<any>["type"];
  width?: WidthType;
  colSpan?: number;
  className?: string;
  roundedClass?: string;
  showCommandShortcut?: boolean;
  ref?: any;
};

const intentStyles: Record<
  Intent,
  {
    bg: string;
    bgHighlight?: string;
    border: string;
    text: string;
    invertedText: string;
    focusRing: string;
    iconColor?: string;
  }
> = {
  highlight: {
    bg: "bg-purple-500",
    border: "border-purple-500",
    text: "text-white",
    invertedText: "text-purple-500",
    focusRing: "focus:ring-purple-500",
  },
  info: { bg: "", border: "", focusRing: "", invertedText: "", text: "" },
  success: {
    bg: "bg-green-500",
    border: "border-green-500",
    text: "text-white",
    invertedText: "text-green-500",
    focusRing: "focus:ring-green-500",
  },
  warning: {
    bg: "bg-yellow-500",
    border: "border-yellow-500",
    text: "text-white",
    invertedText: "text-yellow-500",
    focusRing: "focus:ring-yellow-500",
  },
  primary: {
    bg: "bg-indigo-500 hover:bg-indigo-600",
    bgHighlight: "bg-indigo-400",
    border: "border-indigo-500",
    text: "text-white",
    invertedText: "text-indigo-500",
    focusRing: "focus:ring-indigo-500",
  },
  danger: {
    bg: "bg-red-500",
    bgHighlight: "bg-red-400",
    border: "border-red-500",
    text: "text-white",
    invertedText: "text-red-500",
    focusRing: "focus:ring-red-500",
  },
  neutral: {
    bg: "bg-white hover:bg-gray-100",
    border: "border-gray-300",
    text: "text-gray-900",
    invertedText: "text-gray-500",
    focusRing: "focus:ring-gray-500",
    iconColor: "text-gray-400",
  },
};

const buttonTypeStyles: Record<ButtonType, (intent: Intent) => string> = {
  filled: (intent) =>
    classNames(
      "shadow",
      intentStyles[intent].bg,
      intentStyles[intent].text,
      intentStyles[intent].focusRing,
    ),
  outline: (intent) =>
    classNames(
      "bg-transparent shadow border",
      intentStyles[intent].border,
      intentStyles[intent].invertedText,
      intentStyles[intent].focusRing,
    ),
  inverted: (intent) =>
    classNames(
      "bg-white shadow",
      intentStyles[intent].invertedText,
      intentStyles[intent].focusRing,
    ),
  text: (intent) =>
    classNames(
      "bg-white",
      intentStyles[intent].invertedText,
      intentStyles[intent].focusRing,
    ),
};

function textColor(intent: Intent, type: ButtonType) {
  if (type === "inverted" || type === "outline" || type === "text") {
    return intentStyles[intent].invertedText;
  }

  return intentStyles[intent].text;
}

const widthStyles: Record<WidthType, string> = {
  auto: "",
  full: "w-full",
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      children,
      Icon,
      isLoading,
      LeadingIcon,
      type = "filled",
      intent = "primary",
      htmlButtonType = "button",
      width = "auto",
      roundedClass = "rounded-md",
      className,
      showCommandShortcut,
      ...props
    },
    ref,
  ) => {
    const [isInternalLoading, setIsInternalLoading] = useState<boolean>(false);
    const showLoading = isLoading || isInternalLoading;

    return (
      <button
        ref={ref}
        onClick={async () => {
          setIsInternalLoading(true);
          try {
            await onClick?.();
          } finally {
            setIsInternalLoading(false);
          }
        }}
        type={htmlButtonType}
        disabled={showLoading}
        className={cn(
          "relative flex h-[38px] items-center justify-center overflow-hidden px-3 text-sm font-medium leading-4 transition-colors duration-300 ease-in-out",
          "focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-1",
          buttonTypeStyles[type](intent),
          widthStyles[width],
          roundedClass,
          className,
        )}
        {...props}
      >
        {showLoading && (
          <div
            className={classNames(
              "absolute inset-0 bg-inherit",
              buttonTypeStyles[type](intent),
            )}
          >
            <div className="flex h-full items-center justify-center">
              <Spinner size={4} color={textColor(intent, type)} />
            </div>
          </div>
        )}

        {LeadingIcon && (
          <LeadingIcon
            className={classNames(
              "mr-2 h-4 w-4 flex-shrink-0",
              intentStyles[intent].iconColor,
            )}
            aria-hidden="true"
          />
        )}

        {showCommandShortcut ? (
          <span className="space-x-1 mr-2 text-xs">
            <span
              className={cn("p-1 rounded-md", intentStyles[intent].bgHighlight)}
            >
              ⌘
            </span>
            <span>+</span>
            <span
              className={cn("p-1 rounded-md", intentStyles[intent].bgHighlight)}
            >
              ↵
            </span>
          </span>
        ) : null}

        <span className="truncate">{children}</span>

        {Icon && <Icon className="h-4 w-4" aria-hidden="true" />}
      </button>
    );
  },
);

Button.displayName = "Button";
