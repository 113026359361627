import { DetailedHTMLProps, HTMLAttributes } from "react";
import { Intent } from "../Common/Intent";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";

export type BadgeProps = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  label: string;
  intent: Intent;
  translationKey?: string;
};

export function Badge({
  label,
  intent,
  translationKey,
  className,
  ...props
}: BadgeProps) {
  const { t } = useTranslation();

  const text: string = translationKey
    ? t(`${translationKey}.${label}` as any)
    : label;
  return (
    <span
      className={cn(
        `rounded-md px-2 py-[2px] text-xs ring-1 ring-inset overflow-ellipsis max-w-40 whitespace-nowrap`,
        intentMap[intent],
        className,
      )}
      title={text}
      {...props}
    >
      {text}
    </span>
  );
}

const intentMap: {
  [index in Intent]: string;
} = {
  highlight: "bg-purple-50 text-purple-700 ring-purple-600/20",
  primary: "bg-indigo-50 text-indigo-700 ring-indigo-600/20",
  success: " text-green-700 bg-green-50 ring-green-600/20",
  neutral: "text-gray-600 bg-gray-50 ring-gray-500/10",
  warning: "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
  danger: "text-red-700 bg-red-50 ring-red-600/10",
  info: "bg-blue-50 text-blue-700 ring-blue-600/20",
};
