import { RecordScreen } from "@/lib/Components/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { paymentDetailRoute } from "@/app/Payments/Routes/paymentRoute";
import { usePaymentRecord } from "@/app/Payments/Hooks/usePaymentRecord";
import { Badge } from "@/components/catalyst/badge";
import { paymentStatusColorMap } from "@/app/Payments/Utils/paymentColorMaps";

export function PaymentRecordScreen() {
  const payment = usePaymentRecord();

  return (
    <RecordScreen
      title={`Payment #${payment.id}`}
      badges={
        <Badge color={paymentStatusColorMap[payment.status]}>
          {payment.status}
        </Badge>
      }
      tabs={[
        {
          name: "Details",
          to: paymentDetailRoute.to,
          Icon: DocumentTextIcon,
        },
      ]}
    />
  );
}
