import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { SubmitButtonInput } from "@/lib/Components/Form/Inputs/SubmitButtonInput";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useState } from "react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createQRCodeMutation } from "@/app/Tools/GraphQL/qrCodeMutations";

export function QRCodeTool() {
  const [data, setData] = useState<null | string>(null);
  const { mutateAsync } = useGqlMutation(createQRCodeMutation);
  return (
    <RecordScreenCard title={"QR Code Tool"}>
      <GenericForm
        initialValues={{
          text: "",
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync(values);
          setData(res.generateQRCode);
        }}
      >
        <TextInput name={"text"} label={"Text"} />
        <SubmitButtonInput>Generate QR Code</SubmitButtonInput>
      </GenericForm>

      {data ? <img src={data} alt="qr-code" /> : null}
    </RecordScreenCard>
  );
}
