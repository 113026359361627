import { DescriptionList } from "@/lib/Components/DescriptionList/DescriptionListCard";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { MailIcon, PhoneIcon } from "lucide-react";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { Badge } from "@/lib/Components/Badges/Badge";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { clerkUserRecord } from "@/app/Users/GraphQL/clerkUserRecord";
import { useUserRecord } from "@/app/Users/Hooks/useUserRecord";

export function UserDetailsScreen() {
  const record = useUserRecord();

  const { data } = useGqlQuery(
    clerkUserRecord,
    {
      id: record.clerk_user_id!,
    },
    {
      enabled: !!record.clerk_user_id,
    },
  );

  return (
    <>
      <RecordScreenCard title="User details">
        <DescriptionList
          items={[
            {
              key: "User ID",
              value: (
                <a
                  href={`https://dashboard.clerk.com/apps/app_2TV1Y6Q0KrJPQrxnM7l9xT1Nxtr/instances/ins_2TV1YInoNtXJaqBiWLLN0OFgSB5/users/${record.clerk_user_id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  {record.clerk_user_id}
                </a>
              ),
            },
            {
              key: "Name",
              value: record?.name || "--",
            },
            {
              key: "Email address",
              value: record?.primary_email,
            },
            {
              key: "Phone number",
              value: record?.primary_phone,
            },
          ]}
        />
      </RecordScreenCard>

      <RecordScreenCard title="Emails">
        <ul>
          {data?.clerkUser.email_addresses.map((clerkEmailAddress) => {
            return (
              <li
                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                key={clerkEmailAddress.id}
              >
                <div className="flex w-0 flex-1 items-center">
                  <MailIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <span className="truncate font-medium">
                      {clerkEmailAddress.email_address}
                    </span>
                    <span className="flex-shrink-0 text-gray-400">
                      {clerkEmailAddress.id}
                    </span>

                    <span>
                      {clerkEmailAddress.id ===
                      data?.clerkUser.primary_email_address_id ? (
                        <Badge label="Primary" intent="primary" />
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="ml-4 flex flex-shrink-0 space-x-4">
                  <TextButton>Delete</TextButton>
                </div>
              </li>
            );
          })}
        </ul>
      </RecordScreenCard>

      <RecordScreenCard title="Phones">
        <ul>
          {data?.clerkUser.phone_numbers.map((clerkPhoneNumber) => {
            return (
              <li
                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                key={clerkPhoneNumber.id}
              >
                <div className="flex w-0 flex-1 items-center">
                  <PhoneIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <span className="truncate font-medium">
                      {clerkPhoneNumber.phone_number}
                    </span>
                    <span className="flex-shrink-0 text-gray-400">
                      {clerkPhoneNumber.id}
                    </span>
                  </div>

                  {clerkPhoneNumber.id ===
                  data?.clerkUser.primary_phone_number_id ? (
                    <Badge label="Primary" intent="primary" />
                  ) : null}
                </div>
                <div className="ml-4 flex flex-shrink-0 space-x-4">
                  <TextButton>Delete</TextButton>
                </div>
              </li>
            );
          })}
        </ul>
      </RecordScreenCard>
    </>
  );
}
