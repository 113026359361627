import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import {
  WaitlistListItem,
  waitlistListQuery,
} from "@/app/Waitlists/GraphQL/waitlistListQuery";
import {
  MediaCollection,
  ModelType,
  QueryWaitlistsOrderColumn,
  QueryWaitlistsWhereColumn,
  SortOrder,
} from "@/gql/graphql";
import { formatDate } from "@/lib/Formatters/formatDate";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { userRecordQuery } from "@/app/Users/GraphQL/userRecordQuery";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { useWaitlistActions } from "@/app/Waitlists/Actions/useWaitlistActions";
import { CellContext } from "@tanstack/react-table";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { UploadMediaDialog } from "@/app/Media/Components/UploadMediaDialog";
import { Image } from "@/app/Media/Components/Image";
import { WaitlistUserCard } from "@/app/Waitlists/Components/WaitlistUserCard";
import { MatchesCell } from "@/app/Waitlists/Components/WaitlistTable";

export function WaitlistListDialog({ userId }: { userId: string }) {
  const getActions = useWaitlistActions();
  const { data } = useSuspenseGqlQuery(userRecordQuery, {
    id: userId,
  });

  const { user } = data;

  type WaitlistColumnId =
    | "trip"
    | "departure"
    | "matches"
    | "emailed"
    | "created"
    | "quick-actions";
  const columns: DataTableColDef<WaitlistListItem, any, WaitlistColumnId>[] = [
    {
      id: "trip",
      header: "Trip",
      cell: TripCell,
    },
    {
      id: "departure",
      header: "Departure",
      accessorFn: (row) =>
        `${formatDate(row.earliest_departure_date)} - ${formatDate(
          row.latest_departure_date,
        )}`,
    },
    {
      id: "matches",
      header: "Matches",
      cell: MatchesCell,
    },
    {
      id: "created",
      header: "Created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  return (
    <GenericDialog title={"Waitlist"} className="space-y-10">
      <WaitlistUserCard user={data.user} />
      <DataTable
        id="waitlist-dialog"
        getActions={getActions}
        accessor={(data) => data.waitlists}
        document={waitlistListQuery}
        columns={columns}
        getQueryVariables={({ pagination }) => {
          return {
            page: pagination.pageIndex,
            first: pagination.pageSize,
            order: [
              {
                column: QueryWaitlistsOrderColumn.CreatedAt,
                order: SortOrder.Desc,
              },
            ],
            where: {
              column: QueryWaitlistsWhereColumn.UserId,
              value: user.id,
            },
          };
        }}
      />

      <ActivityLogFeed modelId={user.id} modelType={ModelType.User} />
    </GenericDialog>
  );
}

function TripCell({ row }: CellContext<WaitlistListItem, any>) {
  const waitlist = row.original;
  const { open: openUploadMedia } = useDialog(UploadMediaDialog);
  const trip = waitlist.trip;

  const mediaCollection =
    waitlist.departureCity.id === trip.city_a_id
      ? MediaCollection.TripABImage
      : MediaCollection.TripBAImage;

  return (
    <div className="flex space-x-2 items-center">
      <button
        className="hover:ring-2 ring-offset-1 ring-blue-500 rounded-md overflow-hidden transition-all"
        onClick={() => {
          openUploadMedia({
            objectId: trip.id,
            objectType: ModelType.Trip,
            collection: mediaCollection,
          });
        }}
      >
        {waitlist.image ? (
          <Image
            className="w-10 h-10"
            media={waitlist.image}
            alt={`${waitlist.departureCity.name} - ${waitlist.deliveryCity.name}`}
          />
        ) : (
          <PhotoIcon className="w-10 h-10" />
        )}
      </button>
      <div>
        {`${waitlist.departureCity.name} - ${waitlist.deliveryCity.name}`}
      </div>
    </div>
  );
}
