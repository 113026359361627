import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ActionItemGroup } from "@/lib/Components/Actions/ActionGroupButtons";
import { GenericMenu } from "@/lib/Components/Menu/GenericMenu";
import { FC } from "react";

interface IconMenuProps {
  actionGroups: ActionItemGroup[];
  Icon?: FC<any>;
}

export function IconMenu({
  actionGroups,
  Icon = EllipsisVerticalIcon,
}: IconMenuProps) {
  return (
    <GenericMenu
      button={
        <div className="flex p-2 hover:bg-gray-100/50 rounded-md">
          <Icon className="h-5 w-5" />
        </div>
      }
      actionGroups={actionGroups}
    />
  );
}
