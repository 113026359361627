import { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import {
  ActionItemGroup,
  ActionMenuItem,
} from "@/lib/Components/Actions/ActionGroupButtons";

interface GenericMenuProps {
  button: ReactNode;
  actionGroups: ActionItemGroup[];
  children?: ReactNode;
}

//Wrap the button, so we can prevent the default action when click on the button to close
const MyCustomButton = forwardRef<any, ComponentPropsWithoutRef<"button">>(
  function ({ onClick, ...props }, ref) {
    return (
      <button
        ref={ref}
        onClick={(e) => {
          onClick?.(e);
          e.preventDefault();
        }}
        {...props}
      />
    );
  },
);

MyCustomButton.displayName = "MyCustomButton";

export function GenericMenu({
  button,
  actionGroups,
  children,
}: GenericMenuProps) {
  return (
    <Menu as="div" className="inline-block">
      <MenuButton as={MyCustomButton}>{button}</MenuButton>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor={{
            to: "bottom end",
            gap: 10,
          }}
          className="z-100 w-56 origin-top-right rounded-md border border-gray-200 bg-white shadow-lg"
        >
          <div className="divide-y divide-gray-100">
            {actionGroups.length === 0 ? (
              <MenuItem disabled>
                <span className="block data-[disabled]:opacity-50">
                  No actions available
                </span>
              </MenuItem>
            ) : null}

            {actionGroups.map(({ actions }, itemIndex) => (
              <div key={itemIndex} className="py-1">
                {actions.map((action, index: number) => (
                  <ActionMenuItem {...action} key={index} />
                ))}
              </div>
            ))}
            {children}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
