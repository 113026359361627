import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { CreateRelocationInput } from "@/gql/graphql";
import { Directions } from "@/app/SupplierTrips/Components/SupplierTripMap";
import { TripRecommendationBanner } from "@/app/SupplierTrips/Forms/SupplierTripForm";
import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Suspense } from "react";

export function RelocationTripCard() {
  const { values } = useForm<CreateRelocationInput>();

  if (!values.deliveryOffice?.connect || !values.departureOffice?.connect) {
    return null;
  }

  return (
    <Card className="mb-6 border-none shadow-none">
      <CardContent className="px-0">
        <Suspense fallback={<Skeleton className="h-[260px] w-full" />}>
          <Directions
            deliveryOfficeId={values.deliveryOffice?.connect!}
            departureOfficeId={values.departureOffice?.connect!}
            measurement={values.measurement}
          />
        </Suspense>

        <div className="flex-grow mt-3">
          <TripRecommendationBanner
            measurement={values.measurement}
            hireUnitType={values.hire_unit_type}
            officeAId={values.departureOffice.connect}
            officeBId={values.deliveryOffice.connect}
          />
        </div>
      </CardContent>
    </Card>
  );
}
