import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { ModelType } from "@/gql/graphql";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useInvoiceRecord } from "@/app/Invoices/Hooks/useInvoiceRecord";

export function InvoiceActivityScreen() {
  const invoice = useInvoiceRecord();

  return (
    <RecordScreenCard>
      <ActivityLogFeed modelId={invoice.id} modelType={ModelType.Invoice} />
    </RecordScreenCard>
  );
}
