import { createRoute } from "@tanstack/react-router";
import { DashboardScreen } from "@/app/Dashboard/Screens/DashboardScreen";
import { appLayoutRoute } from "@/routes";
import { z } from "zod";
import { RelocationStatus } from "@/gql/graphql";

const dashboardSearchParams = z.object({
  relocationStatus: z
    .array(z.nativeEnum(RelocationStatus))
    .catch(() => [RelocationStatus.Ready]),
});

export const dashboardRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/",
  component: DashboardScreen,
  validateSearch: dashboardSearchParams,
});
