import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Link, LinkProps } from "@tanstack/react-router";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/catalyst/badge";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationCreateDialog } from "@/app/Relocations/Components/RelocationCreateDialog";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { relocationStatusCountsQuery } from "@/app/Relocations/GraphQL/relocationStatusCountsQuery";
import { RelocationTable } from "@/app/Relocations/Components/RelocationTable";
import { RelocationStatus } from "@/gql/graphql";
import { ReactNode } from "react";
import { TableId } from "@/app/Common/Utils/tableIds";

type Filter = {
  label: ReactNode;
  status: RelocationStatus;
  hideBadge?: boolean;
};

const filters: Filter[] = [
  {
    label: "Active",
    status: RelocationStatus.Ready,
  },
  {
    label: "Paused",
    status: RelocationStatus.Paused,
  },
  {
    label: "Sold out",
    status: RelocationStatus.SoldOut,
  },
  {
    label: "Expired",
    status: RelocationStatus.Expired,
  },

  {
    label: "Draft",
    status: RelocationStatus.Draft,
  },
  {
    label: "Archived",
    status: RelocationStatus.Archived,
    hideBadge: true,
  },
];

export function RelocationMultiTable({
  supplierId,
  getTabLinkProps,
  statuses,
  onStatusChange,
  tableId,
}: {
  supplierId?: string;
  getTabLinkProps: (status: RelocationStatus) => LinkProps;
  statuses?: RelocationStatus[];
  onStatusChange?: (newStatuses: RelocationStatus[]) => void;
  tableId: TableId;
}) {
  const { open } = useDialog(RelocationCreateDialog);
  const { data } = useGqlQuery(relocationStatusCountsQuery, {
    supplierId: supplierId ?? undefined,
  });

  return (
    <div>
      <h2 className="text-lg font-medium leading-6 text-gray-900">
        Relocations
      </h2>
      <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
        <div className="md:flex md:items-center md:justify-between">
          <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
            <Button
              LeadingIcon={PlusIcon}
              onClick={() => {
                open({
                  initialSupplierId: supplierId,
                });
              }}
            >
              Add relocation
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            >
              {filters.map((tab, idx) => (
                <option key={idx}>{tab.label}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {filters.map((tab, idx) => {
                const stats = data?.relocationStatusCounts.filter((s) =>
                  tab.status.includes(s.status),
                );

                const count = stats?.reduce((acc, stat) => acc + stat.count, 0);
                return (
                  <Link
                    key={idx}
                    preload="intent"
                    {...getTabLinkProps(tab.status)}
                    activeProps={() => {
                      return { className: "border-indigo-500 text-indigo-600" };
                    }}
                    inactiveProps={() => {
                      return {
                        className:
                          "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      };
                    }}
                    className={cn(
                      "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
                    )}
                  >
                    {tab.label}
                    {count && !tab.hideBadge ? (
                      <Badge color="indigo" className="ml-2">
                        {count}
                      </Badge>
                    ) : null}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
      <RelocationTable
        id={tableId}
        statuses={statuses}
        onStatusChange={onStatusChange}
        queryVariables={{
          supplier_id: supplierId,
        }}
      />
    </div>
  );
}
