import { ResultOf } from "@graphql-typed-document-node/core";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { CalendarDaysIcon, UserIcon } from "@heroicons/react/24/outline";
import { formatDate } from "@/lib/Formatters/formatDate";
import { Image } from "@/app/Media/Components/Image";
import { Badge } from "@/components/catalyst/badge";
import { bookingStatusColorMap } from "@/app/Bookings/Utils/bookingStatusColorMap";
import { useTranslation } from "react-i18next";
import { Dot } from "lucide-react";

type Booking = ResultOf<typeof bookingRecordQuery>["booking"];

export function BookingCard({ booking }: { booking: Booking }) {
  const primaryImage = booking.relocation.vehicle.images.at(0);
  const { t } = useTranslation("booking");

  return (
    <div className="flex gap-x-4">
      <div className="w-40">
        {primaryImage ? (
          <Image media={primaryImage} alt={booking.relocation.vehicle.name} />
        ) : (
          <img src={booking.relocation.vehicle.heroImage} alt="default image" />
        )}
      </div>

      <div>
        <h2 className="flex space-x-2 items-center font-bold">
          <span>{booking.reference}</span>
          <Dot className="w-4 h-4 text-gray-400" />
          <span>
            {booking.relocation.departureOffice.name} -{" "}
            {booking.relocation.deliveryOffice.name}
          </span>
          <Dot className="w-4 h-4 text-gray-400" />
          <span>{booking.relocation.vehicle.name}</span>
        </h2>
        <p className="text-xs mt-1 space-x-2">
          <Badge color={bookingStatusColorMap[booking.status]}>
            {t(`status.${booking.status}`)}
          </Badge>

          {booking.relocationLineReference?.reference ? (
            <Badge color="zinc">
              Line/Rego: {booking.relocationLineReference.reference}
            </Badge>
          ) : null}

          {booking.supplier_reference ? (
            <Badge color="zinc">
              {booking.relocation.supplier.name} reference:{" "}
              {booking.supplier_reference}
            </Badge>
          ) : null}
        </p>

        <div className="mt-2 text-xs space-y-1">
          <p className="flex space-x-2 items-center">
            <CalendarDaysIcon className="w-4 h-4 text-gray-400" />
            <span>
              {formatDate(booking.depart_at)} - {formatDate(booking.deliver_at)}
            </span>
          </p>
          <p className="flex space-x-2 items-center">
            <UserIcon className="w-4 h-4 text-gray-400" />
            <span className="flex">
              {booking.name}
              <Dot className="w-4 h-4 text-gray-400" />
              {booking.email}
              <Dot className="w-4 h-4 text-gray-400" />
              {booking.phone}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
