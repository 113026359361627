import { UserButton } from "@clerk/clerk-react";
import { ReactNode, useState } from "react";
import { Button } from "@/lib/Components/Button/Button";
import * as Sentry from "@sentry/react";

type ErrorBoundaryProps = {
  children: ReactNode;
};

export function GenericErrorBoundary({ children }: ErrorBoundaryProps) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError, componentStack }) => (
        <div className="flex justify-center py-6">
          <div className="container space-y-6 rounded-md bg-gray-100 p-6">
            <UserButton showName />
            <h1 className="text-xl font-bold">Something went wrong:</h1>
            <div className="rounded-xl bg-red-600 p-3 text-white shadow-md w-full">
              <pre>{(error as any)?.message}</pre>
            </div>

            {showDetails ? <div>{componentStack}</div> : null}

            <div className="flex space-x-3">
              <Button onClick={resetError}>Try again</Button>

              <Button onClick={() => setShowDetails(!showDetails)} type="text">
                {showDetails ? "Hide" : "Show"} details
              </Button>
            </div>
          </div>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
