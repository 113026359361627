import {
  SupplierNotificationSettingEvent,
  SupplierNotificationSettingType,
} from "@/gql/graphql";
import Resources from "@/i18n/resources";

const resource: Resources["supplier"] = {
  notifications: {
    type: {
      [SupplierNotificationSettingType.Bcc]: "bcc",
      [SupplierNotificationSettingType.Cc]: "cc",
      [SupplierNotificationSettingType.To]: "to",
    },
    event: {
      [SupplierNotificationSettingEvent.BookingPending]: "booking pending",
      [SupplierNotificationSettingEvent.RelocationsExpired]:
        "relocations expired",
      [SupplierNotificationSettingEvent.BookingConfirmed]: "booking confirmed",
      [SupplierNotificationSettingEvent.BookingCancelledAfterConfirmation]:
        "booking cancelled",
      [SupplierNotificationSettingEvent.BookingCancelledBeforeConfirmation]:
        "pending booking cancelled",
    },
  },
};

export default resource;
