import { TaxType } from "@/gql/graphql";
import { InvoiceStatus } from "@/gql/graphql";

const status: {
  [key in InvoiceStatus]: string;
} = {
  [InvoiceStatus.Draft]: "draft",
  [InvoiceStatus.Issued]: "issued",
  [InvoiceStatus.Overdue]: "overdue",
  [InvoiceStatus.Paid]: "paid",
  [InvoiceStatus.Voided]: "void",
};

const tax_type: {
  [key in TaxType]: string;
} = {
  [TaxType.Gst]: "GST",
  [TaxType.TaxExempt]: "GST Free",
};

const resource = {
  tax_type,
  status,
};

export default resource;
export type InvoiceResource = typeof resource;
