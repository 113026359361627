import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { ImportPasteTemplate } from "@/app/Import/Utils/pasteTemplate";

export function ImportTemplateInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "Template",
          options: [
            {
              value: ImportPasteTemplate.Standard,
              label: "Standard",
            },
            {
              value: ImportPasteTemplate.ElMonte,
              label: "El monte",
            },
            {
              value: ImportPasteTemplate.BritzNZ,
              label: "Britz NZ",
            },
            {
              value: ImportPasteTemplate.BritzAU,
              label: "Britz AU",
            },
            {
              value: ImportPasteTemplate.CoSeats,
              label: "Other",
            },
            {
              value: ImportPasteTemplate.Cruisin,
              label: "Cruisin",
            },
            {
              value: ImportPasteTemplate.IndieCampers,
              label: "Indie Campers US",
            },
            {
              value: ImportPasteTemplate.IndieCampersEU,
              label: "Indie Campers EU",
            },
          ],
        },
      ]}
    />
  );
}
