import { ICellRendererParams } from "ag-grid-community";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { Currency } from "@/gql/graphql";

export function CurrencyRenderCell(props: ICellRendererParams) {
  const cellValue = props.value;
  const currency: Currency = props.colDef?.cellRendererParams?.currency;

  if (!currency) return "ERR";

  if (!cellValue) return null;

  return (
    <span className="text-right w-full">
      {formatCurrency(cellValue * 100, currency)}
    </span>
  );
}
