import { RecordScreenFormCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenFormCard";
import { RelocationForm } from "@/app/Relocations/Forms/RelocationForm";
import { UpdateRelocationInput } from "@/gql/graphql";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateRelocationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import { getRelocationUpdateInput } from "@/app/Relocations/Utils/getRelocationUpdateInput";
import { useRelocationActions } from "@/app/Relocations/Hooks/useRelocationActions";
import { Card, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Link } from "@tanstack/react-router";
import { ActionGroupButtons } from "@/lib/Components/Actions/ActionGroupButtons";
import dayjs from "dayjs";

export function BookingRelocationScreen() {
  const record = useBookingRecord();
  const getActions = useRelocationActions();

  const { mutateAsync } = useGqlMutation(updateRelocationMutation);

  return (
    <div className="space-y-6 py-6">
      <Card className="cols-span-full">
        <CardHeader>
          <CardTitle className="flex justify-between">
            <Link
              className="text-blue-500 underline"
              to={"/relocations/$relocationId"}
              params={{
                relocationId: record.relocation.id,
              }}
            >
              {record.relocation.departureOffice.name}
              {" to "}
              {record.relocation.deliveryOffice.name} ({record.relocation.id})
            </Link>

            <ActionGroupButtons buttons={getActions(record.relocation)} />
          </CardTitle>
        </CardHeader>
        <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
          <div className="text-xs text-muted-foreground">
            Created{" "}
            <time dateTime="2023-11-23">
              {dayjs(record.relocation.vehicle.created_at).format("LL")}
            </time>
          </div>
        </CardFooter>
      </Card>
      <RecordScreenFormCard<UpdateRelocationInput>
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.relocation.id,
            input: values,
          });
        }}
        title="Relocation"
        initialValues={getRelocationUpdateInput(record.relocation)}
      >
        <RelocationForm
          relocation={record.relocation}
          initialShowAdvanced={true}
        />
      </RecordScreenFormCard>
    </div>
  );
}
