import { useNavigate } from "@tanstack/react-router";
import { useDeferredValue, useEffect, useState } from "react";
import { organisationListRoute } from "@/app/Organisations/Routes/organisationRoutes";

export function OrganisationListScreen() {
  const searchParams = organisationListRoute.useSearch();
  const [search, setSearch] = useState(searchParams.organisationSearch ?? "");
  const navigate = useNavigate({
    from: organisationListRoute.to,
  });

  const deferredSearch = useDeferredValue(search);

  useEffect(() => {
    navigate({
      search: (s) => ({
        ...s,
        organisationSearch: deferredSearch,
      }),
    });
  }, [deferredSearch]);

  return null;

  // return (
  //   <SidebarList
  //     search={search}
  //     onSearchChange={(newSearch) => {
  //       setSearch(newSearch);
  //     }}
  //     listRoute={organisationListRoute}
  //   />
  // );
}
