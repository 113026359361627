import { TaskStatus } from "@/gql/graphql";

const status: {
  [key in TaskStatus]: string;
} = {
  [TaskStatus.Completed]: "completed",
  [TaskStatus.Overdue]: "overdue",
  [TaskStatus.Pending]: "pending",
  [TaskStatus.Snoozed]: "snoozed",
};

const task = {
  status,
};

export default task;
export type TaskResource = typeof task;
