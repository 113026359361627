import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { FeatureFlagType, IntegrationType } from "@/gql/graphql";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { supplierIntegrationList } from "@/app/Settings/Settings/GraphQL/integrationQueries";
import {
  createIntegrationMutation,
  deleteIntegrationrMutation,
} from "@/app/Settings/Settings/GraphQL/integrationMutations";
import { QueryClient } from "@/lib/ReactQuery/QueryClient";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import JucyLogoSvg from "@/app/Settings/Integrations/Jucy/Icons/JucyLogoSvg";
import { FC } from "react";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useFeatureFlag } from "@/app/Meta/utils/useFeatureFlag";
import { Link } from "@tanstack/react-router";
import { Badge } from "@/components/catalyst/badge";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { ConstructionIcon } from "lucide-react";

type Integration = {
  type: IntegrationType;
  description: string;
  logo: FC<any>;
};

const integrations: Integration[] = [
  {
    type: IntegrationType.Jucy,
    description: "Enable integration to automate booking requests",
    logo: JucyLogoSvg,
  },
];

export function SettingsIntegrationsScreen() {
  const { supplier } = useGlobalSupplier();
  if (!supplier) {
    throw new Error("No supplier active");
  }

  return (
    <div className="space-y-6">
      <AddIntegrationCard supplier={supplier} />
      <ActiveIntegrationsCard supplier={supplier} />
    </div>
  );
}

function AddIntegrationCard({ supplier }: { supplier: SupplierRecord }) {
  const isJucyEnabled = useFeatureFlag(FeatureFlagType.JucyIntegration);
  const { data } = useGqlQuery(supplierIntegrationList, {
    supplier_id: supplier?.id,
  });

  const { mutateAsync, isPending } = useGqlMutation(createIntegrationMutation, {
    onSuccess: () => {
      return QueryClient.invalidateQueries();
    },
  });

  const visibleIntegrations = integrations.filter((integration) => {
    if (
      data?.integrations.data.some((item) => item.type === integration.type)
    ) {
      return false;
    }

    if (integration.type === IntegrationType.Jucy) {
      return isJucyEnabled;
    }

    return true;
  });

  return (
    <SettingCard title="Add Integrations" description="">
      <ul className="space-y-6">
        {visibleIntegrations.map((opt) => {
          const Logo = opt.logo;
          return (
            <div
              key={opt.type}
              className="flex items-center border border-gray-100 rounded-md p-6 space-x-2"
            >
              <Logo className="size-[60px] rounded-md" />
              <div className="flex flex-col">
                <span className="text-base font-semibold leading-6 text-gray-900 capitalize">
                  {opt.type.toLowerCase()}
                </span>
                <span className="text-sm text-gray-500">{opt.description}</span>
              </div>
              <div className="grow grid justify-items-end">
                <Button
                  LeadingIcon={PlusIcon}
                  isLoading={isPending}
                  onClick={async () => {
                    await mutateAsync({
                      input: {
                        supplier: { connect: supplier.id },
                        type: IntegrationType.Jucy,
                      },
                    });
                  }}
                >
                  Add Integration
                </Button>
              </div>
            </div>
          );
        })}

        <div className="flex items-center border border-gray-100 rounded-md p-6 space-x-2">
          <ConstructionIcon className="size-[60px] rounded-md text-gray-700" />
          <div className="flex flex-col">
            <span className="text-base font-semibold leading-6 text-gray-900 capitalize">
              More coming soon...
            </span>
            <span className="text-sm">
              Do you want us to integrate with your booking platform?{" "}
              <a className="underline" href="mailto:tradepartners@imoova.com">
                Let us know!
              </a>
              {" 🚀"}
            </span>
          </div>
          <div className="grow grid justify-items-end"></div>
        </div>
      </ul>
    </SettingCard>
  );
}

function ActiveIntegrationsCard({ supplier }: { supplier: SupplierRecord }) {
  const { data, isPending } = useGqlQuery(supplierIntegrationList, {
    supplier_id: supplier?.id,
  });

  const { mutateAsync } = useGqlMutation(deleteIntegrationrMutation);

  return (
    <SettingCard
      title="Active Integrations"
      description="Current active integrations"
    >
      {isPending ? (
        <div className="p-6 flex items-center justify-center">
          <Spinner className="h-full items-center justify-center" />
        </div>
      ) : null}

      {data?.integrations.data.map((item) => {
        const integration = integrations.find(
          (integration) => integration.type === item.type,
        );

        const Logo = integration?.logo!;

        return (
          <Link
            key={item.id}
            className="flex items-center border border-gray-100 hover:bg-gray-50 rounded-md p-6 space-x-2 relative"
            to="/settings/integrations/$integrationId"
            params={{ integrationId: item.id }}
          >
            <Logo className="w-[60px] h-[60px] rounded-md" />
            <div className="flex flex-col">
              <span className="text-base font-semibold leading-6 text-gray-900 capitalize">
                {item.type.toLowerCase()}
              </span>
              <span className="text-sm text-gray-500">
                {integration?.description}
              </span>
            </div>
            <div className="grow grid justify-items-end">
              <Badge color="green">Active</Badge>
            </div>

            <IconButton
              Icon={TrashIcon}
              tooltip="Delete"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await mutateAsync({ id: item.id });
              }}
            />
          </Link>
        );
      })}

      {data?.integrations.data.length === 0 ? (
        <WarningBanner>No active integrations</WarningBanner>
      ) : null}
    </SettingCard>
  );
}
