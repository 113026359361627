import { ICellRendererParams } from "ag-grid-community";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";
import { formatDate } from "@/lib/Formatters/formatDate";

export function FutureDateCell(props: ICellRendererParams<any>) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  if (!cellValue) return null;

  const date = dayjs(cellValue);

  if (!date.isValid()) {
    return <div className="bg-red-300 text-red-700">Invalid date</div>;
  }

  return (
    <div
      className={cn({
        "bg-yellow-300 text-yellow-700": date.isBefore(dayjs()),
      })}
    >
      {formatDate(date)}
    </div>
  );
}
