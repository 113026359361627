import { ColumnDef } from "@tanstack/react-table";
import {
  BookingChangeRequestListQueryVariables,
  BookingChangeRequestStatus,
  QueryBookingChangeRequestsWhereColumn,
  SqlOperator,
} from "@/gql/graphql";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { BadgeCell } from "@/lib/Components/Tables/Components/BadgeCell";
import { TableList } from "@/lib/Components/Tables/TableList";
import {
  BookingChangeRequestListItem,
  bookingChangeRequestListQuery,
} from "@/app/Bookings/GraphQL/bookingChangeRequestListQuery";
import { getTableProps } from "@/lib/Components/Tables/Utils/getTableProps";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";

export function BookingChangeRequestScreen() {
  const record = useBookingRecord();
  const columns: ColumnDef<BookingChangeRequestListItem>[] = [
    {
      header: "id",
      accessorFn: (row) => row.id,
    },
    {
      header: "status",
      cell: ({ row }) => (
        <BadgeCell
          badges={[
            {
              label: row.original.status,
              intent: "neutral",
            },
          ]}
        />
      ),
    },
    {
      header: "paid",
      accessorFn: (row) => formatCurrency(row.amount, row.currency),
    },
    {
      header: "request",
      accessorFn: (row) => row.request,
    },
    {
      header: "created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  const tableProps = getTableProps({
    document: bookingChangeRequestListQuery,
    accessor: (data) => data.bookingChangeRequests,
    columns: columns as any[],
  });

  return (
    <RecordScreenCard title="Change requests">
      <TableList
        {...tableProps}
        queryVariables={
          {
            where: {
              AND: [
                {
                  column: QueryBookingChangeRequestsWhereColumn.BookingId,
                  value: record.id,
                },
                {
                  column: QueryBookingChangeRequestsWhereColumn.Status,
                  operator: SqlOperator.Neq,
                  value: BookingChangeRequestStatus.AwaitingPayment,
                },
              ],
            },
          } as BookingChangeRequestListQueryVariables
        }
      />
    </RecordScreenCard>
  );
}
