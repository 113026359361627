import { TableList, TableListProps } from "@/lib/Components/Tables/TableList";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierPurchasableExtraCreateDialog } from "@/app/Suppliers/Components/SupplierPurchasableExtraCreateDialog";
import { Supplier } from "@/gql/graphql";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";

import { Button } from "@/components/ui/button";
import {
  PurchasableExtraListResult,
  purchasableExtrasQuery,
} from "@/app/Suppliers/GraphQL/supplierPurchasableExtrasQuery";
import { useSupplierPurchasableExtrasHook } from "@/app/Suppliers/Hooks/useSupplierPurchasableExtrasHook";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationShippingPackage } from "@/assets/Illustrations";
import { ColumnDef } from "@tanstack/react-table";
import { getTableProps } from "@/lib/Components/Tables/Utils/getTableProps";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { TableActions } from "@/lib/Components/Actions/ModelIconMenuActions";

type TableProps = TableListProps<
  ResultOf<typeof purchasableExtrasQuery>,
  VariablesOf<typeof purchasableExtrasQuery>,
  PurchasableExtraListResult
>;

export function SupplierPurchasableExtrasTable({
  supplier,
  additionalColumns = [],
  ...props
}: {
  supplier: Pick<Supplier, "currency" | "id">;
  additionalColumns?: ColumnDef<PurchasableExtraListResult>[];
} & Partial<TableProps>) {
  const { open: openCreatePurchasableExtra } = useDialog(
    SupplierPurchasableExtraCreateDialog,
  );

  const getActions = useSupplierPurchasableExtrasHook();

  const purchasableExtrasTableProps = getTableProps({
    document: purchasableExtrasQuery,
    accessor: (data) => data.supplierPurchasableExtras,
    queryVariables: {
      supplierId: supplier.id,
    },
    columns: [
      {
        header: "Name",
        accessorFn: (data) => data.name,
      },
      {
        header: "Units Available",
        accessorFn: (data) => data.units_purchasable,
      },
      {
        header: "Unit Price",
        accessorFn: (data) =>
          formatCurrency(data.unit_price, supplier.currency),
      },
      ...additionalColumns,
      {
        id: "actions",
        size: 25,
        enableResizing: false,
        cell: ({ row }) => {
          return <TableActions actions={getActions(row.original)} />;
        },
      },
    ],
    ...props,
  });

  return (
    <TableList
      title={"Purchasable Extras"}
      button={
        <Button
          type="button"
          onClick={() => {
            openCreatePurchasableExtra({ supplier });
          }}
        >
          Add purchasable extra
        </Button>
      }
      emptyState={
        <EmptyState
          Icon={IllustrationShippingPackage}
          header="Add purchasable extras"
          subheader="Add purchasable extras like linen, camping chairs, or other items that can be purchased by the guest."
        />
      }
      {...purchasableExtrasTableProps}
    />
  );
}
