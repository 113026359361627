import { createRoute } from "@tanstack/react-router";
import { MyOrganisationScreen } from "@/app/Organisations/Screens/MyOrganisationScreen";
import { OrganisationRecordScreen } from "@/app/Organisations/Screens/OrganisationRecordScreen";
import { OrganisationListScreen } from "@/app/Organisations/Screens/OrganisationListScreen";
import { appLayoutRoute } from "@/routes";
import { z } from "zod";
import { prefetchInfiniteQuery } from "@/lib/GraphQLCodegen/fetcher";
import { organisationListQueryGraphql } from "@/app/Organisations/GraphQL/organisationListQuery.graphql";
import { getOrganisationListQueryVariables } from "@/app/Organisations/Utils/getOrganisationListQueryVariables";

export const myOrganisationRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/my-organisation",
  component: MyOrganisationScreen,
});

const organisationListSearchParams = z.object({
  organisationSearch: z.string().optional(),
});
export type OrganisationListSearchParams = z.infer<
  typeof organisationListSearchParams
>;

export const organisationListRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  validateSearch: organisationListSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      organisationListQueryGraphql,
      getOrganisationListQueryVariables(deps),
      auth,
    );
  },
  path: "/organisations",
  component: OrganisationListScreen,
});

export const organisationRoute = createRoute({
  getParentRoute: () => organisationListRoute,
  path: "$id",
  component: OrganisationRecordScreen,
});
