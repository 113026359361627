import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { VariablesOf } from "@graphql-typed-document-node/core";
import {
  BookingChangeRequestListItem,
  bookingChangeRequestListQuery,
} from "@/app/Bookings/GraphQL/bookingChangeRequestListQuery";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { useBookingChangeRequestActions } from "@/app/Bookings/Hooks/useBookingChangeRequestActions";
import { TableId } from "@/app/Common/Utils/tableIds";
import { ModelLinkCell } from "@/lib/Components/Tables/Components/ModelLinkCell";
import { bookingChangeRequestStatusColorMap } from "@/app/Bookings/Utils/bookingChangeRequestStatusColorMap";
import { Badge } from "@/components/catalyst/badge";

type BookingChangeRequestColumnId =
  | "id"
  | "status"
  | "request"
  | "created_at"
  | "booking";
export function ChangeRequestTable({
  queryVariables,
  id,
}: {
  id: TableId;
  queryVariables?: Partial<VariablesOf<typeof bookingChangeRequestListQuery>>;
}) {
  const getActions = useBookingChangeRequestActions();

  const columns: DataTableColDef<
    BookingChangeRequestListItem,
    any,
    BookingChangeRequestColumnId
  >[] = [
    {
      id: "booking",
      header: "Booking",
      cell: ({ row }) => (
        <ModelLinkCell
          to={"/bookings/$bookingId"}
          params={{
            bookingId: row.original.booking.id,
          }}
        >
          {row.original.booking.reference}
        </ModelLinkCell>
      ),
    },
    {
      id: "status",
      header: "Status",
      cell: ({ row }) => (
        <Badge color={bookingChangeRequestStatusColorMap[row.original.status]}>
          {row.original.status}
        </Badge>
      ),
    },
    {
      id: "request",
      header: "Request",
      accessorFn: (row) => row.request,
    },
    {
      id: "created_at",
      header: "created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  return (
    <DataTable
      id={id}
      title="Change requests"
      searchable={false}
      document={bookingChangeRequestListQuery}
      columns={columns}
      getActions={getActions}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          ...queryVariables,
        };
      }}
      accessor={(data) => data.bookingChangeRequests}
    />
  );
}
