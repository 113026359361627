import { TaskListItem, taskListQuery } from "@/app/Tasks/GraphQL/taskListQuery";
import { CellContext } from "@tanstack/react-table";
import {
  QueryTasksOrderByColumn,
  QueryTasksOrderByOrderByClause,
  SortOrder,
  TaskListQueryVariables,
  TaskStatus,
} from "@/gql/graphql";
import { formatDateTimeFromNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { Link } from "@tanstack/react-router";
import { cn } from "@/lib/utils";
import { Text } from "@/components/catalyst/text";
import { useTranslation } from "react-i18next";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { useTaskActions } from "@/app/Tasks/Hooks/useTaskActions";
import { Badge } from "@/components/catalyst/badge";
import { taskStatusMap } from "@/app/Tasks/Utils/taskStatusMap";

type TaskListColumnId =
  | "item"
  | "due_at"
  | "status"
  | "related_to_type"
  | "related_to_id"
  | "relatedTo"
  | "type"
  | "description"
  | "created_at"
  | "updated_at";

export function TaskList({
  queryVariables,
  id,
}: {
  id: TableId;
  queryVariables?: Partial<Omit<TaskListQueryVariables, "page" | "first">>;
}) {
  const { t } = useTranslation("task");
  const getActions = useTaskActions();

  const columns: DataTableColDef<TaskListItem, any, TaskListColumnId>[] = [
    {
      id: "item",
      header: "Item",
      cell: RelatedToCell,
    },
    {
      id: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      accessorFn: (task) => task.status,
      cell: ({ row }) => {
        const task = row.original;
        return (
          <Badge color={taskStatusMap[task.status]}>
            {t(`status.${task.status}`)}
          </Badge>
        );
      },
    },
    {
      id: "description",
      header: "Description",
      accessorFn: (task) => task.description,
      size: 300,
      cell: ({ row }) => {
        return (
          <Text className="text-xs max-w-lg whitespace-normal">
            {row.original.description}
          </Text>
        );
      },
    },
    {
      id: "due_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Due" />
      ),
      accessorFn: (task) => task.due_at,
      cell: ({ row }) => {
        const task = row.original;
        return (
          <span
            className={cn({
              "text-red-500 font-semibold": task.status === TaskStatus.Overdue,
            })}
          >
            {formatDateTimeFromNow(task.due_at)}
          </span>
        );
      },
    },
  ];

  return (
    <DataTable
      id={id}
      columns={columns}
      document={taskListQuery}
      searchable={false}
      title="Tasks"
      accessor={(res) => res.tasks}
      getActions={getActions}
      getQueryVariables={({ pagination, sorting }) => {
        const order: QueryTasksOrderByOrderByClause[] = [];

        sorting.map((sort) => {
          if (sort.id === "status") {
            order.push({
              column: QueryTasksOrderByColumn.Status,
              order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
            });
          }

          if (sort.id === "due_at") {
            order.push({
              column: QueryTasksOrderByColumn.DueAt,
              order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
            });
          }
        });

        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          orderBy: order.length ? order : undefined,
          ...queryVariables,
        };
      }}
    />
  );
}

function RelatedToCell({ row }: CellContext<TaskListItem, any>) {
  const task = row.original;

  if (task.relatedTo?.__typename === "Booking") {
    return (
      <Link
        className="underline text-blue-500"
        to={"/bookings/$bookingId"}
        params={{
          bookingId: task.relatedTo.id,
        }}
        search={() => {
          return {};
        }}
      >
        {task.relatedTo.reference}
      </Link>
    );
  }
}
