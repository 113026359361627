import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import {
  CityListItem,
  cityListQuery,
} from "@/app/Cities/Cities/GraphQL/cityListQuery";
import { cityRecordQuery } from "@/app/Cities/Cities/GraphQL/cityRecordQuery";

export function CityInput({
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof cityListQuery>,
  VariablesOf<typeof cityListQuery>,
  CityListItem,
  ResultOf<typeof cityRecordQuery>,
  VariablesOf<typeof cityRecordQuery>
>) {
  return (
    <ComboboxInput
      document={cityListQuery}
      accessor={(data) => data.cities.data}
      getQueryVariables={(search) => ({
        search,
        first: 20,
        page: 1,
      })}
      recordAccessor={(data) => data.city}
      recordDocument={cityRecordQuery}
      getViewNode={(item) => item?.name ?? "--"}
      getKey={(item) => item.id}
      getInputNode={(item) => item.name}
      getListItemNode={(item) => (
        <div>
          <p>{item.name}</p>
          <p className="text-gray-500 text-xs">{item.region}</p>
        </div>
      )}
      {...props}
    />
  );
}
