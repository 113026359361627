import { invoiceRoute } from "@/app/Invoices/Routes/invoiceRoutes";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { invoiceRecordQuery } from "@/app/Invoices/GraphQL/invoiceRecordQuery";

export function useInvoiceRecord() {
  const { id } = invoiceRoute.useParams();

  const { data } = useSuspenseGqlQuery(invoiceRecordQuery, {
    id,
  });

  return data.invoice;
}
