import { Spinner } from "../../Layout/Loaders/Spinner";
import { dialogContext, dialogStateContext } from "./DialogContext";
import { FC, ReactNode, Suspense, useMemo, useState } from "react";

export type DialogContext = {
  dialogs: Record<string, Dialog>;
  createDialog: (uuid: string, data: DialogInput) => void;
  removeDialog: (uuid: string) => void;
  depth: number;
};

export type Dialog<TParams = any, TRecord = any> = {
  Component: FC<TParams>;
  props: TParams;
  isOpen: boolean;
  close: () => void;
  uuid: string;
  resolve?: (value: TRecord) => void;
  reject?: (value: any) => void;
};

export type DialogInput = Omit<Dialog, "close" | "uuid">;

export type DialogState<TRecord> = Omit<
  Dialog<never, TRecord>,
  "props" | "Component"
> & {
  depth: number;
  remove: () => void;
};

export function DialogProvider({
  depth = 0,
  children,
}: {
  depth?: number;
  children: ReactNode;
}) {
  const [dialogs, setDialogs] = useState<Record<string, Dialog>>({});

  const value = useMemo(
    () => ({
      depth,
      dialogs,
      createDialog: (uuid: string, data: DialogInput) => {
        setDialogs({
          ...dialogs,
          [uuid]: {
            ...data,
            uuid,
            close: () => {
              setDialogs((oldDialogs) => {
                //If the dialog has unmounted, don't try to close it
                if (!oldDialogs[uuid]) {
                  return oldDialogs;
                }

                return {
                  ...oldDialogs,
                  [uuid]: {
                    ...oldDialogs[uuid],
                    isOpen: false,
                  },
                };
              });
            },
          },
        });
      },
      removeDialog: (uuid: string) => {
        setDialogs((oldDialogs) => {
          const copy = { ...oldDialogs };
          delete copy[uuid];
          return copy;
        });
      },
    }),
    [dialogs, depth],
  );

  return (
    <dialogContext.Provider value={value}>
      {children}
      {Object.entries(dialogs).map(([uuid, { Component, props, ...rest }]) => {
        return (
          <dialogStateContext.Provider
            value={{ ...rest, depth, remove: () => value.removeDialog(uuid) }}
            key={uuid}
          >
            <Suspense
              fallback={
                <div className="z-10 fixed inset-0 flex flex-col items-center justify-center space-y-4 bg-black bg-opacity-25 opacity-100">
                  <Spinner />
                </div>
              }
            >
              <Component {...props} />
            </Suspense>
          </dialogStateContext.Provider>
        );
      })}
    </dialogContext.Provider>
  );
}
