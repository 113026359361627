import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type AllUsersWaitlistListItem = ResultOf<
  typeof usersWithWaitlistQuery
>["allUsers"]["data"][number];

export const usersWithWaitlistQuery = graphql(`
  query UsersWithWaitlistsList(
    $page: Int
    $first: Int!
    $waitlistOrder: Boolean
    $hasPhone: Boolean
    $hrsSinceLastCall: Int
    $search: String
    $waitlistRegions: [Region!]
  ) {
    allUsers(
      page: $page
      first: $first
      search: $search
      hasPhone: $hasPhone
      hrsSinceLastCall: $hrsSinceLastCall
      orderByNewestWaitlistWithMatch: $waitlistOrder
      waitlistRegions: $waitlistRegions
    ) {
      data {
        ...UserFields
        primary_email
        primary_phone
        created_at
        waitlists {
          id
        }
        latestCallActivity {
          id
          created_at
          properties
          event
          causer {
            id
            name
            primary_email
            primary_phone
            clerk_user_id
            updated_at
            created_at
            contact {
              id
              name
              phones {
                id
                phone
              }
              emails {
                id
                email
              }
              profilePicture {
                ...MediaFields
              }
            }
          }
        }
        waitlist_count
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type WaitlistListItem = ResultOf<
  typeof waitlistListQuery
>["waitlists"]["data"][number];

export const waitlistListQuery = graphql(`
  query WaitlistList(
    $first: Int!
    $page: Int
    $relocationId: ID
    $search: String
    $order: [QueryWaitlistsOrderOrderByClause!]
    $where: QueryWaitlistsWhereWhereConditions
    $hasMatches: Boolean
    $hasPhoneNumber: Boolean
  ) {
    waitlists(
      first: $first
      page: $page
      relocation_id: $relocationId
      search: $search
      order: $order
      where: $where
      hasMatches: $hasMatches
      hasPhoneNumber: $hasPhoneNumber
    ) {
      data {
        id
        earliest_departure_date
        latest_departure_date
        created_at
        matches
        relocationLink
        trip {
          id
          city_a_id
          city_b_id
        }
        image {
          ...MediaFields
        }
        deliveryCity {
          id
          name
        }
        departureCity {
          id
          name
        }
        user {
          id
          name
          primary_email
          primary_phone
          latestCallActivity {
            created_at
          }
          contact {
            profilePicture {
              ...MediaFields
            }
          }
        }
        last_notified_at
        activities {
          id
          event
          description
          causer {
            id
            name
          }
          properties
          created_at
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
