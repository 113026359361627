import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { paidDriverListQuery } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverListQuery";
import { PaidDriverListItem } from "@/app/PaidDrivers/PaidDrivers/Routes/paidDriverRoutes";
import { ModelLinkCell } from "@/lib/Components/Tables/Components/ModelLinkCell";
import { TimeSinceNowCell } from "@/lib/Components/DataTable/TimeSinceNowCell";
import { useTranslation } from "react-i18next";
import {
  OrderByRelationWithColumnAggregateFunction,
  QueryPaidDriversOrderByRelationOrderByClause,
  QueryPaidDriversOrderByUserColumn,
  Region,
  SortOrder,
} from "@/gql/graphql";
import { MultiSelectFilter } from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import { useState } from "react";

type PaidDriverColumnId =
  | "name"
  | "based-in"
  | "region"
  | "email"
  | "phone"
  | "created_at";

export function PaidDriverTable({ id }: { id: TableId }) {
  const { t } = useTranslation("city");
  const [regions, setRegions] = useState<Region[]>([]);

  const options = Object.values(Region).map((r) => ({
    label: t(`region.${r}`),
    value: r,
  }));

  const columns: DataTableColDef<
    PaidDriverListItem,
    any,
    PaidDriverColumnId
  >[] = [
    {
      id: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      accessorFn: (item) => item.user.name,
      cell: ({ row }) => (
        <ModelLinkCell
          to={"/paid-drivers/$id"}
          params={{
            id: row.original.id,
          }}
        >
          {row.original.user.name}
        </ModelLinkCell>
      ),
    },
    {
      id: "based-in",
      header: "Based in",
      accessorFn: (item) => item.basedIn.name,
      cell: ({ row }) => (
        <div>
          <p className="">{row.original.basedIn.name}</p>
          <p className="text-gray-500">
            {t(`state.${row.original.basedIn.state!}`)}
          </p>
        </div>
      ),
    },
    {
      id: "region",
      header: "Region",
      accessorFn: (item) => t(`region.${item.basedIn.region}`),
    },
    {
      id: "email",
      header: "Email",
      accessorFn: (item) => item.user.primary_email,
      cell: ({ row }) => (
        <a
          className="text-blue-500 underline"
          href={`mailto:${row.original.user.primary_email}`}
        >
          {row.original.user.primary_email}
        </a>
      ),
    },
    {
      id: "phone",
      header: "Phone",
      accessorFn: (item) => item.user.primary_phone,
      cell: ({ row }) => (
        <a
          className="text-blue-500 underline"
          href={`tel:${row.original.user.primary_phone}`}
        >
          {row.original.user.primary_phone}
        </a>
      ),
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (item) => item.created_at,
      cell: TimeSinceNowCell,
    },
  ];

  return (
    <DataTable
      columns={columns}
      document={paidDriverListQuery}
      filters={(table) => (
        <div className="space-x-2">
          <MultiSelectFilter
            label="Region"
            options={options}
            selected={regions}
            onChange={(newVal) => {
              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
              setRegions(newVal);
            }}
          />
        </div>
      )}
      getQueryVariables={({ pagination, sorting, search }) => {
        const orderBy: QueryPaidDriversOrderByRelationOrderByClause[] = sorting
          .map((sort) => {
            if (sort.id === "name") {
              return {
                user: {
                  column: QueryPaidDriversOrderByUserColumn.Name,
                  aggregate: OrderByRelationWithColumnAggregateFunction.Min,
                },
                order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
              };
            }

            return null;
          })
          .filter(Boolean) as QueryPaidDriversOrderByRelationOrderByClause[];

        const region = Object.entries(status)
          .map(([key, value]) => {
            if (value) {
              return key;
            }

            return null;
          })
          .filter(Boolean) as Region[];

        return {
          page: pagination.pageIndex,
          first: pagination.pageSize,
          region: region.length ? region : undefined,
          search,
          orderBy: search ? undefined : orderBy,
        };
      }}
      accessor={(data) => data.paidDrivers}
      id={id}
    />
  );
}
