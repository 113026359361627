import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { VehicleType } from "@/gql/graphql";
import { SelectOption } from "@/lib/Components/Form/Components/Select";

export function VehicleTypeInput(
  props: Omit<SelectInputProps<any>, "options" | "getViewNode">,
) {
  const options: {
    [key in VehicleType]: SelectOption;
  } = {
    [VehicleType.BoxTruck]: {
      value: VehicleType.BoxTruck,
      label: "Box truck",
    },
    [VehicleType.FourWheelDrive]: {
      value: VehicleType.FourWheelDrive,
      label: "4WD",
    },
    [VehicleType.FourWheelDriveCamper]: {
      value: VehicleType.FourWheelDriveCamper,
      label: "4WD Camper",
    },
    [VehicleType.LuxuryCar]: {
      value: VehicleType.LuxuryCar,
      label: "Luxury car",
    },
    [VehicleType.Ute]: {
      value: VehicleType.Ute,
      label: "Ute",
    },
    [VehicleType.Van]: {
      value: VehicleType.Van,
      label: "Van",
    },
    [VehicleType.CamperVan]: {
      value: VehicleType.CamperVan,
      label: "Campervan",
    },
    [VehicleType.Car]: {
      value: VehicleType.Car,
      label: "Car",
    },
    [VehicleType.MotorHome]: {
      value: VehicleType.MotorHome,
      label: "Motorhome",
    },
    [VehicleType.Suv]: {
      value: VehicleType.Suv,
      label: "SUV",
    },
    [VehicleType.MiniVan]: {
      value: VehicleType.MiniVan,
      label: "Minivan",
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "Cars",
          options: [
            options[VehicleType.Car],
            options[VehicleType.LuxuryCar],
            options[VehicleType.MiniVan],
            options[VehicleType.Suv],
            options[VehicleType.FourWheelDrive],
          ],
        },
        {
          label: "RVs",
          options: [
            options[VehicleType.CamperVan],
            options[VehicleType.MotorHome],
            options[VehicleType.FourWheelDriveCamper],
          ],
        },
        {
          label: "Trucks",
          options: [
            options[VehicleType.BoxTruck],
            options[VehicleType.Ute],
            options[VehicleType.Van],
          ],
        },
      ]}
      {...props}
    />
  );
}
