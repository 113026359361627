import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { RelocationLineReferenceTable } from "@/app/Relocations/Components/RelocationLineReferenceTable";
import {
  QueryRelocationLineReferencesWhereLineColumn,
  SqlOperator,
} from "@/gql/graphql";
import dayjs from "dayjs";

export function SupplierActiveLinesScreen() {
  const record = useSupplierRecord();

  return (
    <>
      <RecordScreenCard title="Active lines">
        <RelocationLineReferenceTable
          id="supplier-line-references"
          queryVariables={{
            whereLine: {
              AND: [
                {
                  column:
                    QueryRelocationLineReferencesWhereLineColumn.ExpiresAt,
                  operator: SqlOperator.Gte,
                  value: dayjs().toISOString(),
                },
                {
                  column:
                    QueryRelocationLineReferencesWhereLineColumn.SupplierId,
                  value: record.id,
                },
              ],
            },
          }}
        />
      </RecordScreenCard>
    </>
  );
}
