import { useMatch } from "@tanstack/react-router";
import { cityRoute } from "@/app/Cities/Cities/Routes/cityRoutes";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { cityRecordQuery } from "@/app/Cities/Cities/GraphQL/cityRecordQuery";

export function useCityRecord() {
  const match = useMatch({
    from: cityRoute.id,
  });
  const { data } = useSuspenseGqlQuery(cityRecordQuery, {
    id: match.params.id,
  });

  return data.city;
}
