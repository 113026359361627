import { FC, ReactNode } from "react";
import { cn } from "@/lib/utils";

type EmptyStateProps = {
  header?: ReactNode;
  subheader?: ReactNode;
  Icon?: FC<any>;
  iconWidth?: string;
  children?: ReactNode;
  className?: string;
};

export function EmptyState({
  header,
  subheader,
  Icon: IconProp,
  iconWidth = "w-40",
  children,
  className,
}: EmptyStateProps) {
  const Icon = IconProp ? IconProp : DefaultIcon;

  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center rounded-xl bg-gray-50 py-10 text-center",
        className,
      )}
    >
      <Icon className={cn("mx-auto text-gray-400", iconWidth)} />
      <h3 className="mt-2 text-sm font-medium text-gray-900">{header}</h3>
      <p className="mt-1 text-sm text-gray-500 max-w-2xl">{subheader}</p>
      {children}
    </div>
  );
}

function DefaultIcon(props: any) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
  );
}
