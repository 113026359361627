import { RecordCreateScreen } from "@/lib/Components/RecordScreen/RecordCreateScreen";
import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import { CityForm } from "@/app/Cities/Cities/Forms/CityForm";
import { CreateCityInput } from "@/gql/graphql";
import { useNavigate } from "@tanstack/react-router";

import { cityRoute } from "@/app/Cities/Cities/Routes/cityRoutes";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createCityMutation } from "@/app/Cities/Cities/GraphQL/cityMutations";

export function CityCreateScreen() {
  const { mutateAsync } = useGqlMutation(createCityMutation);
  const navigate = useNavigate();

  return (
    <RecordCreateScreen>
      <RecordCreateCard<CreateCityInput>
        title="Create city"
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          await navigate({
            to: cityRoute.to,
            params: {
              id: res.createCity.id,
            },
          });
        }}
        initialValues={{
          name: "",
          region: null!,
          state: null!,
        }}
      >
        <CityForm />
      </RecordCreateCard>
    </RecordCreateScreen>
  );
}
