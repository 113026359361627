import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { SupplierInsuranceOptionTable } from "@/app/Suppliers/Components/SupplierInsuranceOptionTable";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierInsuranceOptionCreateDialog } from "@/app/Suppliers/Components/SupplierInsuranceOptionCreateDialog";

export function SupplierInsuranceScreen() {
  const record = useSupplierRecord();
  const { open } = useDialog(SupplierInsuranceOptionCreateDialog);

  return (
    <>
      <RecordScreenCard
        title="Insurance options"
        buttons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({
                initialValues: {
                  supplier: {
                    connect: record.id,
                  },
                },
              });
            }}
          >
            Add option
          </Button>
        }
      >
        <SupplierInsuranceOptionTable
          id="supplier-insurance-options"
          supplier={record}
        />
      </RecordScreenCard>
    </>
  );
}
