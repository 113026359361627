import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Check, CirclePlusIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { QuerySupplierOfficesOrderByColumn, SortOrder } from "@/gql/graphql";
import {
  SupplierOfficeListItem,
  supplierOfficeListQuery,
} from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { useState } from "react";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { keepPreviousData } from "@tanstack/react-query";

export function OfficeFilter({
  placeholder,
  onChange,
  selected,
}: {
  placeholder: string;
  selected: string[];
  onChange: (selected: string[]) => void;
}) {
  const { hasSupplier } = useGlobalSupplier();
  const numSelected = selected.length;
  const [filledValues, setFilledValues] = useState<SupplierOfficeListItem[]>(
    [],
  );
  const labels = filledValues.map((key) => {
    return key.name;
  });

  const selectedLabel =
    numSelected > 2
      ? `${labels[0]} and ${numSelected - 1} more`
      : labels.join(", ");

  const [search, setSearch] = useState("");
  const { supplierId } = useGlobalSupplier();
  const { data } = useGqlQuery(
    supplierOfficeListQuery,
    {
      first: 20,
      page: 1,
      archived: false,
      search,
      supplier_id: supplierId ?? undefined,
      orderBy: !search
        ? [
            {
              order: SortOrder.Asc,
              column: QuerySupplierOfficesOrderByColumn.Name,
            },
          ]
        : undefined,
    },
    {
      placeholderData: keepPreviousData,
    },
  );

  return (
    <Popover>
      <PopoverTrigger className="px-2 py-[1px] rounded-full border-gray-300 hover:border-gray-500 border-dashed inline-flex text-xs border items-center space-x-1 hover:bg-gray-100 transition-all">
        <CirclePlusIcon
          onClick={(e) => {
            if (numSelected === 0) return;
            e.preventDefault();
            onChange([]);
            setFilledValues([]);
          }}
          className={cn("h-3 w-3 transition-all", {
            "rotate-0": numSelected === 0,
            "rotate-45 hover:text-red-500": numSelected > 0,
          })}
        />
        <span className="font-semibold text-gray-700">{placeholder}</span>

        {numSelected > 0 && (
          <span className="font-semibold text-indigo-500 border-l border-gray-300 px-1">
            {selectedLabel}
          </span>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search offices..."
            value={search}
            onValueChange={setSearch}
          />
          <CommandList>
            <CommandEmpty>No offices found.</CommandEmpty>
            <CommandGroup>
              {data?.supplierOffices.data.map((office) => {
                const isSelected = selected.includes(office.id);

                return (
                  <CommandItem
                    key={office.id}
                    value={office.id}
                    onSelect={(currentValue) => {
                      if (selected.includes(currentValue)) {
                        onChange(selected.filter((v) => v !== currentValue));
                        setFilledValues(
                          filledValues.filter((v) => v.id !== currentValue),
                        );
                      } else {
                        onChange([...selected, currentValue]);
                        setFilledValues([...filledValues, office]);
                      }
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        isSelected ? "opacity-100" : "opacity-0",
                      )}
                    />
                    <div>
                      <p>{office.name}</p>
                      {!hasSupplier ? (
                        <p className="text-xs text-gray-500">
                          {office.supplier.name}
                        </p>
                      ) : null}
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
