import { bookingCardMessages } from "@/app/Bookings/Messages/bookingCardMessages";
import { vehicleMessages } from "@/app/Vehicles/messages/vehicleMessages";
import { relocationMessages } from "@/app/Relocations/messages/relocationMessages";
import { officeMessages } from "@/app/Offices/messages/officeMessages";
import { bookingMessages } from "@/app/Bookings/Messages/bookingMessages";

export const messages = {
  booking: {
    ...bookingMessages,
    cards: bookingCardMessages,
  },
  vehicle: vehicleMessages,
  relocation: relocationMessages,
  office: officeMessages,
};
