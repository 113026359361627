import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { BookingStatus } from "@/gql/graphql";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import { useOfficeRecord } from "@/app/Offices/Hooks/useOfficeRecord";

export function OfficeBookingsScreen() {
  const office = useOfficeRecord();

  return (
    <div className="py-6">
      <RecordScreenCard title="Pending bookings">
        <BookingTable
          id={"office-pending-bookings"}
          queryVariables={{
            supplierId: office.supplier.id,
          }}
        />
      </RecordScreenCard>

      <RecordScreenCard title="Departing bookings">
        <BookingTable
          id={"office-departing-bookings"}
          queryVariables={{
            supplierId: office.supplier.id,
            status: [BookingStatus.Confirmed],
          }}
        />
      </RecordScreenCard>

      <RecordScreenCard title="Arriving bookings">
        <BookingTable
          id={"office-arriving-bookings"}
          queryVariables={{
            supplierId: office.supplier.id,
            status: [BookingStatus.Confirmed],
          }}
        />
      </RecordScreenCard>
    </div>
  );
}
