import { ICellRendererParams } from "ag-grid-community";

import { cn } from "@/lib/utils";
import { useMemo } from "react";

export function UniqueValueCell(props: ICellRendererParams<any>) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  const columnId = props.column?.getColId();

  if (!columnId) {
    return "ERR";
  }

  const isValueUnique = useMemo(() => {
    const values: string[] = [];

    props.api.forEachNode((node) => {
      values.push(node.data[columnId]);
    });

    return values.filter((value) => value === cellValue).length <= 1;
  }, [cellValue]);

  return (
    <div
      className={cn({
        "bg-yellow-300 text-yellow-700": !isValueUnique,
      })}
    >
      {cellValue}
    </div>
  );
}
