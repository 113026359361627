import { EmptyRecordScreen } from "@/lib/Components/Screens/EmptyRecordScreen";
import { PlusIcon } from "@heroicons/react/24/outline";

import { officeCreateRoute } from "@/app/Offices/Routes/officeRoutes";

export function OfficeEmptyScreen() {
  return (
    <EmptyRecordScreen
      title="Select an office"
      flair="Offices"
      description="Manage your depots and offices through your supplier records"
      items={[
        {
          name: "Create an office",
          description:
            "Add a new depot or office where customers can pick up or drop off their relocations",
          Icon: PlusIcon,
          toOptions: {
            to: officeCreateRoute.to,
            search: (search) => search,
          },
        },
      ]}
    />
  );
}
